import axios from "axios";

export const fetchData = async (type, url, sData) => {
  switch (type) {
    case "GET":
      {
        try {
          const response = await axios.get(url);
          console.log(response.data);
          return response.data;
        } catch (error) {
          console.error("Error fetching data: ", error);
          throw error;
        }
      }
      break;
    case "POST":
      {
        try {
          const response = await axios.post(url, sData);
          console.log(response.data);
          return response.data;
        } catch (error) {
          console.error("Error post data: ", error);
          throw error;
        }
      }
      break;
    case "PUT":
      {
        try {
          const response = await axios.put(url, sData);
          console.log(response.data);
          return response.data;
        } catch (error) {
          console.error("Error put data: ", error);
          throw error;
        }
      }
      break;
    case "PATCH":
      break;
    case "DELETE":
      {
        try {
          const response = await axios.delete(url, sData);
          console.log(response.data);
          return response.data;
        } catch (error) {
          console.error("Error post data: ", error);
          throw error;
        }
      }
      break;
    default:
  }
};

export const getAPIsUrl = () => {
  return "https://apis.hanaapi.com";
  //return "http://localhost:8080";
};

export const getLocalAPIsUrl = () => {
  return "http://localhost:8080";
};
