import React, { useState, useEffect, useMemo, useRef } from "react";
import ASide from "../../component/ASide/ASide";
import CommonHeader from "../../component/Header/CommonHeader";
import LangHeader from "../../component/Header/LangHeader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchData, getAPIsUrl, getLocalAPIsUrl } from "../../utils/api";
import { getCommpaPrice, getContentType } from "../../utils/util";
import { useNavigate } from "react-router-dom";
import { getLoginCheck } from "../../utils/util";

var curCondtion = {
  agency: "",
  fran: "",
  brand: "",
  store: "",
  pos: "",
};

const curCondtionInit = () => {
  curCondtion.agency = "";
  curCondtion.fran = "";
  curCondtion.brand = "";
  curCondtion.store = "";
  curCondtion.pos = "";
};

const serverUrl = getAPIsUrl();

const Lang = () => {
  // 검색어
  const searchRef = useRef(null);

  // 대리점
  const agencyRef = useRef(null);
  // 프랜차이즈
  const franRef = useRef(null);
  // 브랜드
  const brandRef = useRef(null);
  // 점코드
  const storeRef = useRef(null);
  // 포스번호
  const posRef = useRef(null);
  // 대분류
  const bigRef = useRef(null);
  // 중분류
  const middleRef = useRef(null);
  // 소분류
  const smallRef = useRef(null);
  // 상품명
  const goodsNmRef = useRef(null);
  // 상품코드
  const goodsCodeRef = useRef(null);
  // 판매가격
  const priceRef = useRef(null);
  // 한국어
  const koreanRef = useRef(null);
  // 영어
  const englishRef = useRef(null);
  // 일본어
  const japansesRef = useRef(null);
  // 중국어
  const chineseRef = useRef(null);
  // 저장버튼
  const btnSaveRef = useRef(null);
  // TR Ref
  const trRef = useRef(null);
  // 상품 갯수
  const [goodsCnt, setGoodsCnt] = useState("0");
  // 다국어 갯수
  const [langCnt, setLangCnt] = useState("0");
  // 상품 리스트
  const [goodsList, setGoodsList] = useState([]);
  // 상품 리스트 페이지 사이즈 , 현재 페이지
  const [page, setPage] = useState({ curPage: 1, pageSize: 10 });
  // 선택된 TR Index
  const [indexTR, setIndexTR] = useState(0);

  const navigate = useNavigate();

  const totalPages = useMemo(
    () => Math.ceil(goodsCnt / page.pageSize),
    [goodsCnt, page.pageSize]
  );

  const pageNumbers = useMemo(() => {
    const numbers = [];
    for (let i = 1; i <= totalPages; i++) {
      numbers.push(i);
    }
    return numbers;
  }, [totalPages]);

  useEffect(() => {
    if (!getLoginCheck()) {
      navigate("/login", { replace: true });
      return;
    }
    // 현재 정보 초기화
    curCondtionInit();
    setIndexTR(0);

    search();
  }, []);

  const handleChangeCondtion = async (agency, fran, brand, store, pos) => {
    console.group("Lang > handleChangeCondtion");
    curCondtion.agency = agency;
    curCondtion.fran = fran;
    curCondtion.brand = brand;
    curCondtion.store = store;
    curCondtion.pos = pos;
    console.log(curCondtion);
    console.groupEnd();

    search();
  };

  const handleBtnRegGoods = (agency, fran, brand, store, pos) => {
    // const updatedState = {
    //   ...regState,
    //   agency: agency,
    //   fran: fran,
    //   brand: brand,
    //   store: store,
    //   pos: pos,
    // };
    // setRegState(updatedState);
    // console.group("Goods > 상품등록 버튼 클릭");
    // sendFalg = "INSERT";
    // console.log("받아온 값", agency, fran, brand, store, pos);
    // console.log("regState", regState);
    // sendDataInit();
    // sendData.agency = agency;
    // sendData.fran = fran;
    // sendData.brand = brand;
    // sendData.store = store;
    // sendData.pos = pos;
    // agencyRef.current.value = agency;
    // franRef.current.value = fran;
    // brandRef.current.value = brand;
    // storeRef.current.value = store;
    // posRef.current.value = pos;
    // // 할인율
    // offPercentRef.current.value = "";
    // // 할인금액
    // offPriceRef.current.value = "";
    // // 상품명 클리어
    // //setRegState({ ...regState, goodsname: "" });
    // itemNameRef.current.value = "";
    // // 판매 가격 클리어
    // priceRef.current.value = "";
    // // 파일 클리어
    // fileInputRef.current.value = "";
    // // 이미지 미리 보기 삭제
    // attZoneRef.current.innerHTML = "";
    // // 상품코드
    // itemcodeRef.current.value = "";
    // console.log(sendData);
    // console.groupEnd();
    // getBigCategory(agency, fran, brand, store, pos);
  };

  const getGoodsCnt = () => {
    const keyWord = searchRef.current.value;

    const Url = `${serverUrl}/lang/getLangGoodTotCount?agency=${curCondtion.agency}&fran=${curCondtion.fran}&brand=${curCondtion.brand}&store=${curCondtion.store}&pos=${curCondtion.pos}&goods_nm=${keyWord}&gubun=goods`;

    setTimeout(() => {
      fetch(Url, {
        method: "GET",

        timeout: 60 * 1000,
      })
        .then((response) => response.json()) // Assuming the response is JSON
        .then((data) => {
          console.log("Response:", data.data[0]); // Log the response data
          // Additional logic with the response data if needed
          setGoodsCnt(data.data[0].totGoodsCnt);
          console.log(data.header.res_code);
          if (data.header.res_code === "ok") {
            console.log(data.header.res_msg);
            //toast.success(data.header.res_msg || "삽입에 성공하였습니다");
          } else {
            console.log(data.header.res_msg);
            //toast.error(data.header.res_msg || "삽입에 실패하였습니다");
          }
        })
        .catch((err) => {
          console.error(err);
          //toast.error(err);
        })
        .finally(() => {
          console.log("finally");
          // searchGoodsTotCntList();
          // searchGoodsPagingList();
        });
    }, 500);
  };

  const getLangCnt = () => {
    const keyWord = searchRef.current.value;

    const Url = `${serverUrl}/lang/getLangTotCount?agency=${curCondtion.agency}&fran=${curCondtion.fran}&brand=${curCondtion.brand}&store=${curCondtion.store}&pos=${curCondtion.pos}&goods_nm=${keyWord}&gubun=goods`;

    setTimeout(() => {
      fetch(Url, {
        method: "GET",

        timeout: 60 * 1000,
      })
        .then((response) => response.json()) // Assuming the response is JSON
        .then((data) => {
          console.log("Response:", data.data[0]); // Log the response data
          // Additional logic with the response data if needed
          setLangCnt(data.data[0].totLangCnt);
          console.log(data.header.res_code);
          if (data.header.res_code === "ok") {
            console.log(data.header.res_msg);
            //toast.success(data.header.res_msg || "삽입에 성공하였습니다");
          } else {
            console.log(data.header.res_msg);
            //toast.error(data.header.res_msg || "삽입에 실패하였습니다");
          }
        })
        .catch((err) => {
          console.error(err);
          //toast.error(err);
        })
        .finally(() => {
          console.log("finally");
          // searchGoodsTotCntList();
          // searchGoodsPagingList();
        });
    }, 500);
  };

  const getGoodsList = () => {
    const keyWord = searchRef.current.value;
    const url = `${serverUrl}/lang/getGoodsList?pageSize=${page.pageSize}&pageNumber=${page.curPage}&agency=${curCondtion.agency}&fran=${curCondtion.fran}&brand=${curCondtion.brand}&store=${curCondtion.store}&pos=${curCondtion.pos}&goods_nm=${keyWord}&gubun=goods`;
    console.log("url", url);
    fetchData("GET", url)
      .then((data) => {
        // 성공적으로 데이터를 가져왔을 때의 처리
        console.log("getGoodsList fetched: ", data);

        if (data.header) {
          //console.log("data.header", data.header);
          if (data.header.res_code === "ok") {
            setGoodsList(data.data);
            console.log("상품 데이터 페이징 리스트 => ", data.data);
          } else {
            // 에러 메시지 표현
            setGoodsList([]);
            console.log(data.header.res_code, data.header.res_msg);
          }
        } else {
          setGoodsList([]);
          console.error("header 값이 없음");
        }
      })
      .catch((error) => {
        // fetchData 함수 내에서 발생한 에러를 처리
        setGoodsList([]);
        console.error("Error in fetchData: ", error);
      });
    //setGoodsList
  };

  const search = () => {
    // 상품 전체 갯수
    getGoodsCnt();
    // 다국어 갯수
    getLangCnt();
    // 리스트
    getGoodsList();

    //handleTrClick({ currentTarget: { getAttribute: () => 0 } });
    setIndexTR(0);

    if (goodsList.length > 0) {
      console.group("Search >> ");
      console.log(goodsList[0]);
      console.groupEnd();

      // 대리점
      agencyRef.current.value = goodsList[0].AGENCY_CODE;
      // 프랜차이즈
      franRef.current.value = goodsList[0].FRANCHISE_CODE;
      // 브랜드
      brandRef.current.value = goodsList[0].BRAND_CODE;
      // 점코드
      storeRef.current.value = goodsList[0].STORE_CD;
      // 포스번호
      posRef.current.value = goodsList[0].POS_NO;
      // 대분류
      bigRef.current.value = goodsList[0].ITEM_HEAD;
      // 중분류
      middleRef.current.value = goodsList[0].ITEM_MEDIUM;
      // 소분류
      smallRef.current.value = goodsList[0].ITEM_DETAIL;
      // 상품명
      goodsNmRef.current.value = goodsList[0].GOODS_NM;
      // 상품코드
      goodsCodeRef.current.value = goodsList[0].PLU_CD;
      // 판매가격
      priceRef.current.value = getCommpaPrice(goodsList[0].SALEPRICE);
      // 한국어
      koreanRef.current.value = goodsList[0].KOREAN;
      // 영어
      englishRef.current.value = goodsList[0].ENGLISH;
      // 일본어
      japansesRef.current.value = goodsList[0].JAPANESE;
      // 중국어
      chineseRef.current.value = goodsList[0].CHINESE;
      // 버튼 이름
      let btnText = "";
      if (goodsList[0].FLAG === "I") {
        btnText = "저장";
      } else {
        btnText = "수정";
      }

      btnSaveRef.current.innerText = btnText;
    }
  };

  useEffect(() => {
    console.group("useEffect");
    console.log("goodsList change");
    console.groupEnd();

    // TR이 존재 하고

    if (goodsList.length > 0) {
      console.group("Search >> ");
      console.log(goodsList[0]);
      console.groupEnd();

      // 대리점
      agencyRef.current.value = goodsList[0].AGENCY_CODE;
      // 프랜차이즈
      franRef.current.value = goodsList[0].FRANCHISE_CODE;
      // 브랜드
      brandRef.current.value = goodsList[0].BRAND_CODE;
      // 점코드
      storeRef.current.value = goodsList[0].STORE_CD;
      // 포스번호
      posRef.current.value = goodsList[0].POS_NO;
      // 대분류
      bigRef.current.value = goodsList[0].ITEM_HEAD;
      // 중분류
      middleRef.current.value = goodsList[0].ITEM_MEDIUM;
      // 소분류
      smallRef.current.value = goodsList[0].ITEM_DETAIL;
      // 상품명
      goodsNmRef.current.value = goodsList[0].GOODS_NM;
      // 상품코드
      goodsCodeRef.current.value = goodsList[0].PLU_CD;
      // 판매가격
      priceRef.current.value = getCommpaPrice(goodsList[0].SALEPRICE);
      // 한국어
      koreanRef.current.value = goodsList[0].KOREAN;
      // 영어
      englishRef.current.value = goodsList[0].ENGLISH;
      // 일본어
      japansesRef.current.value = goodsList[0].JAPANESE;
      // 중국어
      chineseRef.current.value = goodsList[0].CHINESE;
      // 버튼 이름
      let btnText = "";
      if (goodsList[0].FLAG === "I") {
        btnText = "저장";
      } else {
        btnText = "수정";
      }

      btnSaveRef.current.innerText = btnText;
    }
  }, [goodsList]);

  const handleSearch = () => {
    search();
  };

  const handlePageChange = (pageNumber) => {
    console.log("handlePageChange", pageNumber);
    console.log("curCondtion", curCondtion);
    setPage({ ...page, curPage: Number(pageNumber) });

    console.log(
      "page.pageSize",
      page.pageSize,
      "page.curPage",
      page.curPage,
      "curCondtion.agency",
      curCondtion.agency,
      "curCondtion.fran",
      curCondtion.fran,
      "curCondtion.brand",
      curCondtion.brand,
      "curCondtion.store",
      curCondtion.store,
      "curCondtion.pos",
      curCondtion.pos
    );
  };

  useEffect(() => {
    search();
  }, [page.curPage]);

  const handleTrClick = (e) => {
    // TR 클릭
    setIndexTR(Number(e.currentTarget.getAttribute("index")));

    const data = JSON.parse(e.currentTarget.dataset.datas);

    console.log("data", data);

    // 대리점
    agencyRef.current.value = data.AGENCY_CODE;
    // 프랜차이즈
    franRef.current.value = data.FRANCHISE_CODE;
    // 브랜드
    brandRef.current.value = data.BRAND_CODE;
    // 점코드
    storeRef.current.value = data.STORE_CD;
    // 포스번호
    posRef.current.value = data.POS_NO;
    // 대분류
    bigRef.current.value = data.ITEM_HEAD;
    // 중분류
    middleRef.current.value = data.ITEM_MEDIUM;
    // 소분류
    smallRef.current.value = data.ITEM_DETAIL;
    // 상품명
    goodsNmRef.current.value = data.GOODS_NM;
    // 상품코드
    goodsCodeRef.current.value = data.PLU_CD;
    // 판매가격
    priceRef.current.value = getCommpaPrice(data.SALEPRICE);
    // 한국어
    koreanRef.current.value = data.KOREAN;
    // 영어
    englishRef.current.value = data.ENGLISH;
    // 일본어
    japansesRef.current.value = data.JAPANESE;
    // 중국어
    chineseRef.current.value = data.CHINESE;
    // 버튼 이름
    let btnText = "";
    if (data.FLAG === "I") {
      btnText = "저장";
    } else {
      btnText = "수정";
    }

    btnSaveRef.current.innerText = btnText;
  };

  const handleBtnSave = (e) => {
    // 대리점
    const agency = agencyRef.current.value;
    // 프랜차이즈
    const fran = franRef.current.value;
    // 브랜드
    const brand = brandRef.current.value;
    // 점코드
    const store = storeRef.current.value;
    // 포스번호
    const pos = posRef.current.value;
    // 구분
    const gubun = "goods";
    // 대분류
    const big = bigRef.current.value;
    // 중분류
    const middle = middleRef.current.value;
    // 소분류
    const small = smallRef.current.value;
    // 한국어
    const korean = koreanRef.current.value;
    // 영어
    const english = englishRef.current.value;
    // 일본어
    const japanese = japansesRef.current.value;
    // 중국어
    const chinese = chineseRef.current.value;

    // 코드
    const code = goodsCodeRef.current.value;

    if (korean === "") {
      toast.error("한국어를 입력하세요");
      return;
    }
    if (english === "") {
      toast.error("영어를 입력하세요");
      return;
    }
    if (japanese === "") {
      toast.error("일본어를 입력하세요");
      return;
    }
    if (chinese === "") {
      toast.error("중국어를 입력하세요");
      return;
    }

    let url = "";
    let method = "POST";

    if (btnSaveRef.current.innerText === "저장") {
      url = `${serverUrl}/lang/addLang?agency=${agency}&fran=${fran}&brand=${brand}&store=${store}&pos=${pos}&code=${code}
		&gubun=${gubun}&big=${big}&middle=${middle}&small=${small}&korean=${korean}&english=${english}&japanese=${japanese}
		&chinese=${chinese}&lvl=${0}`;

      method = "POST";
    } else {
      url = `${serverUrl}/lang/editLang?agency=${agency}&fran=${fran}&brand=${brand}&store=${store}&pos=${pos}&code=${code}
		&gubun=${gubun}&big=${big}&middle=${middle}&small=${small}&korean=${korean}&english=${english}&japanese=${japanese}
		&chinese=${chinese}`;

      method = "PUT";
    }

    console.log("url", url);
    fetchData(method, url)
      .then((data) => {
        // 성공적으로 데이터를 가져왔을 때의 처리
        console.log("addLang fetched: ", data);

        if (data.header) {
          //console.log("data.header", data.header);
          if (data.header.res_code === "ok") {
            //setGoodsList(data.data);
            console.log("다국어 입력 => ", data.data);
            search();
          } else {
            // 에러 메시지 표현
            //setGoodsList([]);
            console.log(data.header.res_code, data.header.res_msg);
            search();
          }
        } else {
          //setGoodsList([]);
          console.error("header 값이 없음");
          search();
        }
      })
      .catch((error) => {
        // fetchData 함수 내에서 발생한 에러를 처리
        //setGoodsList([]);
        console.error("Error in addLang fetchData: ", error);
        search();
      });
  };

  const handleBtnDel = (e) => {
    console.log("삭제 버튼 클릭");
    const data = JSON.parse(e.currentTarget.dataset.datas);
    console.log("삭제 정보", data);

    // 대리점
    const agency = data.AGENCY_CODE;
    // 프랜차이즈
    const fran = data.FRANCHISE_CODE;
    // 브랜드
    const brand = data.BRAND_CODE;
    // 점코드
    const store = data.STORE_CD;
    // 포스번호
    const pos = data.POS_NO;
    // 대분류
    const big = data.ITEM_HEAD;
    // 중분류
    const middle = data.ITEM_MEDIUM;
    // 소분류
    const small = data.ITEM_DETAIL;
    // 상품코드
    const itemCode = data.PLU_CD;
    // 구분
    const gubun = "goods";

    let url = `${serverUrl}/lang/deleteLang?agency=${agency}&fran=${fran}&brand=${brand}&store=${store}&pos=${pos}&code=${itemCode}
	&gubun=${gubun}&big=${big}&middle=${middle}&small=${small}&lvl=0`;
    let method = "DELETE";

    console.log("url", url);
    fetchData(method, url)
      .then((data) => {
        // 성공적으로 데이터를 가져왔을 때의 처리
        console.log("addLang fetched: ", data);

        if (data.header) {
          //console.log("data.header", data.header);
          if (data.header.res_code === "ok") {
            //setGoodsList(data.data);
            console.log("다국어 삭제 => ", data.data);
            search();
          } else {
            // 에러 메시지 표현
            //setGoodsList([]);
            console.log(data.header.res_code, data.header.res_msg);
            search();
          }
        } else {
          //setGoodsList([]);
          console.error("header 값이 없음");
          search();
        }
      })
      .catch((error) => {
        // fetchData 함수 내에서 발생한 에러를 처리
        //setGoodsList([]);
        console.error("Error in addLang fetchData: ", error);
        search();
      });
  };

  return (
    <div>
      {/* <div id="overlay" class="transparent"></div> */}
      <div id="wrapper" className="preload">
        <CommonHeader />
        <ASide index={2} gubun={"goods"} />

        <div id="main-container">
          <LangHeader
            title={"다국어(상품)"}
            onHandleCondtion={handleChangeCondtion}
            onHandleBtnReg={handleBtnRegGoods}
          />
          <div className="padding-md">
            <div className="panel panel-default">
              <div className="panel-body">
                <div
                  className="row"
                  style={{
                    borderBottom: "1px solid #dddddd",
                    marginBottom: "10px",
                  }}
                >
                  <div className="col-lg-4 col-sm-4 col-xs-4">
                    <span className="marR5">상품전체</span>
                    <span className="badge badge-info">
                      {getCommpaPrice(goodsCnt)}
                    </span>
                    <span className="marR5"> 다국어전체</span>
                    <span className="badge badge-info">
                      {getCommpaPrice(langCnt)}
                    </span>
                  </div>

                  <div className="col-lg-6 col-sm-6 col-xs-6 fl-right">
                    <div style={{ float: "left" }} className="marR2">
                      <select
                        className="form-control input-sm"
                        style={{ display: "inline-block", width: "150px" }}
                      >
                        <option value="">상품명</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control input-sm"
                          placeholder="검색어를 입력하세요."
                          ref={searchRef}
                        />
                        <span className="input-group-btn">
                          <button
                            className="btn btn-default btn-sm"
                            type="button"
                            onClick={handleSearch}
                          >
                            <i className="fa fa-search"></i>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 col2Sc ">
                    <div className="table-responsive ">
                      <table
                        className="table table-striped"
                        id="responsiveTable"
                      >
                        <thead>
                          <tr>
                            <th>No.</th>
                            {/* <th>상품 코드</th> */}
                            <th>상품명</th>
                            {/* <th>판매가격</th> */}
                            <th>한국어</th>
                            <th>영어</th>
                            <th>일본어</th>
                            <th>중국어</th>
                            <th className="tabletHidden">편집</th>
                          </tr>
                        </thead>
                        <tbody>
                          {goodsList.map((value, index) => (
                            <tr
                              key={index}
                              index={index}
                              onClick={handleTrClick}
                              data-datas={JSON.stringify(value)}
                              style={{
                                backgroundColor:
                                  index === indexTR ? "skyblue" : "",
                              }}
                              ref={trRef}
                            >
                              <td>
                                <span className="fcgreen">{value.RowNum}</span>
                              </td>
                              {/* <td>{value.PLU_CD}</td> */}
                              <td className="text-left">{value.GOODS_NM}</td>
                              {/* <td>
                                <span className="fcblue">
                                  {getCommpaPrice(value.SALEPRICE)}
                                </span>
                                원
                              </td> */}
                              <td className="text-left">{value.KOREAN}</td>
                              <td className="text-left">{value.ENGLISH}</td>
                              <td className="text-left">{value.JAPANESE}</td>
                              <td className="text-left">{value.CHINESE}</td>
                              <td>
                                {value.FLAG === "I" ? (
                                  ""
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-default fcred lightCustomModal_open"
                                    href="#lightCustomModal"
                                    data-datas={JSON.stringify(value)}
                                    onClick={handleBtnDel}
                                  >
                                    삭제
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="panel-footer clearfix">
                        <div className="search-pager fl-right">
                          <ul className="pagination pagination-sm pagination-split no-margin">
                            <li className="disabled">
                              <a>«</a>
                            </li>
                            {pageNumbers.map((pageNumber) => (
                              <li
                                key={pageNumber}
                                className={
                                  pageNumber == page.curPage && "active"
                                }
                                onClick={() => handlePageChange(pageNumber)}
                              >
                                <a>
                                  {pageNumber}
                                  {pageNumber == page.curPage && (
                                    <span className="sr-only">(current)</span>
                                  )}
                                </a>
                              </li>
                            ))}
                            <li>
                              <a>»</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col2Sc tabletHiddne ">
                    <form>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <span className="fcred">*</span> 대리점
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="대리점"
                              disabled
                              ref={agencyRef}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <span className="fcred">*</span> 프랜차이즈
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="프랜차이즈"
                              disabled
                              ref={franRef}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <span className="fcred">*</span> 브랜드
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="브랜드"
                              disabled
                              ref={brandRef}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <span className="fcred">*</span> 매장
                            </label>
                            <div className="form-inline no-margin">
                              <div className="input-group w100per">
                                <input
                                  type="text"
                                  className="form-control "
                                  placeholder="매장코드"
                                  disabled
                                  ref={storeRef}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <span className="fcred">*</span> POS
                            </label>
                            <input
                              type="text"
                              className="form-control "
                              placeholder="포스번호"
                              disabled
                              ref={posRef}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="line100 marT20 marB20"></div>
                      <div className="row">
                        <div className="col-md-12 form-group">
                          <label>
                            <span className="fcred">*</span> 상품분류
                          </label>
                          <div className="form-inline no-margin ">
                            <div
                              className="form-group"
                              style={{ width: "32%" }}
                            >
                              <input
                                type="text"
                                className="form-control "
                                placeholder="대분류"
                                disabled
                                ref={bigRef}
                              />
                            </div>
                            <div
                              className="form-group"
                              style={{ width: "32%" }}
                            >
                              <input
                                type="text"
                                className="form-control "
                                placeholder="중분류"
                                disabled
                                ref={middleRef}
                              />
                            </div>
                            <div
                              className="form-group"
                              style={{ width: "32%" }}
                            >
                              <input
                                type="text"
                                className="form-control "
                                placeholder="소분류"
                                disabled
                                ref={smallRef}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-8">
                          <label>
                            <span className="fcred">*</span> 상품명
                          </label>
                          <div className="form-inline no-margin">
                            <div className="input-group w100per">
                              <input
                                type="text"
                                className="form-control input-sm w50per"
                                placeholder="상품명"
                                disabled
                                ref={goodsNmRef}
                              />
                              <input
                                type="text"
                                className="form-control  input-sm w50per"
                                placeholder="상품코드"
                                disabled
                                ref={goodsCodeRef}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              <span className="fcred">*</span> 판매가격
                            </label>
                            <div className="form-inline no-margin">
                              <div
                                className="input-group"
                                style={{ width: "70%" }}
                              >
                                <input
                                  type="text"
                                  className="form-control input-sm"
                                  placeholder="판매가격"
                                  disabled
                                  ref={priceRef}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <label>
                            <span className="fcred">*</span> 한국어
                          </label>
                          <div className="form-inline no-margin">
                            <div className="input-group w100per">
                              <input
                                type="text"
                                className="form-control input-sm w100per"
                                placeholder="한국어"
                                ref={koreanRef}
                              />
                            </div>
                            <label>
                              <span className="fcred">*</span> 영어
                            </label>
                            <div className="form-inline no-margin">
                              <div className="input-group w100per">
                                <input
                                  type="text"
                                  className="form-control  input-sm w100per"
                                  placeholder="영어"
                                  ref={englishRef}
                                />
                              </div>
                            </div>
                            <label>
                              <span className="fcred">*</span> 일본어
                            </label>
                            <div className="form-inline no-margin">
                              <div className="input-group w100per">
                                <input
                                  type="text"
                                  className="form-control  input-sm w100per"
                                  placeholder="일본어"
                                  ref={japansesRef}
                                />
                              </div>
                            </div>
                            <label>
                              <span className="fcred">*</span> 중국어
                            </label>
                            <div className="form-inline no-margin">
                              <div className="input-group w100per">
                                <input
                                  type="text"
                                  className="form-control  input-sm w100per"
                                  placeholder="중국어"
                                  ref={chineseRef}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>

                    <div className="line100 marT20"></div>
                    <div className="row">
                      <div
                        className="modal-footer"
                        style={{ borderTop: "0px" }}
                      >
                        <a
                          className="btn btn-navy "
                          ref={btnSaveRef}
                          onClick={handleBtnSave}
                        >
                          저장
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="formModal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close fl-right"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  &times;
                </button>
                <span className="fcred fl-right marR20">* 필수입력</span>
                <h4>상품 등록</h4>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="modal-footer" style={{ borderTop: "0px" }}>
                    <button
                      className="btn btn-success "
                      data-dismiss="modal"
                      aria-hidden="true"
                    >
                      취소
                    </button>
                    <a className="btn btn-navy ">저장</a>
                  </div>
                </div>
                {/* <form> */}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>프랜차이즈</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="프랜차이즈"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        <span className="fcred">*</span> 브랜드
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="브랜드"
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        <span className="fcred">*</span> 매장
                      </label>
                      <div className="form-inline no-margin">
                        <div className="input-group w100per">
                          <input
                            type="text"
                            className="form-control "
                            placeholder=""
                          />
                          <div className="input-group-btn">
                            <button
                              type="button"
                              className="btn  btn-primary"
                              tabindex="-1"
                            >
                              검색
                            </button>
                          </div>

                          <input
                            type="text"
                            className="form-control "
                            placeholder="매장코드"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        <span className="fcred">*</span> POS 선택
                      </label>
                      <select className="form-control">
                        <option value="" selected>
                          선택하세요.
                        </option>
                        <option value="1">POS 1</option>
                        <option value="2">POS 2</option>
                        <option value="3">POS 3</option>
                        <option value="4">POS 4</option>
                        <option value="5">POS 5</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="line100 marT20 marB20"></div>
                <div className="row">
                  <div className="col-md-12 form-group">
                    <label>
                      <span className="fcred">*</span> 상품분류
                    </label>
                    <div className="form-inline no-margin ">
                      <div className="form-group" style={{ width: "32%" }}>
                        <select className="form-control input-sm w100per">
                          <option value="" selected>
                            대분류 선택aaaaaaaaaaaaa
                          </option>
                          <option value="1">분류001</option>
                          <option value="2">분류002</option>
                          <option value="3">분류003</option>
                          <option value="4">분류004</option>
                          <option value="5">분류005</option>
                        </select>
                      </div>

                      <div className="form-group" style={{ width: "32%" }}>
                        <select className="form-control input-sm w100per">
                          <option value="" selected>
                            중분류 선택
                          </option>
                          <option value="1">분류001</option>
                          <option value="2">분류002ssssssssssssssssss</option>
                          <option value="3">분류003</option>
                          <option value="4">분류004</option>
                          <option value="5">분류005</option>
                        </select>
                      </div>

                      <div className="form-group" style={{ width: "32%" }}>
                        <select className="form-control input-sm w100per">
                          <option value="" selected>
                            소분류 선택
                          </option>
                          <option value="1">분류001</option>
                          <option value="2">분류002</option>
                          <option value="3">분류003</option>
                          <option value="4">분류004</option>
                          <option value="5">분류005</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-8">
                    <label>
                      <span className="fcred">*</span> 상품명
                    </label>
                    <div className="form-inline no-margin">
                      <div className="input-group w100per">
                        <input
                          type="text"
                          className="form-control input-sm w50per"
                          placeholder=""
                        />
                        <input
                          type="text"
                          className="form-control  input-sm w50per"
                          placeholder="상품코드"
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <label></label>
                    <div className="form-group marT10">
                      <label className="label-checkbox">
                        <input type="checkbox" />
                        <span className="custom-checkbox"></span>
                        세트 및 코스 가능
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        <span className="fcred">*</span> 판매여부
                      </label>
                      <div className="form-inline ">
                        <label className="label-radio inline fw-normal ">
                          <input type="radio" name="inline-radio" />
                          <span className="custom-radio"></span>
                          판매
                        </label>
                        <label className="label-radio inline fw-normal">
                          <input type="radio" name="inline-radio" />
                          <span className="custom-radio"></span>
                          중지
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="line100 marT20 marB20"></div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        <span className="fcred">*</span> 판매가격
                      </label>
                      <div className="form-inline no-margin">
                        <div className="input-group" style={{ width: "70%" }}>
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder=""
                          />
                        </div>

                        <select
                          className="form-control input-sm"
                          style={{ width: "29%" }}
                        >
                          <option value="" selected>
                            원(KRW)
                          </option>
                          <option value="1">달러(USD)</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        <span className="fcred">*</span> 과세 여부
                      </label>
                      <div className="row ">
                        <div className="col-md-6 col-sm-6 col-xs-6">
                          <select className="form-control input-sm">
                            <option value="" selected>
                              과세
                            </option>
                            <option value="">비과세</option>
                          </select>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-6">
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control input-sm"
                              placeholder=""
                            />
                            <span className="input-group-addon">%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        <span className="fcred">*</span> 할인 적용
                      </label>
                      <div className="row">
                        <div className="col-md-4 col-sm-4 col-xs-4">
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control input-sm"
                              placeholder=""
                            />
                            <span className="input-group-addon">%</span>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-4">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder="0"
                            disabled
                          />
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-4">
                          <label className="label-checkbox">
                            <input type="checkbox" />
                            <span className="custom-checkbox"></span>
                            세트 및 코스 가능
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        <span className="fcred">*</span> 제휴 할인
                      </label>
                      <div className="row">
                        <div className="col-md-6 col-sm-6 col-xs-6">
                          <select className="form-control input-sm">
                            <option value="" selected>
                              통신사
                            </option>
                            <option value="1">SKT</option>
                            <option value="1">KT</option>
                          </select>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-6">
                          <label className="label-checkbox">
                            <input type="checkbox" />
                            <span className="custom-checkbox"></span>
                            중복 할인 가능
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label> 특별판매가격</label>
                      <input
                        type="text"
                        className="form-control input-sm"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 form-group">
                    <label>특별판매기간</label>
                    <div className="form-inline no-margin">
                      <div className="form-group" style={{ width: "42%" }}>
                        <div className="input-group">
                          <input
                            type="text"
                            value="2023-06-01"
                            className="form-control input-sm input-group date"
                          />
                          <span className="input-group-addon">
                            <i className="fa fa-calendar"></i>
                          </span>
                        </div>
                      </div>
                      <div
                        className="form-group"
                        style={{ width: "6%", textAlign: "center" }}
                      >
                        ~
                      </div>
                      <div className="form-group" style={{ width: "42%" }}>
                        <div className="input-group">
                          <input
                            type="text"
                            value="2023-06-01"
                            className="form-control input-sm input-group date"
                          />
                          <span className="input-group-addon">
                            <i className="fa fa-calendar"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label>시간</label>
                    <div className="form-inline no-margin">
                      <div className="form-group" style={{ width: "42%" }}>
                        <div className="input-group bootstrap-timepicker">
                          <input
                            className="form-control input-sm input-group time"
                            type="text"
                          />
                          <span className="input-group-addon">
                            <i className="fa fa-clock-o"></i>
                          </span>
                        </div>
                      </div>
                      <div
                        className="form-group"
                        style={{ width: "6%", textAlign: "center" }}
                      >
                        ~
                      </div>
                      <div className="form-group" style={{ width: "42%" }}>
                        <div className="input-group bootstrap-timepicker">
                          <input
                            className="form-control input-sm input-group time"
                            type="text"
                          />
                          <span className="input-group-addon">
                            <i className="fa fa-clock-o"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 form-group">
                    <div>
                      <label>요일</label>
                    </div>
                    <label className="label-checkbox fw-normal inline">
                      <input type="checkbox" />
                      <span className="custom-checkbox"></span>월
                    </label>
                    <label className="label-checkbox fw-normal inline">
                      <input type="checkbox" />
                      <span className="custom-checkbox"></span>화
                    </label>
                    <label className="label-checkbox fw-normal inline">
                      <input type="checkbox" />
                      <span className="custom-checkbox"></span>수
                    </label>
                    <label className="label-checkbox fw-normal inline">
                      <input type="checkbox" />
                      <span className="custom-checkbox"></span>목
                    </label>
                    <label className="label-checkbox fw-normal inline">
                      <input type="checkbox" />
                      <span className="custom-checkbox"></span>금
                    </label>
                    <label className="label-checkbox fw-normal inline">
                      <input type="checkbox" />
                      <span className="custom-checkbox"></span>토
                    </label>
                    <label className="label-checkbox fw-normal inline">
                      <input type="checkbox" />
                      <span className="custom-checkbox"></span>일
                    </label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>매장 판매 가격</label>
                      <input
                        type="text"
                        className="form-control input-sm"
                        placeholder="숫자입력"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>포장 판매 가격</label>
                      <input
                        type="text"
                        className="form-control input-sm"
                        placeholder="숫자입력"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>배달 판매 가격</label>
                      <input
                        type="text"
                        className="form-control input-sm"
                        placeholder="숫자입력"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>소득공제 적용</label>
                      <select className="form-control input-sm">
                        <option value="" selected>
                          도서
                        </option>
                        <option value="1">선택 1</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>영수증 표기</label>
                      <select className="form-control input-sm">
                        <option value="" selected>
                          표기
                        </option>
                        <option value="1">선택 1</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>상품 재고</label>
                      <div className="form-inline no-margin">
                        <select className="form-control input-sm">
                          <option value="" selected>
                            재고량
                          </option>
                          <option value="1">선택 1</option>
                        </select>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control input-sm"
                            placeholder=""
                          />
                          <span className="input-group-addon">개</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>품절 상태</label>
                      <div className="form-inline ">
                        <label className="label-radio inline fw-normal ">
                          <input type="radio" name="inline-radio" />
                          <span className="custom-radio"></span>
                          자동 표기
                        </label>
                        <label className="label-radio inline fw-normal">
                          <input type="radio" name="inline-radio" />
                          <span className="custom-radio"></span>
                          수동 입력
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>구매 허용 수량</label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control input-sm"
                          placeholder=""
                        />
                        <span className="input-group-addon">개</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>성인 인증</label>
                      <label className="label-checkbox fw-normal">
                        <input type="checkbox" />
                        <span className="custom-checkbox"></span>
                        성인 인증 사용
                      </label>
                    </div>
                  </div>
                </div>

                <div className="line100 marT20"></div>
                <div className="marT10 row">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <h4 className="fw600">옵션정보 </h4>
                  </div>
                  <div className="navbar-right marR20 marT5">
                    <a
                      href="#agencyModal"
                      className="btn btn-sm btn-danger"
                      data-dismiss="modal"
                      aria-hidden="true"
                      role="button"
                      data-toggle="modal"
                      data-backdrop="static"
                      data-keyboard="false"
                    >
                      <i className="fa fa-tasks"></i> 옵션추가
                    </a>
                  </div>
                </div>
                <div className="form-group marT20 madalTable">
                  <div className="panel panel-default table-responsive">
                    <table
                      className="table table-striped fontSize13"
                      id="responsiveTable"
                    >
                      <thead>
                        <tr>
                          <th>노출순서</th>
                          <th>옵션코드</th>
                          <th>옵션선택</th>
                          <th>옵션기능</th>
                          <th>필수여부</th>
                          <th>옵션 분류 수</th>
                          <th>수량조절</th>
                          <th>편집</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>9000221</td>
                          <td>
                            <select className="form-control input-sm">
                              <option value="">상품 크기 변경</option>
                              <option value="">상품 온도 설정</option>
                              <option value="">상품 농도 조절</option>
                              <option value="">상품 당도 조절</option>
                              <option value="">토핑 재료 추가</option>
                            </select>
                          </td>
                          <td>단수선택</td>
                          <td>필수</td>
                          <td>3</td>
                          <td>불가</td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-sm btn-default fcblue"
                            >
                              상세
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-default fcred lightCustomModal_open"
                              href="#lightCustomModal"
                            >
                              삭제
                            </button>
                          </td>
                        </tr>

                        <tr>
                          <td>1</td>
                          <td>9000221</td>
                          <td>
                            <select className="form-control input-sm">
                              <option value="">상품 크기 변경</option>
                              <option value="">상품 온도 설정</option>
                              <option value="">상품 농도 조절</option>
                              <option value="">상품 당도 조절</option>
                              <option value="">토핑 재료 추가</option>
                            </select>
                          </td>
                          <td>단수선택</td>
                          <td>필수</td>
                          <td>3</td>
                          <td>불가</td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-sm btn-default fcblue"
                            >
                              상세
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-default fcred lightCustomModal_open"
                              href="#lightCustomModal"
                            >
                              삭제
                            </button>
                          </td>
                        </tr>

                        <tr>
                          <td>1</td>
                          <td>9000221</td>
                          <td>
                            <select className="form-control input-sm">
                              <option value="">상품 크기 변경</option>
                              <option value="">상품 온도 설정</option>
                              <option value="">상품 농도 조절</option>
                              <option value="">상품 당도 조절</option>
                              <option value="">토핑 재료 추가</option>
                            </select>
                          </td>
                          <td>단수선택</td>
                          <td>필수</td>
                          <td>3</td>
                          <td>불가</td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-sm btn-default fcblue"
                            >
                              상세
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-default fcred lightCustomModal_open"
                              href="#lightCustomModal"
                            >
                              삭제
                            </button>
                          </td>
                        </tr>

                        <tr>
                          <td>1</td>
                          <td>9000221</td>
                          <td>
                            <select className="form-control input-sm">
                              <option value="">상품 크기 변경</option>
                              <option value="">상품 온도 설정</option>
                              <option value="">상품 농도 조절</option>
                              <option value="">상품 당도 조절</option>
                              <option value="">토핑 재료 추가</option>
                            </select>
                          </td>
                          <td>단수선택</td>
                          <td>필수</td>
                          <td>3</td>
                          <td>불가</td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-sm btn-default fcblue"
                            >
                              상세
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-default fcred lightCustomModal_open"
                              href="#lightCustomModal"
                            >
                              삭제
                            </button>
                          </td>
                        </tr>

                        <tr>
                          <td>1</td>
                          <td>9000221</td>
                          <td>
                            <select className="form-control input-sm">
                              <option value="">상품 크기 변경</option>
                              <option value="">상품 온도 설정</option>
                              <option value="">상품 농도 조절</option>
                              <option value="">상품 당도 조절</option>
                              <option value="">토핑 재료 추가</option>
                            </select>
                          </td>
                          <td>단수선택</td>
                          <td>필수</td>
                          <td>3</td>
                          <td>불가</td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-sm btn-default fcblue"
                            >
                              상세
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-default fcred lightCustomModal_open"
                              href="#lightCustomModal"
                            >
                              삭제
                            </button>
                          </td>
                        </tr>

                        <tr>
                          <td>1</td>
                          <td>9000221</td>
                          <td>
                            <select className="form-control input-sm">
                              <option value="">상품 크기 변경</option>
                              <option value="">상품 온도 설정</option>
                              <option value="">상품 농도 조절</option>
                              <option value="">상품 당도 조절</option>
                              <option value="">토핑 재료 추가</option>
                            </select>
                          </td>
                          <td>단수선택</td>
                          <td>필수</td>
                          <td>3</td>
                          <td>불가</td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-sm btn-default fcblue"
                            >
                              상세
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-default fcred lightCustomModal_open"
                              href="#lightCustomModal"
                            >
                              삭제
                            </button>
                          </td>
                        </tr>

                        <tr>
                          <td>1</td>
                          <td>9000221</td>
                          <td>
                            <select className="form-control input-sm">
                              <option value="">상품 크기 변경</option>
                              <option value="">상품 온도 설정</option>
                              <option value="">상품 농도 조절</option>
                              <option value="">상품 당도 조절</option>
                              <option value="">토핑 재료 추가</option>
                            </select>
                          </td>
                          <td>단수선택</td>
                          <td>필수</td>
                          <td>3</td>
                          <td>불가</td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-sm btn-default fcblue"
                            >
                              상세
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-default fcred lightCustomModal_open"
                              href="#lightCustomModal"
                            >
                              삭제
                            </button>
                          </td>
                        </tr>

                        <tr>
                          <td>1</td>
                          <td>9000221</td>
                          <td>
                            <select className="form-control input-sm">
                              <option value="">상품 크기 변경</option>
                              <option value="">상품 온도 설정</option>
                              <option value="">상품 농도 조절</option>
                              <option value="">상품 당도 조절</option>
                              <option value="">토핑 재료 추가</option>
                            </select>
                          </td>
                          <td>단수선택</td>
                          <td>필수</td>
                          <td>3</td>
                          <td>불가</td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-sm btn-default fcblue"
                            >
                              상세
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-default fcred lightCustomModal_open"
                              href="#lightCustomModal"
                            >
                              삭제
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="line100 marT20"></div>
                <div className="row marT20">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>무게 / 용량</label>
                      <div className="form-inline no-margin">
                        <div className="form-group" style={{ width: "42%" }}>
                          <div className="input-group">
                            <input
                              type="text"
                              value="2023-06-01"
                              className="form-control input-sm"
                            />
                            <span className="input-group-addon">g</span>
                          </div>
                        </div>
                        <div
                          className="form-group"
                          style={{ width: "6%", textAlign: "center" }}
                        >
                          /
                        </div>
                        <div className="form-group" style={{ width: "42%" }}>
                          <div className="input-group">
                            <input
                              type="text"
                              value="2023-06-01"
                              className="form-control input-sm"
                            />
                            <span className="input-group-addon">ml</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>조리 예상 시간</label>
                      <div className="input-group">
                        <input
                          type="text"
                          value="10"
                          className="form-control input-sm"
                        />
                        <span className="input-group-addon">분</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>상품 설명</label>
                      <textarea className="form-control" rows="3"></textarea>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>원산지 표기</label>
                      <textarea className="form-control" rows="3"></textarea>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>행사 안내</label>
                      <textarea className="form-control" rows="3"></textarea>
                    </div>
                  </div>
                </div>

                <div className="line100 marT20"></div>
                <div className="row marT20">
                  <div className="col-md-12">
                    <input type="file" id="btnAtt" multiple="multiple" />
                    <div
                      id="att_zone"
                      data-placeholder="파일을 첨부 하려면 파일 선택 버튼을 클릭하거나 파일을 드래그앤드롭 하세요"
                    ></div>
                  </div>
                </div>
                <div className="line100 marT20"></div>
                <div className="row marT20">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>프린터 출력</label>
                      <div className="form-inline no-margin">
                        <select className="form-control input-sm" id="printer">
                          <option value="프린터 001" selected>
                            프린터 001
                          </option>
                          <option value="선택 1">선택 1</option>
                        </select>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control input-sm"
                            id="tag"
                            placeholder="장치명 입력후 엔터"
                          />
                          <div className="input-group-btn">
                            <button
                              type="button"
                              className="btn btn-sm btn-danger"
                              tabindex="-1"
                              id="doAction"
                            >
                              추가
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="input-group marT10">
                        <ul id="tag-list"></ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* </form> */}
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-success "
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  취소
                </button>
                <a href="#" className="btn btn-navy ">
                  저장
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="agencyModal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close fl-right"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  &times;
                </button>
                <span className="fcred fl-right marR20">* 필수입력</span>
                <h4>대리점 관리 매장 등록</h4>
              </div>
              <div className="modal-body">
                <form>
                  <div className="fl-right marT20">
                    <div style={{ float: "left" }} className="marR2">
                      <select
                        className="form-control input-sm"
                        style={{ display: "inline-block", width: "130px" }}
                      >
                        <option value="">상호</option>
                        <option value="">대표자</option>
                        <option value="">사업자등록번호</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control input-sm"
                          placeholder="검색어를 입력하세요."
                        />
                        <span className="input-group-btn">
                          <button
                            className="btn btn-default btn-sm"
                            type="button"
                          >
                            <i className="fa fa-search"></i>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group marT20 madalTable">
                    <div className="panel panel-default table-responsive">
                      <table
                        className="table table-striped fontSize13"
                        id="responsiveTable"
                      >
                        <thead>
                          <tr>
                            <th>
                              <input type="checkbox" id="catechkAll" />
                              <span className="custom-checkbox"></span>
                            </th>
                            <th>상태</th>
                            <th>분류</th>
                            <th>코드</th>
                            <th>상호</th>
                            <th>사업자등록번호</th>
                            <th>대표</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <input type="checkbox" name="catechk" id="" />
                              <span className="custom-checkbox"></span>
                            </td>
                            <td>
                              <span className="fcgreen">운영</span>
                            </td>
                            <td>프랜차이즈</td>
                            <td>500025</td>
                            <td className="text-left">하나시스정보통신</td>
                            <td>138-55-88975</td>
                            <td>홍길동</td>
                          </tr>
                          <tr>
                            <td>
                              <input type="checkbox" name="catechk" id="" />
                              <span className="custom-checkbox"></span>
                            </td>
                            <td>
                              <span className="fcred">미운영</span>
                            </td>
                            <td>프랜차이즈</td>
                            <td>500025</td>
                            <td className="text-left">하나시스정보통신</td>
                            <td>138-55-88975</td>
                            <td>홍길동</td>
                          </tr>
                          <tr>
                            <td>
                              <input type="checkbox" name="catechk" id="" />
                              <span className="custom-checkbox"></span>
                            </td>
                            <td>
                              <span className="fcgreen">운영</span>
                            </td>
                            <td>프랜차이즈</td>
                            <td>500025</td>
                            <td className="text-left">하나시스정보통신</td>
                            <td>138-55-88975</td>
                            <td>홍길동</td>
                          </tr>
                          <tr>
                            <td>
                              <input type="checkbox" name="catechk" id="" />
                              <span className="custom-checkbox"></span>
                            </td>
                            <td>운영</td>
                            <td>프랜차이즈</td>
                            <td>500025</td>
                            <td className="text-left">하나시스정보통신</td>
                            <td>138-55-88975</td>
                            <td>홍길동</td>
                          </tr>
                          <tr>
                            <td>
                              <input type="checkbox" name="catechk" id="" />
                              <span className="custom-checkbox"></span>
                            </td>
                            <td>운영</td>
                            <td>프랜차이즈</td>
                            <td>500025</td>
                            <td className="text-left">하나시스정보통신</td>
                            <td>138-55-88975</td>
                            <td>홍길동</td>
                          </tr>
                          <tr>
                            <td>
                              <input type="checkbox" name="catechk" id="" />
                              <span className="custom-checkbox"></span>
                            </td>
                            <td>운영</td>
                            <td>프랜차이즈</td>
                            <td>500025</td>
                            <td className="text-left">하나시스정보통신</td>
                            <td>138-55-88975</td>
                            <td>홍길동</td>
                          </tr>
                          <tr>
                            <td>
                              <input type="checkbox" name="catechk" id="" />
                              <span className="custom-checkbox"></span>
                            </td>
                            <td>운영</td>
                            <td>프랜차이즈</td>
                            <td>500025</td>
                            <td className="text-left">하나시스정보통신</td>
                            <td>138-55-88975</td>
                            <td>홍길동</td>
                          </tr>
                          <tr>
                            <td>
                              <input type="checkbox" name="catechk" id="" />
                              <span className="custom-checkbox"></span>
                            </td>
                            <td>운영</td>
                            <td>프랜차이즈</td>
                            <td>500025</td>
                            <td className="text-left">하나시스정보통신</td>
                            <td>138-55-88975</td>
                            <td>홍길동</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-success "
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  취소
                </button>
                <a href="#" className="btn btn-navy">
                  저장
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="excelModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close fl-right"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  &times;
                </button>
                <h4>엑셀파일 등록</h4>
              </div>
              <div className="modal-body">
                <div className="marB30">
                  <div className="alert">
                    <i className="fa fa-warning"></i>
                    <span className="m-left-xs">
                      지정된 형식의 엑셀파일만 등록이 가능합니다
                    </span>
                    <div className="m-left-xs">
                      STEP 1. 아래 [서식 파일 받기] 버튼을 클릭하여 엑셀 파일을
                      다운로드
                      <br />
                      STEP 1. 다운로드한 파일에 관련 정보를 입력한 후 저장
                      <br />
                      STEP 1. 아래 [파일 선택] 버튼을 클릭하여 저장된 엑셀
                      파일을 선택한 후, [등록] 버튼 클릭
                      <br />
                    </div>
                  </div>
                </div>
                <form action="." className="">
                  <div className="form-inline no-margin">
                    <input
                      name="file"
                      type="file"
                      multiple
                      className="form-control "
                      style={{ border: "0px solid #ffffff" }}
                    />
                    <div className="navbar-right">
                      <a
                        href=""
                        role="button"
                        className="btn btn-sm btn-default"
                      >
                        <i className="fa fa-download"></i> 서식 파일 받기
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-success "
                data-dismiss="modal"
                aria-hidden="true"
              >
                취소
              </button>
              <a href="#" className="btn btn-navy">
                일괄 등록
              </a>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={600} />
    </div>
  );
};

export default Lang;
