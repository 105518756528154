import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { fetchData, getAPIsUrl, getLocalAPIsUrl } from "../src/utils/api";

const serverUrl = getAPIsUrl();

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    checkLogin();
  }, []);

  const checkLogin = () => {
    const keepLogin = window.localStorage.getItem("keep_login") === "true";
    const loginInfo =
      JSON.parse(window.localStorage.getItem("login_info")) || {};

    if (keepLogin && loginInfo.ID && loginInfo.PASS2) {
      // 저장된 로그인 정보가 있는 경우
      fetchLogin(loginInfo.ID, loginInfo.PASS2)
        .then((data) => {
          // 로그인 성공 시 /category로 이동
          console.log("자동로그인");
          navigate("/category", { replace: true });
        })
        .catch((error) => {
          // 로그인 실패 시 로그인 페이지 유지
        });
    } else {
      console.log("자동로그인 아님");
    }
  };

  const fetchLogin = (id, password) => {
    // 로그인 API 호출 함수
    const loginUrl = `${serverUrl}/login/getLogin2?id=${id}&pass=${password}`;
    return fetchData("POST", loginUrl);
  };

  return children;
};

export default PrivateRoute;
