import React from "react";

const ExeclRecvButton = () => {
  return (
    <span>
      <a href="" role="button" className="btn btn-sm btn-default">
        <i className="fa fa-download"></i> 엑셀파일 받기
      </a>{" "}
    </span>
  );
};

export default ExeclRecvButton;
