import React from "react";
import ExcelRegButton from "../Button/ExcelRegButton";
import ExeclRecvButton from "../Button/ExeclRecvButton";

const CategoryHeader = ({ title }) => {
  return (
    <div className="main-header clearfix row">
      <div className="page-title col-sm-6 col-xs-6 col-lg-4">
        <h3 className="no-margin fw500 fcblack">{title}</h3>
      </div>

      <div className="navbar-right marR20 ">
        {/* 엑셀 기능이 되면 나중에 풀자 */}
        {/* <ExcelRegButton />
        <ExeclRecvButton /> */}
      </div>
    </div>
  );
};

export default CategoryHeader;
