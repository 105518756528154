import React from "react";

const ExcelRegButton = () => {
  return (
    <span>
      <a
        href="#excelModal"
        role="button"
        data-toggle="modal"
        className="btn btn-sm btn-success"
        data-backdrop="static"
        data-keyboard="false"
      >
        <i className="fa fa-upload"></i> 엑셀파일 등록
      </a>{" "}
    </span>
  );
};

export default ExcelRegButton;
