import React, { useState, useEffect } from "react";
import SelectBox from "../SelectBox/SelectBox";

const LangHeader = ({ title, onHandleCondtion, onHandleBtnReg }) => {
  const [selectedAgencyOption, setSelectedAgencyOption] = useState("");
  const handleAgencyChange = (agency) => {
    setSelectedAgencyOption(agency);
    console.log("★★★★★ 1. category agency change ★★★★★", agency);

    const timer = setTimeout(() => {
      //
    }, 500);

    //clearTimeout(timer);
  };

  const [selectedFranOption, setSelectedFranOption] = useState("");

  const handleFranChange = (fran) => {
    setSelectedFranOption(fran);
    console.log("★★★★★ 2. category fran change", fran);
  };

  const [selectedBrandOption, setSelectedBrandOption] = useState("");

  const handleBrandChange = (brand) => {
    setSelectedBrandOption(brand);
    console.log("★★★★★ 3. category brand change", brand);
  };

  const [selectedStoreOption, setSelectedStoreOption] = useState("");

  const handleStoreChange = (store) => {
    setSelectedStoreOption(store);
    console.log("★★★★★ 4. category store change", store);
  };

  const [selectedPosOption, setSelectedPosOption] = useState("");

  const handlePosChange = (pos) => {
    setSelectedPosOption(pos);
    console.log("★★★★★ 5. category pos change", pos);
  };

  const handleBtnClick = () => {
    console.log(
      "agency",
      selectedAgencyOption,
      "fran",
      selectedFranOption,
      "brand",
      selectedBrandOption,
      "store",
      selectedStoreOption,
      "pos",
      selectedPosOption
    );

    onHandleCondtion(
      selectedAgencyOption,
      selectedFranOption,
      selectedBrandOption,
      selectedStoreOption,
      selectedPosOption
    );
  };

  useEffect(() => {
    console.log(
      "❤︎❤︎❤︎❤︎❤︎❤︎ useEffect",
      "LangHeader",
      selectedAgencyOption,
      selectedFranOption,
      selectedBrandOption,
      selectedStoreOption,
      selectedPosOption
    );
    onHandleCondtion(
      selectedAgencyOption,
      selectedFranOption,
      selectedBrandOption,
      selectedStoreOption,
      selectedPosOption
    );

    onHandleBtnReg(
      selectedAgencyOption,
      selectedFranOption,
      selectedBrandOption,
      selectedStoreOption,
      selectedPosOption
    );
  }, [
    selectedAgencyOption,
    selectedFranOption,
    selectedBrandOption,
    selectedStoreOption,
    selectedPosOption,
  ]);

  const handleRegGoodsBtn = () => {
    console.group(
      "☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎"
    );
    console.log(
      "agency",
      selectedAgencyOption,
      "fran",
      selectedFranOption,
      "brand",
      selectedBrandOption,
      "store",
      selectedStoreOption,
      "pos",
      selectedPosOption
    );
    console.groupEnd(
      "☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎☀︎"
    );

    console.log("GoodsHeader >  상품등록 버튼 클릭");

    onHandleBtnReg(
      selectedAgencyOption,
      selectedFranOption,
      selectedBrandOption,
      selectedStoreOption,
      selectedPosOption
    );
  };

  return (
    <div>
      <div className="main-header clearfix row">
        <div className="page-title col-sm-6 col-xs-6 col-lg-2 col-md-2">
          <h3 className="no-margin fw500 fcblack">{title}</h3>
        </div>

        <SelectBox
          onAgencyChange={handleAgencyChange}
          onFranChange={handleFranChange}
          onBrandChange={handleBrandChange}
          onStoreChange={handleStoreChange}
          onPosChange={handlePosChange}
          onButtonClick={handleBtnClick}
          type={"goods"}
        />
        <div className="navbar-right marR20 marT5">
          {/* <span>
            <a
              href="#excelModal"
              role="button"
              data-toggle="modal"
              className="btn btn-sm btn-success"
              data-backdrop="static"
              data-keyboard="false"
            >
              <i className="fa fa-upload"></i> 엑셀파일 등록
            </a>{" "}
          </span>
          <span>
            <a href="" role="button" className="btn btn-sm btn-default">
              <i className="fa fa-download"></i> 엑셀파일 받기
            </a>{" "}
          </span> */}
        </div>
      </div>
    </div>
  );
};

export default LangHeader;
