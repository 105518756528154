import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

var menuGoodsItems = [
  { label: "카테고리 설정", href: "/category" },
  { label: "상품 관리", href: "/goods" },
  { label: "다국어(상품)", href: "/lang" },
  { label: "내 상품", href: "/mygoods" },
  // 추가 메뉴 항목
];

const ASide = ({ index, gubun }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  //   const displayStyleGoods = gubun === "goods" ? "block" : "none";
  //   const displayStyleUsers = gubun === "users" ? "block" : "none";
  let displayStyleGoods;
  let displayStyleUsers;

  if (gubun === "goods") {
    displayStyleGoods = "block";
    displayStyleUsers = "none";
  } else if (gubun == "users") {
    displayStyleGoods = "none";
    displayStyleUsers = "block";
  }

  console.log("displayStyleGoods", displayStyleGoods);
  console.log("displayStyleUsers", displayStyleUsers);

  //   const [menuGoodsItems, setMenuGoodsItems] = useState([
  //     { label: "카테고리 설정", href: "/category" },
  //     { label: "상품 관리", href: "/goods" },
  //     { label: "다국어(상품)", href: "/lang" },
  //     { label: "내 상품", href: "/mygoods" },
  //     // 추가 메뉴 항목
  //   ]);

  //   var menuGoodsItems = [
  //     { label: "카테고리 설정", href: "/category" },
  //     { label: "상품 관리", href: "/goods" },
  //     { label: "다국어(상품)", href: "/lang" },
  //     { label: "내 상품", href: "/mygoods" },
  //     // 추가 메뉴 항목
  //   ];

  const handleGoodsMenuClick = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    //alert("useEffect");

    setActiveIndex(index);
    //alert(menuGoodsItems);
  }, []);

  useEffect(() => {
    setActiveIndex(index);
  }, [index]);

  //   const renderMenuItems = () => {
  //     //alert("render");
  //     console.group("renderMenuItems");
  //     const items = [];
  //     for (let i = 0; i < menuGoodsItems.length; i++) {
  //       const item = menuGoodsItems[i];
  //       console.log(item);
  //       items.push(
  //         <li key={i} className={activeIndex === i ? "active" : ""}>
  //           <a href={item.href} onClick={() => handleGoodsMenuClick(i)}>
  //             <span className="submenu-label">{item.label}</span>
  //           </a>
  //         </li>
  //       );
  //     }
  //     console.groupEnd();
  //     return items;
  //   };
  return (
    <aside className="fixed skin-6">
      <div className="sidebar-inner scrollable-sidebars">
        <div className="size-toggle">
          <a className="btn btn-sm " id="sizeToggle">
            <i className="fa fa-exchange"></i>
          </a>
        </div>

        <div className="main-menu">
          <ul>
            {/* <li
              className={`${
                gubun === "users" ? "active openable open" : "openable open"
              }`}
            >
              <a href="#">
                <span className="menu-icon">
                  <i className="fa fa-file-text fa-lg"></i>
                </span>
                <span className="text">사용자정보관리</span>
                <span className="menu-hover"></span>
              </a>
              <ul className="submenu" style={{ display: displayStyleUsers }}>
                <li className={activeIndex === 11 ? "active" : ""}>
                  <a href="/agency">
                    <span className="submenu-label">대리점 관리</span>
                  </a>
                </li>
                <li className={activeIndex === 22 ? "active" : ""}>
                  <Link to="/fran">
                    <span className="submenu-label">프랜차이즈 관리</span>
                  </Link>
                </li>
                <li className={activeIndex === 33 ? "active" : ""}>
                  <a href="#">
                    <span className="submenu-label">브랜드 관리</span>
                  </a>
                </li>

                <li className={activeIndex === 44 ? "active" : ""}>
                  <a href="#">
                    <span className="submenu-label">매장 관리</span>
                  </a>
                </li>
                <li className={activeIndex === 55 ? "active" : ""}>
                  <a href="#">
                    <span className="submenu-label">POS 관리</span>
                  </a>
                </li>
                <li className={activeIndex === 66 ? "active" : ""}>
                  <a href="#">
                    <span className="submenu-label">사용자 정보</span>
                  </a>
                </li>
              </ul>
            </li> */}
            <li
              className={`${
                gubun === "goods" ? "active openable open" : "openable open"
              }`}
            >
              <a>
                <span className="menu-icon">
                  <i className="fa fa-tag fa-lg"></i>
                </span>
                <span className="text">상품정보관리</span>
                <span className="menu-hover"></span>
              </a>
              <ul className="submenu" style={{ display: displayStyleGoods }}>
                <li className={activeIndex === 0 ? "active" : ""}>
                  <Link to="/category">
                    <span className="submenu-label">카테고리 설정</span>
                  </Link>
                </li>
                <li className={activeIndex === 1 ? "active" : ""}>
                  <Link to="/goods">
                    <span className="submenu-label">상품 관리</span>
                  </Link>
                </li>
                <li className={activeIndex === 2 ? "active" : ""}>
                  <Link to="/lang">
                    <span className="submenu-label">다국어(상품)</span>
                  </Link>
                </li>
                <li className={activeIndex === 3 ? "active" : ""}>
                  <Link to="/mygoods">
                    <span className="submenu-label">내 상품</span>
                  </Link>
                </li>
              </ul>
            </li>

            {/* <li>
              <a href="timeline.html">
                <span className="menu-icon">
                  <i className="fa fa-home fa-lg"></i>
                </span>
                <span className="text">매장 운영 설정</span>
                <span className="menu-hover"></span>
              </a>
            </li> */}
            {/* <li>
              <a href="gallery.html">
                <span className="menu-icon">
                  <i className="fa fa-user fa-lg"></i>
                </span>
                <span className="text">고객 관리 설정</span>
                <span className="menu-hover"></span>
              </a>
            </li> */}
            {/* <li>
              <a href="inbox.html">
                <span className="menu-icon">
                  <i className="fa fa-bar-chart-o fa-lg"></i>
                </span>
                <span className="text">매출 현황 분석</span>
                <span className="menu-hover"></span>
              </a>
            </li> */}
            {/* <li>
              <a href="email_selection.html">
                <span className="menu-icon">
                  <i className="fa fa-cog fa-lg"></i>
                </span>
                <span className="text">시스템 관리</span>
                <span className="menu-hover"></span>
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </aside>
  );
};

export default ASide;
