import React, { useEffect } from "react";
import LoginForm from "../../component/Login/LoginForm";
import { setPageTitle } from "../../utils/util";
import { fetchData, getAPIsUrl, getLocalAPIsUrl } from "../../utils/api";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getLoginCheck } from "../../utils/util";

const serverUrl = getAPIsUrl();

const Login = () => {
  const navigate = useNavigate();
  setPageTitle("하나시스 로그인");
  const keep_login = window.localStorage.getItem("keep_login") || "false";

  useEffect(() => {
    console.log("Login useEffect");
    if (getLoginCheck()) {
      console.log("keep_login", keep_login);
      //alert(keep_login);
      // 자동로그인 처리
      if (keep_login === "true" || keep_login === true) {
        navigate("/category", { replace: true });
      }

      //return;
    }
  }, []);

  return (
    <div className="login-wrapper">
      <div className="text-center"></div>
      <div className="login-widget animation-delay1">
        <div className="panel login-box loginBg">
          <div className="text-center marT100">
            <h2 className="fadeInUp animation-delay8">
              <div>
                <img src={"img/logo.png"} className="max-w-90" />
              </div>
              <div className="login-head-text">
                Application Service Provider
              </div>
            </h2>
          </div>
          <LoginForm></LoginForm>
        </div>
      </div>
      <ToastContainer autoClose={600} />
    </div>
  );
};

export default Login;
