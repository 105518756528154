import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAPIsUrl, fetchData } from "../../utils/api";

const serverUrl = getAPIsUrl();

const Prelogin = () => {
  const navigate = useNavigate();

  const keepLogin = window.localStorage.getItem("keep_login") === "true";
  const loginInfo = JSON.parse(window.localStorage.getItem("login_info")) || {};

  useEffect(() => {
    //autoLogin();
  }, []);

  const autoLogin = () => {
    if (keepLogin && loginInfo.ID && loginInfo.PASS2) {
      // 저장된 로그인 정보가 있는 경우
      fetchLogin(loginInfo.ID, loginInfo.PASS2)
        .then((data) => {
          // 로그인 성공 시 /category로 이동
          console.log("자동로그인");
          navigate("/category", { replace: true });
        })
        .catch((error) => {
          // 로그인 실패 시 로그인 페이지 유지
          navigate("/login", { replace: true });
        });
    } else {
      console.log("자동로그인 아님");
      navigate("/login", { replace: true });
    }
  };

  const fetchLogin = (id, password) => {
    // 로그인 API 호출 함수
    const loginUrl = `${serverUrl}/login/getLogin2?id=${id}&pass=${password}`;
    return fetchData("POST", loginUrl);
  };

  return <div></div>;
};

export default Prelogin;
