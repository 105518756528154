import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Switch,
  Redirect,
  Navigate,
} from "react-router-dom";

import Login from "./pages/login/Login";
import Category from "./pages/category/Category";
import Goods from "./pages/goods/Goods";
import MyGoods from "./pages/mygoods/MyGoods";
import Main from "./pages/main/Main";
import Lang from "./pages/lang/Lang";
import PrivateRoute from "./PrivateRoute";
import Prelogin from "./pages/login/Prelogin";
import Fran from "./pages/fran/Fran";

const _Routes = () => {
  const isLoggedIn = !!localStorage.getItem("login_info") || null;

  return (
    <Routes>
      <Route
        path="/login"
        element={<Login />}
        //element={isLoggedIn ? <Login /> : <Navigate to="/category" />}
      />
      {/* <Route
        path="/login"
        element={
          <PrivateRoute>
            <Login />
          </PrivateRoute>
        } 
      /> */}

      {/* <Route
        path="/prelogin"
        element={isLoggedIn ? <Category /> : <Navigate to="/login" />}
      /> */}

      <Route path="/category" element={<Category />} />

      {/* <Route
        path="/category"
        element={isLoggedIn ? <Category /> : <Navigate to="/login" />}
      /> */}

      {/* <Route
        path="/category"
        element={isLoggedIn ? <Category /> : <Navigate to="/login" />}
      /> */}
      <Route
        path="/goods"
        // element={isLoggedIn ? <Goods /> : <Navigate to="/login" />}
        element={<Goods />}
      />
      <Route
        path="/mygoods"
        // element={isLoggedIn ? <MyGoods /> : <Navigate to="/login" />}
        element={<MyGoods />}
      />
      <Route
        path="/main"
        // element={isLoggedIn ? <Main /> : <Navigate to="/login" />}
        element={<Main />}
      />
      <Route
        path="/lang"
        // element={isLoggedIn ? <Lang /> : <Navigate to="/login" />}
        element={<Lang />}
      />
      <Route
        path="*"
        // element={
        //   isLoggedIn ? <Navigate to="/category" /> : <Navigate to="/login" />
        // }
        element={<Navigate to="/login" />}
      />

      <Route path="/fran" element={<Fran />}></Route>
    </Routes>
  );
};

export default _Routes;
