import React, { useEffect, useState, useMemo, useRef } from "react";
import ASide from "../../component/ASide/ASide";
import CommonHeader from "../../component/Header/CommonHeader";
import MyGoodsHeader from "../../component/Header/MyGoodsHeader";
import { fetchData, getAPIsUrl, getLocalAPIsUrl } from "../../utils/api";
import { getCommpaPrice, getContentType } from "../../utils/util";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import { getLoginCheck } from "../../utils/util";

const serverUrl = getAPIsUrl();

// add,edit 입력이냐 수정이냐
var editType = "add";

var curCondtion = {
  agency: "",
  fran: "",
  brand: "",
  store: "",
  pos: "",
};

const curCondtionInit = () => {
  curCondtion.agency = "";
  curCondtion.fran = "";
  curCondtion.brand = "";
  curCondtion.store = "";
  curCondtion.pos = "";
};

const MyGoods = () => {
  // 선택된 TR Index
  const [indexTR, setIndexTR] = useState(0);
  // 선택된 Goods TR Index
  const [indexGoodsTR, setIndexGoodsTR] = useState(0);
  // 내상품 전체 갯수
  const [myGoodsCnt, setMyGoodsCnt] = useState("0");
  // 내상품 리스트
  const [myGoodsList, setMyGoodsList] = useState([]);
  // 내상품 리스트 페이지 사이즈 , 현재 페이지
  const [page, setPage] = useState({ curPage: 1, pageSize: 10 });
  // 상품 리스트
  const [goodsList, setGoodsList] = useState([]);

  // 대리점
  const agencyRef = useRef(null);
  // 프랜차이즈
  const franRef = useRef(null);
  // 브랜드
  const brandRef = useRef(null);
  // 점코드
  const storeRef = useRef(null);
  // 포스번호
  const posRef = useRef(null);
  // 상품명
  const goodsOriRef = useRef(null);

  // 내상품 등록 / 수정시 필요한 상품명
  const goodsNmRef = useRef(null);
  // 내상품 등록 / 수정시 필요한 상품코드
  const goodsIdRef = useRef(null);

  // 판매 여부
  const saleYNRef = useRef(null);
  // 상품상태
  const goodsStateRef = useRef(null);
  // 크기 x
  const xSizeRef = useRef(null);
  // 크기 y
  const ySizeRef = useRef(null);
  // 위치 x
  const xRef = useRef(null);
  // 위치 y
  const yRef = useRef(null);
  // 재고
  const stockRef = useRef(null);
  // 순서
  const orderRef = useRef(null);
  // 버튼
  const btnSave = useRef(null);
  // 원래 위치 x
  const oriXRef = useRef(null);
  // 원래 위치 y
  const oriYRef = useRef(null);
  // 검색어
  const keyWordRef = useRef(null);

  const navigate = useNavigate();

  const totalPages = useMemo(
    () => Math.ceil(myGoodsCnt / page.pageSize),
    [myGoodsCnt, page.pageSize]
  );

  const pageNumbers = useMemo(() => {
    const numbers = [];
    for (let i = 1; i <= totalPages; i++) {
      numbers.push(i);
    }
    return numbers;
  }, [totalPages]);

  const handleChangeCondtion = async (agency, fran, brand, store, pos) => {
    curCondtion.agency = agency;
    curCondtion.fran = fran;
    curCondtion.brand = brand;
    curCondtion.store = store;
    curCondtion.pos = pos;

    console.group("handleChangeCondtion");
    console.log(
      "MyGoods > handleChangeCondtion",
      curCondtion.agency,
      curCondtion.fran,
      curCondtion.brand,
      curCondtion.store,
      curCondtion.pos
    );
    console.groupEnd();

    search();
  };

  const handleBtnRegGoods = (agency, fran, brand, store, pos) => {
    editType = "add";
    btnSave.current.innerText = "저장";

    $("#search_things").show();
    $("#sales_list").show();

    curCondtion.agency = agency;
    curCondtion.fran = fran;
    curCondtion.brand = brand;
    curCondtion.store = store;
    curCondtion.pos = pos;

    console.group("handleBtnRegGoods");
    console.log(
      "MyGoods > handleBtnRegGoods 내상품 등록",
      curCondtion.agency,
      curCondtion.fran,
      curCondtion.brand,
      curCondtion.store,
      curCondtion.pos
    );
    console.groupEnd();

    // 대리점
    agencyRef.current.value = agency;
    // 프랜차이즈
    franRef.current.value = fran;
    // 브랜드
    brandRef.current.value = brand;
    // 점코드
    storeRef.current.value = store;
    // 포스번호
    posRef.current.value = pos;

    search();

    // 선택상품
    goodsNmRef.current.value = "";
    // 상품코드
    goodsIdRef.current.value = "";

    saleYNRef.current.value = "Y";
    goodsStateRef.current.value = "normal";
    xSizeRef.current.value = "";
    ySizeRef.current.value = "";
    xRef.current.value = "";
    yRef.current.value = "";
    stockRef.current.value = "";
    orderRef.current.value = "";

    goodsSeach();
  };

  useEffect(() => {
    const keep_login = window.localStorage.getItem("keep_login") || "false";
    if (!getLoginCheck()) {
      navigate("/login", { replace: true });
      return;
    } else {
      //   if (keep_login === "false" || keep_login === false) {
      //     navigate("/login", { replace: true });
      //     return;
      //   }
    }

    // 현재 정보 초기화
    curCondtionInit();
    setIndexTR(0);

    search();
  }, []);

  const search = () => {
    getMyGoodsTotCount();
    getMyGoodsList();
  };

  const getMyGoodsTotCount = () => {
    console.group("getMyGoodsTotCount");
    console.log("getMyGoodsTotCount", curCondtion);
    console.log(curCondtion.agency);
    console.groupEnd();

    const keyWord = keyWordRef.current.value;

    const url = `${serverUrl}/mygoods/getMyGoodsTotCount?agency=${curCondtion.agency}&fran=${curCondtion.fran}&brand=${curCondtion.brand}&store=${curCondtion.store}&pos=${curCondtion.pos}&goods_nm=${keyWord}`;
    console.log("url", url);
    fetchData("GET", url)
      .then((data) => {
        // 성공적으로 데이터를 가져왔을 때의 처리
        //console.log("Data fetched: ", data);

        if (data.header) {
          //console.log("data.header", data.header);
          if (data.header.res_code === "ok") {
            setMyGoodsCnt(data.data[0].myGoodsTotCnt);
            console.log("내상품 총 갯수  => ", data.data);
          } else {
            // 에러 메시지 표현
            setMyGoodsCnt("0");
            console.log(data.header.res_code, data.header.res_msg);
          }
        } else {
          setMyGoodsCnt("0");
          console.error("header 값이 없음");
        }
      })
      .catch((error) => {
        // fetchData 함수 내에서 발생한 에러를 처리
        console.error("Error in fetchData: ", error);
      });
  };

  const getMyGoodsList = () => {
    console.group("getMyGoodsList");
    console.log("getMyGoodsList", curCondtion);
    console.log(curCondtion.agency);
    console.groupEnd();

    const keyWord = keyWordRef.current.value;

    const url = `${serverUrl}/mygoods/getMyGoodsList?pageNumber=${page.curPage}&pageSize=${page.pageSize}&agency=${curCondtion.agency}&fran=${curCondtion.fran}&brand=${curCondtion.brand}&store=${curCondtion.store}&pos=${curCondtion.pos}&goods_nm=${keyWord}`;
    console.log("url", url);
    fetchData("GET", url)
      .then((data) => {
        // 성공적으로 데이터를 가져왔을 때의 처리
        //console.log("Data fetched: ", data);

        if (data.header) {
          //console.log("data.header", data.header);
          if (data.header.res_code === "ok") {
            setMyGoodsList(data.data);
            console.log("내상품 리스트  => ", data.data);
          } else {
            // 에러 메시지 표현
            setMyGoodsList([]);
            console.log(data.header.res_code, data.header.res_msg);
          }
        } else {
          setMyGoodsList([]);
          console.error("header 값이 없음");
        }
      })
      .catch((error) => {
        // fetchData 함수 내에서 발생한 에러를 처리
        console.error("Error in fetchData: ", error);
      });
  };

  const handleTrClick = () => {
    console.log("Tr Click");
  };

  const handleGoodsTrClick = (e) => {
    console.log("Goods TR Click");
    // TR 클릭
    setIndexGoodsTR(Number(e.currentTarget.getAttribute("index")));

    const data = JSON.parse(e.currentTarget.dataset.datas);

    console.log("data", data);

    // 상품명
    goodsNmRef.current.value = data.GOODS_NM;
    // 상품코드
    goodsIdRef.current.value = data.PLU_CD;
  };

  const handleMyGoodSearch = () => {
    search();
  };

  const handlePageChange = (pageNumber) => {
    setPage({ ...page, curPage: Number(pageNumber) });
  };

  useEffect(() => {
    search();
  }, [page.curPage]);

  const btnGoodsSearch = (e) => {
    goodsSeach();
  };

  const goodsSeach = () => {
    console.group("매장내 원래 상품 리스트");
    console.log("매장내 원래 상품 리스트");
    console.groupEnd();

    console.group("goodsSeach");
    console.log("goodsSeach", curCondtion);
    console.log(curCondtion.agency);

    const url = `${serverUrl}/goods/getGoodsList?agency=${curCondtion.agency}&fran=${curCondtion.fran}&brand=${curCondtion.brand}&store=${curCondtion.store}&pos=${curCondtion.pos}&goods_nm=${goodsOriRef.current.value}`;
    console.log("url", url);
    fetchData("GET", url)
      .then((data) => {
        // 성공적으로 데이터를 가져왔을 때의 처리
        //console.log("Data fetched: ", data);

        if (data.header) {
          //console.log("data.header", data.header);
          if (data.header.res_code === "ok") {
            setGoodsList(data.data);
            console.log("상품 리스트  => ", data.data);
          } else {
            // 에러 메시지 표현
            setGoodsList([]);
            console.log(data.header.res_code, data.header.res_msg);
          }
        } else {
          setGoodsList([]);
          console.error("header 값이 없음");
        }
      })
      .catch((error) => {
        // fetchData 함수 내에서 발생한 에러를 처리
        console.error("Error in fetchData: ", error);
      });

    console.groupEnd();
  };

  const handleBtnSave = () => {
    console.log("저장 버튼 클릭");

    // 상품 아이디
    const goodsId = goodsIdRef.current.value;
    if (goodsId === "") {
      toast.error("상품 아이디를 입력하세요.");
      goodsIdRef.current.focus();
      return;
    }
    // 판매 여부
    const saleYN = saleYNRef.current.value;
    if (saleYN === "") {
      toast.error("판매여부를 선택하세요.");
      saleYNRef.current.focus();
      return;
    }
    console.log("판매여부", saleYN);

    // 상품상태
    const goodsState = goodsStateRef.current.value;
    if (goodsState === "") {
      toast.error("상품상태를 선택하세요.");
      goodsStateRef.current.focus();
      return;
    }
    console.log("상품상태", goodsState);

    let newYN = "";
    let bestYN = "";
    let recommandYN = "";
    let soldout = "";

    switch (goodsState) {
      case "normal":
        {
          newYN = "N";
          bestYN = "N";
          recommandYN = "N";
          soldout = "N";
        }
        break;
      case "new":
        {
          newYN = "Y";
          bestYN = "N";
          recommandYN = "N";
          soldout = "N";
        }
        break;
      case "best":
        {
          newYN = "N";
          bestYN = "Y";
          recommandYN = "N";
          soldout = "N";
        }
        break;
      case "recommand":
        {
          newYN = "N";
          bestYN = "N";
          recommandYN = "Y";
          soldout = "N";
        }
        break;
      case "soldout":
        {
          newYN = "N";
          bestYN = "N";
          recommandYN = "N";
          soldout = "Y";
        }
        break;
      default: {
        newYN = "N";
        bestYN = "N";
        recommandYN = "N";
        soldout = "N";
      }
    }

    // 크기 x
    const xSize = xSizeRef.current.value;
    if (xSize === "") {
      toast.error("크기 x 를 입력하세요.");
      xSizeRef.current.focus();
      return;
    }
    // 크기 y
    const ySize = ySizeRef.current.value;
    if (ySize === "") {
      toast.error("크기 y 를 입력하세요.");
      ySizeRef.current.focus();
      return;
    }
    // 위치 x
    const x = xRef.current.value;
    if (x === "") {
      toast.error("위치 x 를 입력하세요.");
      xRef.current.focus();
      return;
    }
    // 위치 y
    const y = yRef.current.value;
    if (y === "") {
      toast.error("위치 y 를 입력하세요.");
      yRef.current.focus();
      return;
    }
    // 재고
    const stock = stockRef.current.value;
    if (stock === "") {
      toast.error("재고를 입력하세요.");
      stockRef.current.focus();
      return;
    }
    // 순서
    const order = orderRef.current.value;
    if (order === "") {
      toast.error("순서를 입력하세요.");
      orderRef.current.focus();
      return;
    }

    let url = "";
    let method = "POST";

    // 저장이면
    if (editType === "add") {
      method = "POST";
      url = `
	  ${serverUrl}/mygoods/addMyGoods?agency=${agencyRef.current.value}&fran=${franRef.current.value}&brand=${brandRef.current.value}&store=${storeRef.current.value}&pos=${posRef.current.value}&goodsId=${goodsId}
	 	&xSize=${xSize}&ySize=${ySize}&useYN=${saleYN}&newYN=${newYN}&bestYN=${bestYN}&recommandYN=${recommandYN}&soldout=${soldout}&dispOrder=${order}&stockQty=${stock}
		&positionX=${x}&positionY=${y} 
	  `;
    } else {
      method = "PUT";
      url = `
	  ${serverUrl}/mygoods/updateMyGoods?agency=${agencyRef.current.value}&fran=${franRef.current.value}&brand=${brandRef.current.value}&store=${storeRef.current.value}&pos=${posRef.current.value}&goodsId=${goodsId}
	 	&xSize=${xSize}&ySize=${ySize}&useYN=${saleYN}&newYN=${newYN}&bestYN=${bestYN}&recommandYN=${recommandYN}&soldoutYN=${soldout}&order=${order}&stock=${stock}
		&x=${x}&y=${y}&oriX=${oriXRef.current.value}&oriY=${oriYRef.current.value} 
	  `;
    }

    console.log("url", url);
    fetchData(method, url)
      .then((data) => {
        // 성공적으로 데이터를 가져왔을 때의 처리
        console.log("Data fetched: ", data);

        if (data.header) {
          //console.log("data.header", data.header);
          if (data.header.res_code === "ok") {
            //setGoodsList(data.data);
            //console.log("상품 리스트  => ", data.data);
            search();
            if (editType === "add") {
              toast.success(data.header.res_msg || "삽입에 성공하였습니다.");
            } else {
              toast.success(data.header.res_msg || "수정에 성공하였습니다.");
            }
          } else {
            // 에러 메시지 표현
            //setGoodsList([]);
            search();
            toast.error(data.header.res_msg || "삽입에 실패 하였습니다.");
            console.log(data.header.res_code, data.header.res_msg);
          }
        } else {
          //setGoodsList([]);
          search();
          if (editType === "add") {
            toast.error("삽입에 실패 하였습니다.");
          } else {
            toast.error("수정에 실패 하였습니다.");
          }

          console.error("header 값이 없음");
        }
      })
      .catch((error) => {
        // fetchData 함수 내에서 발생한 에러를 처리
        search();
        console.error("Error in fetchData: ", error);
        toast.error(error);
      });

    $(".btn.btn-success[data-dismiss='modal']").trigger("click");
    return;
  };

  const handleBtnDel = (e) => {
    console.group("삭제");
    console.log("삭제 버튼 클릭");
    const data = JSON.parse(e.currentTarget.dataset.datas);
    console.log(data);

    let url = "";
    let method = "DELETE";

    // 저장이면
    url = `
	  ${serverUrl}/mygoods/deleteMyGoods?agency=${data.AGENCY_CODE}&fran=${data.FRANCHISE_CODE}&brand=${data.BRAND_CODE}&store=${data.STORE_CD}&pos=${data.POS_NO}&goodsId=${data.goodsId}&positionX=${data.positionX}&positionY=${data.positionY}
	  `;

    console.log("url", url);
    fetchData(method, url)
      .then((data) => {
        // 성공적으로 데이터를 가져왔을 때의 처리
        console.log("Data fetched: ", data);

        if (data.header) {
          //console.log("data.header", data.header);
          if (data.header.res_code === "ok") {
            //setGoodsList(data.data);
            //console.log("상품 리스트  => ", data.data);
            search();
            toast.success(data.header.res_msg || "삭제에 성공하였습니다.");
          } else {
            // 에러 메시지 표현
            //setGoodsList([]);
            search();
            toast.error(data.header.res_msg || "삭제에 실패 하였습니다.");
            console.log(data.header.res_code, data.header.res_msg);
          }
        } else {
          //setGoodsList([]);
          search();
          toast.error("삭제에 실패 하였습니다.");
          console.error("header 값이 없음");
        }
      })
      .catch((error) => {
        // fetchData 함수 내에서 발생한 에러를 처리
        search();
        console.error("Error in fetchData: ", error);
        toast.error(error);
      });

    console.groupEnd();
  };

  const handleBtnEdit = (e) => {
    editType = "edit";
    btnSave.current.innerText = "수정";
    console.group("수정 버튼 클릭");
    console.log("수정 버튼 클릭");

    $("#search_things").hide();
    $("#sales_list").hide();

    const data = JSON.parse(e.currentTarget.dataset.datas);
    console.log("data", data);
    agencyRef.current.value = data.AGENCY_CODE;
    franRef.current.value = data.FRANCHISE_CODE;
    brandRef.current.value = data.BRAND_CODE;
    storeRef.current.value = data.STORE_CD;
    posRef.current.value = data.POS_NO;
    oriXRef.current.value = data.positionX;
    oriYRef.current.value = data.positionY;

    // 상품명
    goodsNmRef.current.value = data.GOODS_NM;
    // 상품 코드
    goodsIdRef.current.value = data.goodsId;
    // 판매 여부
    saleYNRef.current.value = data.useYN;
    // 상품 상태
    let goodsState = "";
    if (data.newYN === "Y") {
      goodsState = "new";
    } else if (data.bestYN === "Y") {
      goodsState = "best";
    } else if (data.recommandYN == "Y") {
      goodsState = "recommand";
    } else if (data.soldout === "Y") {
      goodsState = "soldout";
    } else {
      goodsState = "normal";
    }

    goodsStateRef.current.value = goodsState;

    // 크기 x
    xSizeRef.current.value = data.xSize;
    // 크기 y
    ySizeRef.current.value = data.ySize;

    // 위치 x
    xRef.current.value = data.positionX;
    // 위치 y
    yRef.current.value = data.positionY;

    // 재고
    stockRef.current.value = getCommpaPrice(data.stockQty);
    // 순서
    orderRef.current.value = data.dispOrder;

    console.groupEnd();
  };

  return (
    <div>
      <div id="wrapper" className="preload">
        <CommonHeader />
        <ASide index={3} gubun={"goods"} />
        <div id="main-container">
          <div className="main-header clearfix row">
            <MyGoodsHeader
              title="내 상품 관리"
              onHandleCondtion={handleChangeCondtion}
              onHandleBtnReg={handleBtnRegGoods}
            />
          </div>
          {/* <!-- /main-header --> */}
          <div className="padding-md">
            <div className="panel panel-default table-responsive">
              <div className="panel-body">
                <div className="row">
                  <div className="col-lg-4 col-sm-4 col-xs-4">
                    <span className="marR5">전체</span>
                    <span className="badge badge-info">
                      {getCommpaPrice(myGoodsCnt)}
                    </span>
                  </div>
                  {/* <!-- /.col --> */}
                  <div className="col-lg-6 col-sm-6 col-xs-6 fl-right">
                    <div style={{ float: "left" }} className="marR2">
                      <select
                        className="form-control input-sm"
                        style={{ display: "inline-block", width: "150px" }}
                      >
                        <option value="">상품명</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control input-sm"
                          placeholder="검색어를 입력하세요."
                          ref={keyWordRef}
                        />
                        <span className="input-group-btn">
                          <button
                            className="btn btn-default btn-sm"
                            type="button"
                            onClick={handleMyGoodSearch}
                          >
                            <i className="fa fa-search"></i>
                          </button>
                        </span>
                      </div>
                      {/* <!-- /input-group --> */}
                    </div>
                  </div>
                </div>
                {/* <!-- /.row --> */}
              </div>
              <table className="table table-striped" id="responsiveTable">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>순서</th>
                    <th>상품명</th>
                    <th>상품코드</th>
                    <th>크기</th>
                    <th>사용유무</th>
                    <th>상태</th>
                    <th>재고</th>
                    <th>위치</th>
                    <th>가격</th>
                    <th>편집</th>
                  </tr>
                </thead>
                <tbody>
                  {myGoodsList.map((value, index) => (
                    <tr
                      key={index}
                      data-datas={JSON.stringify(value)}
                      onClick={handleTrClick}
                    >
                      <td>{value.RowNum}</td>
                      <td className="text-center">{value.dispOrder}</td>
                      <td className="text-left">{value.GOODS_NM}</td>
                      <td className="text-center">{value.goodsId}</td>
                      <td className="text-left">
                        {value.xSize && value.ySize
                          ? `(${value.xSize}, ${value.ySize})`
                          : ""}
                      </td>
                      <td className="text-center">{value.useYN}</td>
                      <td className="text-center">{value.state}</td>
                      <td className="text-center">
                        {getCommpaPrice(value.stockQty)}
                      </td>
                      <td className="text-center">
                        {`( ${value.positionX} , ${value.positionY} )`}
                      </td>
                      <td className="text-center">
                        {getCommpaPrice(value.SALEPRICE)}
                      </td>
                      <td>
                        <a
                          type="button"
                          className="btn btn-sm btn-default fcblue"
                          onClick={handleBtnEdit}
                          href="#formModal"
                          data-toggle="modal"
                          data-backdrop="static"
                          data-keyboard="false"
                          data-datas={JSON.stringify(value)}
                        >
                          상세
                        </a>
                        <button
                          type="button"
                          className="btn btn-sm btn-default fcred lightCustomModal_open"
                          href="#lightCustomModal"
                          onClick={handleBtnDel}
                          data-datas={JSON.stringify(value)}
                        >
                          삭제
                        </button>
                      </td>
                      {/* SALEPRICE */}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="panel-footer clearfix">
                <div className="search-pager fl-right">
                  <ul className="pagination pagination-sm pagination-split no-margin">
                    <li className="disabled">
                      <a>«</a>
                    </li>
                    {pageNumbers.map((pageNumber) => (
                      <li
                        key={pageNumber}
                        className={pageNumber == page.curPage && "active"}
                        onClick={() => handlePageChange(pageNumber)}
                      >
                        <a>
                          {pageNumber}
                          {pageNumber == page.curPage && (
                            <span className="sr-only">(current)</span>
                          )}
                        </a>
                      </li>
                    ))}
                    {/* <li className="active">
                      <a>
                        1 <span className="sr-only">(current)</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">2</a>
                    </li>
                    <li>
                      <a href="#">3</a>
                    </li>
                    <li>
                      <a href="#">4</a>
                    </li>
                    <li>
                      <a href="#">5</a>
                    </li> */}
                    <li>
                      <a>»</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <!-- /panel --> */}
          </div>
        </div>
        {/* <!-- /main-container --> */}
        <div className="modal fade" id="formModal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close fl-right"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  &times;
                </button>
                <span className="fcred fl-right marR20">* 필수입력</span>
                <h4>내 상품 등록 </h4>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row" id="search_things">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <label>
                        <span className="fcred">*</span> 판매상품 선택
                      </label>
                      <div className="form-inline no-margin">
                        <select
                          className="form-control input-sm"
                          style={{ display: "inline-block", width: "150px" }}
                        >
                          <option value="">상품명</option>
                        </select>

                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control "
                            placeholder=""
                            style={{ width: "244px" }}
                            ref={goodsOriRef}
                          />
                          <div className="input-group-btn">
                            <button
                              type="button"
                              className="btn  btn-primary"
                              tabindex="-1"
                              onClick={btnGoodsSearch}
                            >
                              검색
                            </button>
                          </div>
                          {/* <!-- /input-group-btn --> */}
                        </div>
                      </div>
                    </div>
                    {/* <!-- /.col --> */}
                  </div>
                  {/* <!-- /.row -->    */}

                  <div className="form-group marT20 madalTable" id="sales_list">
                    <div className="panel panel-default table-responsive">
                      <table
                        className="table table-striped fontSize13"
                        id="responsiveTable"
                      >
                        <thead>
                          <tr>
                            <th>선택</th>
                            <th>판매상태</th>
                            <th>상품코드</th>
                            <th>상품명</th>
                            <th>판매가격</th>
                            <th>성인유무</th>
                            <th>대분류</th>
                            <th>중분류</th>
                            <th>소분류</th>
                            <th>옵션여부</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* goodsList */}
                          {goodsList.map((value, index) => (
                            <tr
                              key={index}
                              index={index}
                              data-datas={JSON.stringify(value)}
                              onClick={handleGoodsTrClick}
                              style={{
                                backgroundColor:
                                  index === indexGoodsTR ? "skyblue" : "",
                              }}
                            >
                              <td>
                                <span className="fcgreen">{index + 1}</span>
                              </td>
                              <td>
                                <span className="fcgreen">{value.SALE_YN}</span>
                              </td>
                              <td className="text-center">{value.PLU_CD}</td>
                              <td className="text-center">{value.GOODS_NM}</td>
                              <td>
                                <span className="fcred">
                                  {getCommpaPrice(value.SALEPRICE)}
                                </span>
                                원
                              </td>
                              <td>
                                <span className="fcgreen">
                                  {value.JUNIOR_YN === "Y" ? "아니요" : "예"}
                                </span>
                              </td>
                              <td>{value.ITEM_HEAD_NM}</td>
                              <td>{value.ITEM_MEDIUM_NM}</td>
                              <td>{value.ITEM_DETAIL_NM}</td>
                              <td>
                                {value.ITEM_DETAIL_NM === "Y"
                                  ? "포함"
                                  : "미포함"}{" "}
                              </td>
                              {/* PLU_CD */}
                            </tr>
                          ))}
                          {/* <tr>
							<td>
                              <input
                                type="checkbox"
                                className="regular-checkbox"
                                checked=""
                              />
                              <span className="custom-checkbox"></span>
                            </td>
                            <td>
                              <span className="fcgreen">판매</span>
                            </td>
                            <td>100025</td>
                            <td className="text-left">트리플딥치즈싸이버거</td>
                            <td>
                              <span className="fcblue">6,000</span>원
                            </td>
                            <td>
                              <span className="fcred">5,400</span>원
                            </td>
                            <td>메인</td>
                            <td>햄버거</td>
                            <td>닭고기살</td>
                            <td>가능</td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                    {/* <!-- /panel --> */}
                  </div>

                  <div className="row">
                    <input type="hidden" ref={agencyRef} />
                    <input type="hidden" ref={franRef} />
                    <input type="hidden" ref={brandRef} />
                    <input type="hidden" ref={storeRef} />
                    <input type="hidden" ref={posRef} />
                    <input type="hidden" ref={oriXRef} />
                    <input type="hidden" ref={oriYRef} />
                    <div className="col-md-8 col-sm-12 col-xs-12">
                      <div className="form-group">
                        <label>
                          <span className="fcred">*</span> 선택상품
                        </label>
                        <div className="form-inline no-margin">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="상품명"
                            disabled
                            style={{ minWidth: "440px" }}
                            ref={goodsNmRef}
                          />
                          <span>{"  "}</span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="상품코드"
                            disabled
                            style={{ width: "calc(100 % -250px)" }}
                            ref={goodsIdRef}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <!-- /.col --> */}

                    <div className="col-md-2 col-sm-12 col-xs-12">
                      <div className="form-group">
                        <label>
                          <span className="fcred">*</span> 판매여부
                        </label>
                        <div className="form-inline no-margin">
                          <select
                            className="form-control"
                            style={{ display: "inline-block", width: "120px" }}
                            ref={saleYNRef}
                          >
                            <option value="Y">판매</option>
                            <option value="N">중지</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /.col --> */}

                    <div className="col-md-2 col-sm-12 col-xs-12">
                      <div className="form-group">
                        <label>
                          <span className="fcred">*</span> 상품상태
                        </label>
                        <div className="form-inline no-margin">
                          <select
                            className="form-control"
                            style={{ display: "inline-block", width: "120px" }}
                            ref={goodsStateRef}
                          >
                            <option value="normal">일반</option>
                            <option value="new">신규</option>
                            <option value="best">인기</option>
                            <option value="recommand">추천</option>
                            <option value="soldout">품절</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /.col --> */}
                  </div>
                  {/* <!-- /.row --> */}

                  <div className="row">
                    <div className="col-md-2 col-sm-12 col-xs-12">
                      <div className="form-group">
                        <label>
                          <span className="fcred">*</span> 크기(X * Y)
                        </label>
                        <p />
                        <input
                          type="number"
                          className="form-control"
                          placeholder="x"
                          style={{ display: "inline-block", width: "60px" }}
                          ref={xSizeRef}
                        />
                        <span> {"      "}</span>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="y"
                          style={{ display: "inline-block", width: "60px" }}
                          ref={ySizeRef}
                        />
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-12 col-xs-12">
                      <div className="form-group">
                        <label>
                          <span className="fcred">*</span> 위치(슬롯 = (X,Y))
                        </label>
                        <p />
                        <input
                          type="number"
                          className="form-control"
                          placeholder="x"
                          style={{ display: "inline-block", width: "60px" }}
                          ref={xRef}
                        />
                        <span> {"      "}</span>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="y"
                          style={{ display: "inline-block", width: "60px" }}
                          ref={yRef}
                        />
                      </div>
                    </div>
                    {/* <!-- /.col --> */}

                    <div className="col-md-4 col-sm-12 col-xs-12">
                      <div className="form-group">
                        <label>
                          <span className="fcred">*</span>재고
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="재고"
                          ref={stockRef}
                        />
                      </div>
                    </div>
                    {/* <!-- /.col --> */}

                    <div className="col-md-4 col-sm-12 col-xs-12">
                      <div className="form-group">
                        <label>
                          <span className="fcred">*</span> 순서
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="순서"
                          ref={orderRef}
                        />
                      </div>
                    </div>
                    {/* <!-- /.col --> */}
                  </div>
                  {/* <!-- /.row --> */}
                </form>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-success "
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  취소
                </button>
                <a
                  className="btn btn-navy"
                  onClick={handleBtnSave}
                  ref={btnSave}
                >
                  저장
                </a>
              </div>
            </div>
            {/* <!-- /.modal-content --> */}
          </div>
          {/* <!-- /.modal-dialog --> */}
        </div>
        {/* <!-- /.modal --> */}

        {/* <!-- 엑셀파일 등록 modal--> */}
        <div className="modal fade" id="excelModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close fl-right"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  &times;
                </button>
                <h4>엑셀파일 등록</h4>
              </div>
              <div className="modal-body">
                <div className="marB30">
                  <div className="alert">
                    <i className="fa fa-warning"></i>
                    <span className="m-left-xs">
                      지정된 형식의 엑셀파일만 등록이 가능합니다
                    </span>
                    <div className="m-left-xs">
                      STEP 1. 아래 [서식 파일 받기] 버튼을 클릭하여 엑셀 파일을
                      다운로드
                      <br />
                      STEP 1. 다운로드한 파일에 관련 정보를 입력한 후 저장
                      <br />
                      STEP 1. 아래 [파일 선택] 버튼을 클릭하여 저장된 엑셀
                      파일을 선택한 후, [등록] 버튼 클릭
                      <br />
                    </div>
                  </div>
                </div>
                <form action="." className="">
                  <div className="form-inline no-margin">
                    <input
                      name="file"
                      type="file"
                      multiple
                      className="form-control "
                      style={{ border: "0px solid #ffffff" }}
                    />
                    <div className="navbar-right">
                      <a
                        href=""
                        role="button"
                        className="btn btn-sm btn-default"
                      >
                        <i className="fa fa-download"></i> 서식 파일 받기
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- /.modal-content --> */}
            <div className="modal-footer">
              <button
                className="btn btn-success "
                data-dismiss="modal"
                aria-hidden="true"
              >
                취소
              </button>
              <a href="#" className="btn btn-navy">
                일괄 등록
              </a>
            </div>
          </div>
          {/* <!-- /.modal-dialog --> */}
        </div>
        {/* <!-- /.modal --> */}
      </div>
      {/* <!-- /wrapper --> */}
      <ToastContainer autoClose={600} />
    </div>
  );
};

export default MyGoods;
