import React, { useState, useEffect, useRef } from "react";

const CategoryAddButton = (props) => {
  const { lvl } = props;
  const [level, setLevel] = useState(props.lvl || "1");

  console.log("받아온값", lvl);

  useEffect(() => {
    if (lvl !== undefined) {
      setLevel(lvl);
    }
  }, [lvl]);

  useEffect(() => {
    console.log("lvl", lvl);
  }, []);

  return (
    <div className="navbar-right ">
      <span>
        <a
          href="#formModal"
          role="button"
          data-toggle="modal"
          className="btn btn-sm btn-danger"
          data-backdrop="static"
          data-keyboard="false"
        >
          <i className="fa fa-plus-circle"></i> 분류추가
        </a>
      </span>
      <input type="hidden" name="hidden-lvl" value={level} />
    </div>
  );
};

export default CategoryAddButton;
