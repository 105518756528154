import React, { useEffect, forwardRef, useState } from "react";
import { fetchData, getAPIsUrl, getLocalAPIsUrl } from "../../utils/api";
import CategoryAddButton from "../Button/CategoryAddButton";

const serverUrl = getAPIsUrl();

const SmallCategroy = ({ small, handleGetLvl }) => {
  const [smallCategory, setSmallCategory] = useState([]);

  const [selectedRow, setSelectedRow] = useState(null);

  const getCategoryList = () => {
    // if (small.agency) {
    const url = `${serverUrl}/category/getCategoryList?agency=${small.agency}&fran=${small.fran}&brand=${small.brand}&store=${small.store}&pos=${small.pos}&upper=${small.code}&lvl=3`;
    console.log("url", url);

    fetchData("GET", url)
      .then((data) => {
        // 성공적으로 데이터를 가져왔을 때의 처리
        //console.log("Data fetched: ", data);

        if (data.header) {
          //console.log("data.header", data.header);

          if (data.header.res_code === "ok") {
            console.log(data.data);
            setSmallCategory(data.data);
            setSelectedRow({
              agency: data.data[0].AGENCY_CODE,
              fran: data.data[0].FRANCHISE_CODE,
              brand: data.data[0].BRAND_CODE,
              store: data.data[0].STORE_CD,
              pos: data.data[0].POS_NO,
              code: data.data[0].CATEGORYCODE,
              upper: data.data[0].UPPERCATEGORYCODE,
              lvl: data.data[0].CATEGORYLVL,
            });
          } else {
            // 에러 메시지 표현
            setSmallCategory([]);
            console.log(data.header.res_code, data.header.res_msg);
          }
        } else {
          setSmallCategory([]);
          console.error("header 값이 없음");
        }
      })
      .catch((error) => {
        // fetchData 함수 내에서 발생한 에러를 처리
        console.error("Error in fetchData: ", error);
      });
    // }
  };

  useEffect(() => {
    console.log(
      "small 선택 대리점 ",
      small.agency,
      "small 선택 프랜",
      small.fran,
      "small 선택 브랜드",
      small.brand,
      "small 선택 점포",
      small.store,
      "small 선택 포스",
      small.pos,
      "small 선택 코드",
      small.code,
      "small.upper",
      small.upper,
      "small.lvl",
      small.lvl
    );

    // 초기화 시킴
    // if (ref) {
    //   ref.current = {
    //     getCategoryList,
    //   };
    // }

    getCategoryList();
  }, [small]);

  const handleButtonClick = () => {
    console.log("★★★★★ Button-like component clicked! ★★★★★★", small);
    handleGetLvl("3", small.code, "추가");
  };

  const handleBtnDelClick = (e) => {
    console.group("삭제버튼");
    console.log("삭제 버튼 클릭", e.target.value);

    const agency = e.currentTarget.dataset.agency;
    const fran = e.currentTarget.dataset.fran;
    const brand = e.currentTarget.dataset.brand;
    const store = e.currentTarget.dataset.store;
    const pos = e.currentTarget.dataset.pos;
    const code = e.currentTarget.dataset.code;
    const upper = e.currentTarget.dataset.upper;
    const lvl = e.currentTarget.dataset.lvl;

    const name = e.currentTarget.dataset.name;

    console.log(agency, fran, brand, store, pos, code, upper, lvl, name);

    const Url = `${serverUrl}/category/deleteCategory?agency=${agency}&fran=${fran}&brand=${brand}&store=${store}&pos=${pos}&lvl=${lvl}&code=${code}&upper=${upper}`;

    fetchData("DELETE", Url)
      .then((data) => {
        // 성공적으로 데이터를 가져왔을 때의 처리
        //console.log("Data fetched: ", data);

        if (data.header) {
          console.log("data.header", data.header);
          if (data.header.res_code === "ok") {
            //
            getCategoryList();
            console.log("delete  받은 데이터 ", data);
          } else {
            // 에러 메시지 표현
            getCategoryList();
            console.log(data.header.res_code, data.header.res_msg);
          }
        } else {
          getCategoryList();
          console.error("header 값이 없음");
        }
      })
      .catch((error) => {
        // fetchData 함수 내에서 발생한 에러를 처리
        getCategoryList();
        console.error("Error in fetchData: ", error);
      });

    console.groupEnd("삭제버튼");
  };

  const handleTrClick = (e) => {
    const agency = e.currentTarget.dataset.agency;
    const fran = e.currentTarget.dataset.fran;
    const brand = e.currentTarget.dataset.brand;
    const store = e.currentTarget.dataset.store;
    const pos = e.currentTarget.dataset.pos;
    const code = e.currentTarget.dataset.code;
    const upper = e.currentTarget.dataset.upper;
    const lvl = e.currentTarget.dataset.lvl;

    const name = e.currentTarget.dataset.name;

    setSelectedRow({
      agency: agency,
      fran: fran,
      brand: brand,
      store: store,
      pos: pos,
      code: code,
      upper: upper,
      lvl: lvl,
    });
  };

  const handleBtnEditClick = (e) => {
    console.log("상세 클릭", e.target.text);

    const agency = e.currentTarget.dataset.agency;
    const fran = e.currentTarget.dataset.fran;
    const brand = e.currentTarget.dataset.brand;
    const store = e.currentTarget.dataset.store;
    const pos = e.currentTarget.dataset.pos;
    const name = e.currentTarget.dataset.name;
    const lvl = e.currentTarget.dataset.lvl;
    const disorder = e.currentTarget.dataset.disorder;
    const x = e.currentTarget.dataset.x;
    const y = e.currentTarget.dataset.y;
    const useYN = e.currentTarget.dataset.yn;
    const code = e.currentTarget.dataset.code;
    const upper = e.currentTarget.dataset.upper;

    const newForm = {
      agency: agency,
      fran: fran,
      brand: brand,
      store: store,
      pos: pos,
      name: name,
      lvl: lvl,
      disorder: disorder,
      x: x,
      y: y,
      useYN: useYN,
      code: code,
      type: "수정",
      upper: upper,
    };

    console.log("새로운 폼", newForm);

    handleGetLvl("3", small.code, "수정", newForm);
  };

  return (
    <div className="col-md-4 ">
      <div className="clearfix pad15 row">
        <div className="page-title col-sm-6 col-xs-6 col-lg-6">
          <h4 className="no-margin fw500 fcblack">3. 분류 설정 - 소</h4>
        </div>

        <div className="navbar-right ">
          <span onClick={handleButtonClick}>
            <a
              href="#formModal"
              role="button"
              data-toggle="modal"
              className="btn btn-sm btn-danger"
              data-backdrop="static"
              data-keyboard="false"
            >
              <i className="fa fa-plus-circle"></i> 분류추가
            </a>
          </span>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-striped" id="responsiveTable">
          <thead>
            <tr>
              <th>사용</th>
              <th>코드</th>
              <th>분류명</th>
              <th>편집</th>
            </tr>
          </thead>
          <tbody>
            {smallCategory.map((value, index) => (
              <tr
                key={index}
                onClick={handleTrClick}
                data-agency={value.AGENCY_CODE}
                data-fran={value.FRANCHISE_CODE}
                data-brand={value.BRAND_CODE}
                data-store={value.STORE_CD}
                data-pos={value.POS_NO}
                data-lvl={value.CATEGORYLVL}
                data-code={value.CATEGORYCODE}
                data-name={value.CATEGORYNAME}
                data-yn={value.USEYN}
                data-x={value.XARRAY}
                data-y={value.YARRAY}
                data-upper={value.UPPERCATEGORYCODE}
                style={{
                  backgroundColor:
                    selectedRow?.agency === value.AGENCY_CODE &&
                    selectedRow?.fran === value.FRANCHISE_CODE &&
                    selectedRow?.brand === value.BRAND_CODE &&
                    selectedRow?.store === value.STORE_CD &&
                    selectedRow?.pos === value.POS_NO &&
                    selectedRow?.code === value.CATEGORYCODE
                      ? "skyblue"
                      : "",
                }}
              >
                <td>
                  {/* <input
                    type="checkbox"
                    name="catechk"
                    checked={value.USEYN === "Y" ? true : false}
                  />
                  <span className="custom-checkbox"></span> */}
                  {value.USEYN}
                </td>
                <td>{value.CATEGORYCODE}</td>
                <td className="text-left">{value.CATEGORYNAME}</td>
                <td>
                  <span className="fcgreen">{value.CATEGORYLVL}</span>
                </td>
                <td>
                  <a
                    type="button"
                    className="btn btn-sm btn-default fcblue"
                    role="button"
                    data-toggle="modal"
                    href="#formModal"
                    data-agency={value.AGENCY_CODE}
                    data-fran={value.FRANCHISE_CODE}
                    data-brand={value.BRAND_CODE}
                    data-store={value.STORE_CD}
                    data-pos={value.POS_NO}
                    data-lvl={value.CATEGORYLVL}
                    data-code={value.CATEGORYCODE}
                    data-name={value.CATEGORYNAME}
                    data-yn={value.USEYN}
                    data-x={value.XARRAY}
                    data-y={value.YARRAY}
                    data-upper={value.UPPERCATEGORYCODE}
                    data-disorder={value.DISPORDER}
                    onClick={handleBtnEditClick}
                  >
                    상세
                  </a>
                  <button
                    type="button"
                    className="btn btn-sm btn-default fcred lightCustomModal_open"
                    href="#lightCustomModal"
                    onClick={handleBtnDelClick}
                    data-agency={value.AGENCY_CODE}
                    data-fran={value.FRANCHISE_CODE}
                    data-brand={value.BRAND_CODE}
                    data-store={value.STORE_CD}
                    data-pos={value.POS_NO}
                    data-lvl={value.CATEGORYLVL}
                    data-code={value.CATEGORYCODE}
                    data-name={value.CATEGORYNAME}
                    data-yn={value.USEYN}
                    data-x={value.XARRAY}
                    data-y={value.YARRAY}
                    data-upper={value.UPPERCATEGORYCODE}
                    data-disorder={value.DISPORDER}
                  >
                    삭제
                  </button>
                </td>
              </tr>
            ))}
            {/* <tr>
              <td>
                <input type="checkbox" name="catechk" id="" />
                <span className="custom-checkbox"></span>
              </td>
              <td>100025</td>
              <td className="text-left">메인디시</td>
              <td>
                <button type="button" className="btn btn-sm btn-default fcblue">
                  상세
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-default fcred lightCustomModal_open"
                  href="#lightCustomModal"
                >
                  삭제
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <input type="checkbox" name="catechk" id="" />
                <span className="custom-checkbox"></span>
              </td>
              <td>100025</td>
              <td className="text-left">사이트</td>
              <td>
                <button type="button" className="btn btn-sm btn-default fcblue">
                  상세
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-default fcred lightCustomModal_open"
                  href="#lightCustomModal"
                >
                  삭제
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <input type="checkbox" name="catechk" id="" />
                <span className="custom-checkbox"></span>
              </td>
              <td>100025</td>
              <td className="text-left">디저트</td>
              <td>
                <button type="button" className="btn btn-sm btn-default fcblue">
                  상세
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-default fcred lightCustomModal_open"
                  href="#lightCustomModal"
                >
                  삭제
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <input type="checkbox" name="catechk" id="" />
                <span className="custom-checkbox"></span>
              </td>
              <td>100025</td>
              <td className="text-left">이벤트</td>
              <td>
                <button type="button" className="btn btn-sm btn-default fcblue">
                  상세
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-default fcred lightCustomModal_open"
                  href="#lightCustomModal"
                >
                  삭제
                </button>
              </td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SmallCategroy;
