import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const CommonHeader = () => {
  const navigate = useNavigate();
  // login_info
  var login_info = window.localStorage.getItem("login_info") || {};
  console.log("login_info", login_info, typeof login_info);
  if (typeof login_info === "string") login_info = JSON.parse(login_info);

  const [name, setName] = useState("");

  useEffect(() => {
    setName(login_info.NAME);
  }, []);

  const handleLogout = (e) => {
    console.log("log out");
    window.localStorage.removeItem("login_info");
    window.localStorage.removeItem("keep_login");
    navigate("/login", { replace: true });
  };

  return (
    <div id="top-nav" className="skin-6 fixed">
      <div className="brand">
        <span>
          <img src={"./img/logo.png"} className="max-w-90" />
        </span>
      </div>
      <button
        type="button"
        className="navbar-toggle pull-left"
        id="sidebarToggle"
      >
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
      </button>
      <button
        type="button"
        className="navbar-toggle pull-left hide-menu"
        id="menuToggle"
      >
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
      </button>
      <ul className="nav-notification clearfix">
        <li className="profile dropdown">
          <a className="dropdown-toggle" data-toggle="dropdown">
            <strong>
              <span className="fcblue">{name}</span> 님 반갑습니다.
            </strong>
            <span>
              <i className="fa fa-chevron-down"></i>
            </span>
          </a>
          <ul className="dropdown-menu">
            <li>
              <a className="clearfix" href="#">
                <div className="detail">
                  <strong>[ 대리점 ]</strong>
                  <p className="grey fcblue">
                    하나로정보통신 (hanaro@hanasis.com)
                  </p>
                </div>
              </a>
            </li>
            <li className="divider"></li>
            <li>
              <a tabIndex="-1" href="#" className="main-link">
                <i className="fa fa-edit fa-lg"></i> 사용자 정보 변경
              </a>
            </li>
            <li>
              <a tabIndex="-1" href="#" className="main-link">
                <i className="fa fa-refresh fa-lg"></i> 관리 및 운영 권한 변경
              </a>
            </li>
          </ul>
        </li>
        <li className="topLogout">
          <a className="logoutConfirm_open" onClick={handleLogout}>
            <img src="./img/logout.png" />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default CommonHeader;
