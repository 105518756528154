import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getLoginCheck } from "../../utils/util";

const Main = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!getLoginCheck()) {
      navigate("/login", { replace: true });
      return;
    }
  }, []);

  return <div>메인 페이지 입니다.</div>;
};

export default Main;
