import React from "react";
import ASide from "../../component/ASide/ASide";
import CommonHeader from "../../component/Header/CommonHeader";
import FranHeader from "../../component/Header/FranHeader";

const Fran = () => {
  return (
    <div id="wrapper" className="preload">
      <CommonHeader />
      {/* <!-- /top-nav--> */}

      <ASide index={22} gubun={"users"} />

      <div id="main-container">
        {/* <!-- /main-header --> */}
        <FranHeader></FranHeader>

        <div className="padding-md">
          <div className="panel panel-default table-responsive">
            <div className="panel-body">
              <div className="row">
                <div className="col-lg-4 col-sm-4 col-xs-4">
                  <span className="marR5">전체</span>
                  <span className="badge badge-info">215,555</span>
                </div>
                {/* <!-- /.col --> */}
                <div className="col-lg-6 col-sm-6 col-xs-6 fl-right">
                  <div style={{ float: "left" }} className="marR2">
                    <select
                      className="form-control input-sm"
                      style={{ display: "inline-block", width: "150px" }}
                    >
                      <option value="">프랜차이즈</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control input-sm"
                        placeholder="검색어를 입력하세요."
                      />
                      <span className="input-group-btn">
                        <button
                          className="btn btn-default btn-sm"
                          type="button"
                        >
                          <i className="fa fa-search"></i>
                        </button>
                      </span>
                    </div>
                    {/* <!-- /input-group --> */}
                  </div>
                </div>
              </div>
              {/* <!-- /.row --> */}
            </div>
            <table className="table table-striped" id="responsiveTable">
              <thead>
                <tr>
                  <th>번호</th>
                  <th>프랜차이즈 코드</th>
                  <th>프랜차이즈명</th>
                  <th>사업자등록번호</th>
                  <th>대표자</th>
                  <th>연락처</th>
                  <th>보유 브랜드</th>
                  <th>편집</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>001</td>
                  <td>100025</td>
                  <td className="text-left">주식회사 더본코리아</td>
                  <td>211-87-11211</td>
                  <td>홍길동</td>
                  <td>061-793-9238</td>
                  <td>96</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-sm btn-default fcblue"
                    >
                      상세
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-default fcred lightCustomModal_open"
                      href="#lightCustomModal"
                    >
                      삭제
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>002</td>
                  <td>100025</td>
                  <td className="text-left">주식회사 더본코리아</td>
                  <td>211-87-11211</td>
                  <td>홍길동</td>
                  <td>061-793-9238</td>
                  <td>58</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-sm btn-default fcblue"
                    >
                      상세
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-default fcred"
                    >
                      삭제
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>003</td>
                  <td>100025</td>
                  <td className="text-left">주식회사 더본코리아</td>
                  <td>211-87-11211</td>
                  <td>홍길동</td>
                  <td>061-793-9238</td>
                  <td>30</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-sm btn-default fcblue"
                    >
                      상세
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-default fcred"
                    >
                      삭제
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>004</td>
                  <td>100025</td>
                  <td className="text-left">주식회사 더본코리아</td>
                  <td>211-87-11211</td>
                  <td>홍길동</td>
                  <td>061-793-9238</td>
                  <td>8</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-sm btn-default fcblue"
                    >
                      상세
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-default fcred"
                    >
                      삭제
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>005</td>
                  <td>100025</td>
                  <td className="text-left">주식회사 더본코리아</td>
                  <td>211-87-11211</td>
                  <td>홍길동</td>
                  <td>061-793-9238</td>
                  <td>4</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-sm btn-default fcblue"
                    >
                      상세
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-default fcred"
                    >
                      삭제
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>006</td>
                  <td>100025</td>
                  <td className="text-left">주식회사 더본코리아</td>
                  <td>211-87-11211</td>
                  <td>홍길동</td>
                  <td>061-793-9238</td>
                  <td>122</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-sm btn-default fcblue"
                    >
                      상세
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-default fcred"
                    >
                      삭제
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>007</td>
                  <td>100025</td>
                  <td className="text-left">주식회사 더본코리아</td>
                  <td>211-87-11211</td>
                  <td>홍길동</td>
                  <td>061-793-9238</td>
                  <td>2</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-sm btn-default fcblue"
                    >
                      상세
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-default fcred"
                    >
                      삭제
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>008</td>
                  <td>100025</td>
                  <td className="text-left">주식회사 더본코리아</td>
                  <td>211-87-11211</td>
                  <td>홍길동</td>
                  <td>061-793-9238</td>
                  <td>20</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-sm btn-default fcblue"
                    >
                      상세
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-default fcred"
                    >
                      삭제
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>009</td>
                  <td>100025</td>
                  <td className="text-left">주식회사 더본코리아</td>
                  <td>211-87-11211</td>
                  <td>홍길동</td>
                  <td>061-793-9238</td>
                  <td>55</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-sm btn-default fcblue"
                    >
                      상세
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-default fcred"
                    >
                      삭제
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>010</td>
                  <td>100025</td>
                  <td className="text-left">주식회사 더본코리아</td>
                  <td>211-87-11211</td>
                  <td>홍길동</td>
                  <td>061-793-9238</td>
                  <td>11</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-sm btn-default fcblue"
                    >
                      상세
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-default fcred"
                    >
                      삭제
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="panel-footer clearfix">
              <div className="search-pager fl-right">
                <ul className="pagination pagination-sm pagination-split no-margin">
                  <li className="disabled">
                    <a href="#">«</a>
                  </li>
                  <li className="active">
                    <a href="#">
                      1 <span className="sr-only">(current)</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">2</a>
                  </li>
                  <li>
                    <a href="#">3</a>
                  </li>
                  <li>
                    <a href="#">4</a>
                  </li>
                  <li>
                    <a href="#">5</a>
                  </li>
                  <li>
                    <a href="#">»</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <!-- /panel --> */}
        </div>
      </div>
      {/* <!-- /main-container --> */}
      <div className="modal fade" id="formModal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close fl-right"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
              <span className="fcred fl-right marR20">* 필수입력</span>
              <h4>프랜차이즈 등록</h4>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label>
                    <span className="fcred">*</span> 프랜차이즈
                  </label>
                  <div className="  no-margin">
                    <div className="row">
                      <div className="col-lg-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="한글,영문"
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div className="col-lg-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="프랜차이즈코드"
                          disabled
                        />
                      </div>
                    </div>

                    {/* <!-- /input-group-btn --> */}
                  </div>
                </div>
                {/* <!-- /form-group --> */}

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        <span className="fcred">*</span> 사업자등록번호
                      </label>
                      <div className="form-inline no-margin">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            style={{ width: "60p" }}
                          />{" "}
                          -
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            style={{ width: "40px" }}
                          />{" "}
                          -
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            style={{ width: "100px" }}
                          />
                          <button
                            type="button"
                            className="btn  btn-primary"
                            tabIndex="-1"
                          >
                            중복검사
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /form-group --> */}
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      {/* <!--20230707 edit kjaelee

								  <label><span className="fcred">*</span> 사업자유형</label>
								  <select  className="form-control">
									  <option value="" selected>선택하세요.</option>
									  <option value="2" >주식회사</option>
									  <option value="1" >유한회사</option>
								  </select>

								  --> */}

                      <label>
                        <span className="fcred">*</span> 운영상태
                      </label>
                      <div className="form-inline ">
                        <label className="label-radio inline fw-normal ">
                          <input type="radio" name="inline-radio" />
                          <span className="custom-radio"></span>
                          운영
                        </label>
                        <label className="label-radio inline fw-normal">
                          <input type="radio" name="inline-radio" />
                          <span className="custom-radio"></span>
                          미운영
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* <!-- /.col --> */}
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        <span className="fcred">*</span> 사업 종류 업태
                      </label>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="한글입력"
                      />
                    </div>
                    {/* <!-- /form-group --> */}
                  </div>
                  {/* <!-- /.col --> */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        <span className="fcred">*</span> 업종
                      </label>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="한글입력"
                      />
                    </div>
                    {/* <!-- /form-group --> */}
                  </div>
                  {/* <!-- /.col --> */}
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        <span className="fcred">*</span> 주소
                      </label>

                      <div className="form-inline no-margin">
                        <input
                          type="text"
                          className="form-control "
                          placeholder=""
                          style={{ width: "80px" }}
                          name="zipcode"
                          id="zipcode"
                          disabled
                        />
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control "
                            placeholder=""
                            style={{ width: "284px" }}
                            name="address"
                            id="address"
                            disabled
                          />
                          <div className="input-group-btn">
                            <button
                              type="button"
                              className="btn  btn-primary"
                              tabIndex="-1"
                              onlick="kakaopost()"
                            >
                              검색
                            </button>
                          </div>
                          {/* <!-- /input-group-btn --> */}
                        </div>
                      </div>
                      <div className="marT5">
                        <input
                          type="text"
                          className="form-control"
                          id="address2"
                          placeholder="상세 주소 입력"
                        />
                      </div>
                    </div>
                    {/* <!-- /form-group --> */}
                  </div>
                  {/* <!-- /.col --> */}
                  <div className="col-md-6 ">
                    <div className="form-group">
                      <label>
                        <span className="fcred">*</span> 사업장연락처
                      </label>
                      <div className="form-inline no-margin">
                        <select
                          style={{ width: "120px", marginRight: "10px" }}
                          className="form-control"
                        >
                          <option value="">선택하세요.</option>
                          <option value="02">02</option>
                          <option value="031">031</option>
                          <option value="032">032</option>
                          <option value="033">033</option>
                          <option value="041">041</option>
                          <option value="042">042</option>
                          <option value="043">043</option>
                          <option value="044">044</option>
                          <option value="051">051</option>
                          <option value="052">052</option>
                          <option value="053">053</option>
                          <option value="054">054</option>
                          <option value="055">055</option>
                          <option value="061">061</option>
                          <option value="062">062</option>
                          <option value="063">063</option>
                          <option value="064">064</option>
                          <option value="0502">0502</option>
                          <option value="0503">0503</option>
                          <option value="0504">0504</option>
                          <option value="0505">0505</option>
                          <option value="0506">0506</option>
                          <option value="0507">0507</option>
                          <option value="0508">0508</option>
                          <option value="070">070</option>
                          <option value="010">010</option>
                          <option value="011">011</option>
                          <option value="016">016</option>
                          <option value="017">017</option>
                          <option value="018">018</option>
                          <option value="019">019</option>
                        </select>
                        <input
                          type="text"
                          className="form-control "
                          placeholder="숫자"
                        />
                      </div>
                    </div>
                    {/* <!-- /form-group --> */}
                  </div>
                  {/* <!-- /.col --> */}
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        <span className="fcred">*</span> 대표자명
                      </label>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="한글, 영문 입력 1-20자"
                      />
                    </div>
                  </div>
                  {/* <!-- /.col --> */}
                </div>
                {/* <!-- /.row --> */}
              </form>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-success "
                data-dismiss="modal"
                aria-hidden="true"
              >
                취소
              </button>
              <a href="#" className="btn btn-navy ">
                프랜차이즈 등록
              </a>
            </div>
          </div>
          {/* <!-- /.modal-content --> */}
        </div>
        {/* <!-- /.modal-dialog --> */}
      </div>
      {/* <!-- /.modal --> */}

      {/* <!-- 엑셀파일 등록 modal--> */}
      <div className="modal fade" id="excelModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close fl-right"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
              <h4>엑셀파일 등록</h4>
            </div>
            <div className="modal-body">
              <div className="marB30">
                <div className="alert">
                  <i className="fa fa-warning"></i>
                  <span className="m-left-xs">
                    지정된 형식의 엑셀파일만 등록이 가능합니다
                  </span>
                  <div className="m-left-xs">
                    STEP 1. 아래 [서식 파일 받기] 버튼을 클릭하여 엑셀 파일을
                    다운로드
                    <br />
                    STEP 1. 다운로드한 파일에 관련 정보를 입력한 후 저장
                    <br />
                    STEP 1. 아래 [파일 선택] 버튼을 클릭하여 저장된 엑셀 파일을
                    선택한 후, [등록] 버튼 클릭
                    <br />
                  </div>
                </div>
              </div>
              <form action="." className="">
                <div className="form-inline no-margin">
                  <input
                    name="file"
                    type="file"
                    multiple
                    className="form-control "
                    style={{ border: "0px solid #ffffff" }}
                  />
                  <div className="navbar-right">
                    <a href="" role="button" className="btn btn-sm btn-default">
                      <i className="fa fa-download"></i> 서식 파일 받기
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* <!-- /.modal-content --> */}
          <div className="modal-footer">
            <button
              className="btn btn-success "
              data-dismiss="modal"
              aria-hidden="true"
            >
              취소
            </button>
            <a href="#" className="btn btn-navy">
              일괄 등록
            </a>
          </div>
        </div>
        {/* <!-- /.modal-dialog --> */}
      </div>
      {/* <!-- /.modal --> */}
    </div>
  );
};

export default Fran;
