import React from "react";

const LoginFooter = () => {
  return (
    <div className="login-footer">
      Copyright © 2005-2024 HANASIS. All Rights Reserved.
    </div>
  );
};

export default LoginFooter;
