import React, { useState, useRef, useEffect } from "react";
import LoginFooter from "./LoginFooter";
import { fetchData, getAPIsUrl } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const serverUrl = getAPIsUrl();
var keep_login = window.localStorage.getItem("keep_login") || "false";
var login_info = window.localStorage.getItem("login_info") || {};

const LoginForm = () => {
  const navigate = useNavigate();

  const [state, setState] = useState({
    id: "",
    pass: "",
  });

  const [keepLogin, setKeepLogin] = useState(
    window.localStorage.getItem("keep_login") === "true"
  );
  const [loginInfo, setLoginInfo] = useState(
    JSON.parse(window.localStorage.getItem("login_info")) || {}
  );

  const keepLoginRef = useRef("");

  //   useEffect(() => {
  //     //handleAutoLogin();
  //   }, []);

  const handleAutoLogin = () => {
    if (keepLogin && loginInfo.ID && loginInfo.PASS2) {
      // 저장된 로그인 정보를 사용하여 자동 로그인 시도
      fetchLogin(loginInfo.ID, loginInfo.PASS2)
        .then((data) => {
          // 로그인 성공 처리
          console.log("자동로그인 성공 > ", data);
          navigate("/category", { replace: true });
        })
        .catch((error) => {
          // 로그인 실패 처리
          console.log("자동로그인 실패");
        });
    }
  };

  const fetchLogin = (id, password) => {
    // 로그인 API 호출 함수
    const loginUrl = `${serverUrl}/login/getLogin2?id=${id}&pass=${password}`;
    return fetchData("POST", loginUrl);
  };

  useEffect(() => {
    if (keep_login === "true" || keep_login === true) {
      //keepLoginRef.current.checked = true;
    } else {
      //keepLoginRef.current.checked = false;
    }

    // if (keep_login === "true" || keep_login === true) {
    //   keepLoginRef.current.checked = true;
    // } else {
    //   keepLoginRef.current.checked = false;
    // }
    //     // if (typeof login_info === "string") login_info = JSON.parse(login_info);
    //     // console.log("login_info", login_info, typeof login_info);
    //     // const loginUrl = `${serverUrl}/login/getLogin2?id=${login_info.ID}&pass=${login_info.PASS2}`;
    //     // console.log("loginUrl", loginUrl);
    //     // if (keep_login === "true" || keep_login === true) {
    //     //   if (login_info.AUTH_CD === 99 || login_info.AUTH_CD === "99") {
    //     //   } else {
    //     //     toast.error("관리자 권한이 아닙니다.");
    //     //     return;
    //     //   }
    //     //   fetchData("POST", loginUrl)
    //     //     .then((data) => {
    //     //       // 성공적으로 데이터를 가져왔을 때의 처리
    //     //       console.log("Data fetched: ", data);
    //     //       if (data.header) {
    //     //         console.log("data.header", data.header);
    //     //         if (data.header.res_code === "ok") {
    //     //           // 로그인 인포, 스토어 인포 저장 >
    //     //           console.log("data", data.data);
    //     //           // 이동
    //     //           navigate("/category", { replace: true });
    //     //         } else {
    //     //           // 에러 메시지 표현
    //     //           console.log(data.header.res_code, data.header.res_msg);
    //     //           toast.error(
    //     //             data.header.res_msg || "아이디나 패스워드가 틀립니다"
    //     //           );
    //     //         }
    //     //       } else {
    //     //         console.error("header 값이 없음");
    //     //         toast.error("에러가 발생하였습니다.");
    //     //       }
    //     //     })
    //     //     .catch((error) => {
    //     //       // fetchData 함수 내에서 발생한 에러를 처리
    //     //       console.error("Error in fetchData: ", error);
    //     //       toast.error(error);
    //     //     });
    //     // }
  }, []);

  const handleChangeId = (e) => {
    setState({
      ...state,
      id: e.target.value,
    });
  };

  const handleChangePass = (e) => {
    setState({ ...state, pass: e.target.value });
  };

  const handleSubmit = (e) => {
    console.log(state.id);
    console.log(state.pass);

    const loginUrl = `${serverUrl}/login/getLogin?id=${state.id}&pass=${state.pass}`;

    fetchData("POST", loginUrl)
      .then((data) => {
        // 성공적으로 데이터를 가져왔을 때의 처리
        console.log("Data fetched: ", data);

        if (data.header) {
          console.log("data.header", data.header);
          if (data.header.res_code === "ok") {
            // 로그인 인포, 스토어 인포 저장
            console.log("data", data.data);
            if (data.data.AUTH_CD === 99 || data.data.AUTH_CD === "99") {
            } else {
              toast.error("관리자 권한이 아닙니다.");
              return;
            }
            var vObj = JSON.stringify(data.data);
            window.localStorage.setItem("login_info", vObj);
            window.localStorage.setItem(
              "keep_login",
              keepLoginRef.current.checked
            );
            console.log("Login Form > handleSumit ", "이동준비");
            // 이동
            navigate("/category", { replace: true });
            console.log("Login Form > handleSumit ", "navigate 호출");
          } else {
            // 에러 메시지 표현
            console.log(data.header.res_code, data.header.res_msg);
            toast.error(data.header.res_msg || "아이디나 패스워드가 틀립니다");
          }
        } else {
          console.error("header 값이 없음");
          toast.error("에러가 발생하였습니다.");
        }
      })
      .catch((error) => {
        // fetchData 함수 내에서 발생한 에러를 처리
        console.error("Error in fetchData: ", error);
        toast.error(error);
      });
  };

  return (
    <div className="login-body">
      <form className="form-login">
        <div className="form-group">
          <label>아이디</label>
          <input
            type="text"
            placeholder="아이디를 입력하세요."
            className="form-control input-sm bounceIn animation-delay2"
            value={state.id}
            onChange={handleChangeId}
            autoComplete="username"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSubmit(); // Enter 키 입력 시 handleSubmit 함수 실행
              }
            }}
          />
        </div>
        <div className="form-group">
          <label>비밀번호</label>
          <input
            type="password"
            placeholder="비밀번호를 입력하세요."
            className="form-control input-sm bounceIn animation-delay4"
            value={state.pass}
            onChange={handleChangePass}
            autoComplete="current-password"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSubmit(); // Enter 키 입력 시 handleSubmit 함수 실행
              }
            }}
          />
        </div>
        <div className="row">
          <div className="form-group col-lg-6">
            <label className="label-checkbox inline fw-normal">
              <input
                type="checkbox"
                className="regular-checkbox chk-delete"
                ref={keepLoginRef}
              />
              <span className="custom-checkbox info bounceIn animation-delay4"></span>
              로그인 상태 유지{" "}
            </label>
          </div>
          {/* <div className="fl-right col-lg-6 login-search">
            <a href="">아이디</a> | <a href="">비밀번호 찾기</a>
          </div> */}
        </div>

        <a className="btn btn-navy block btn-lg" onClick={handleSubmit}>
          로그인
        </a>
      </form>
      <LoginFooter />
      <ToastContainer autoClose={600} />
    </div>
  );
};

export default LoginForm;
