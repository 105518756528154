export const setPageTitle = (title) => {
  const titleElement = document.getElementsByTagName("title")[0];
  titleElement.innerText = title;
};

export const getCommpaPrice = (price) => {
  if (price === null || price === "" || price === undefined) {
    return ""; // 빈 문자열 리턴
  }
  return price.toLocaleString();
};

const isSeparator = (value) => value === "/" || value === "\\" || value === ":";

export const getContentType = (path) => {
  let fileExt = "";
  //let mime = "application/octet-stream";
  let mime = "image/jpeg";

  for (let i = path.length - 1; i > -1; --i) {
    const value = path[i];
    if (value === ".") {
      if (i > 1) {
        if (isSeparator(path[i - 1])) {
          fileExt = "";
        }
        fileExt = path.substring(i + 1);
        break;
      }
      fileExt = "";
    }
    if (isSeparator(value)) {
      fileExt = "";
    }
  }

  if (fileExt === "jpg" || fileExt === "jpeg") {
    mime = "image/jpeg";
  } else if (fileExt === "png") {
    mime = "image/png";
  } else if (fileExt === "gif") {
    mime = "image/gif";
  } else if (fileExt === "bmp") {
    mime = "image/bmp";
  }

  return mime;
};

export const getLoginCheck = () => {
  var keep_login = window.localStorage.getItem("keep_login");
  var login_info = window.localStorage.getItem("login_info") || null;

  if (typeof login_info === "string") login_info = JSON.parse(login_info);
  console.log("login_info", login_info, typeof login_info);

  if (login_info === null) {
    return false;
  } else {
    return true;
  }
};

export function generateDownload(canvas, crop) {
  if (!crop || !canvas) {
    return;
  }

  canvas.toBlob(
    (blob) => {
      const previewUrl = window.URL.createObjectURL(blob);

      const anchor = document.createElement("a");
      anchor.download = "cropPreview.png";
      anchor.href = URL.createObjectURL(blob);
      anchor.click();

      window.URL.revokeObjectURL(previewUrl);
    },
    "image/png",
    1
  );
}

/**
 * Function to draw cropped image on canvas
 * @param {HTMLImageElement} image
 * @param {HTMLCanvasElement} canvas
 * @param {import('react-image-crop').PixelCrop} crop
 * @returns void
 */
export function drawImageOnCanvas(image, canvas, crop) {
  if (!crop || !canvas || !image) {
    return;
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const ctx = canvas.getContext("2d");
  // refer https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio
  const pixelRatio = window.devicePixelRatio;

  canvas.width = crop.width * pixelRatio * scaleX;
  canvas.height = crop.height * pixelRatio * scaleY;

  // refer https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setTransform
  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = "high";

  // refer https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  );
}
