import React, { useState, useEffect, useRef, Children } from "react";
import ASide from "../../component/ASide/ASide";
import CategoryHeader from "../../component/Header/CategoryHeader";
import CommonHeader from "../../component/Header/CommonHeader";
import BigCatergory from "../../component/Category/BigCatergory";
import MiddleCategory from "../../component/Category/MiddleCategory";
import SmallCategroy from "../../component/Category/SmallCategroy";
import SelectBox from "../../component/SelectBox/SelectBox";
import { fetchData, getAPIsUrl, getLocalAPIsUrl } from "../../utils/api";
import { createBrowserHistory } from "@remix-run/router";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getLoginCheck } from "../../utils/util";
import { useNavigate } from "react-router-dom";

const serverUrl = getAPIsUrl();

const Category = () => {
  const customBoderStyle = {
    border: "0px solid #ffffff",
  };

  const formModalRef = useRef(null);

  const btnRefs = useRef([]);

  const navigate = useNavigate();

  const [selectedAgencyOption, setSelectedAgencyOption] = useState("");
  const [selectedFranOption, setSelectedFranOption] = useState("");
  const [selectedBrandOption, setSelectedBrandOption] = useState("");
  const [selectedStoreOption, setSelectedStoreOption] = useState("");
  const [selectedPosOption, setSelectedPosOption] = useState("");

  // 서버 통신시 필요한 데이터 : 분류 추가시 사용
  const [formState, setFormState] = useState({
    agency: "",
    fran: "",
    brand: "",
    store: "",
    pos: "",
    name: "",
    lvl: "",
    disorder: "",
    x: "",
    y: "",
    useYN: "",
    code: "",
    type: "",
  });

  // 분류 설정 - 중분류에 전달할 내용
  const [middleCategory, setMiddleCategory] = useState({
    agency: "",
    fran: "",
    brand: "",
    store: "",
    pos: "",
    code: "",
    upper: "",
    lvl: 0,
  });

  // 분류 설정 - 소 분류에 전달할 내용
  const [smallCategory, setSmallCategory] = useState({
    agency: "",
    fran: "",
    brand: "",
    store: "",
    pos: "",
    code: "",
    upper: "",
    lvl: 0,
  });

  const bigCategroyRef = React.useRef();
  const middleCategoryRef = React.useRef();

  const orderRef = useRef(null);
  const xSizeRef = useRef(null);
  const ySizeRef = useRef(null);
  const goodsNmRef = useRef(null);
  const goodsIdRef = useRef(null);
  const koeranRef = useRef(null);
  const englishRef = useRef(null);
  const japaneseRef = useRef(null);
  const chineseRef = useRef(null);

  const bigCategoryGoodsCode = useRef(null);

  const handleAgencyChange = (agency) => {
    setSelectedAgencyOption(agency);
    console.log("★★★★★ 1. category agency change ★★★★★", agency);

    const timer = setTimeout(() => {
      bigCategroyRef.current.getCategoryList();
    }, 500);

    //clearTimeout(timer);
  };

  const handleFranChange = (fran) => {
    setSelectedFranOption(fran);
    console.log("★★★★★ 2. category fran change", fran);
  };

  const handleBrandChange = (brand) => {
    setSelectedBrandOption(brand);
    console.log("★★★★★ 3. category brand change", brand);
  };

  const handleStoreChange = (store) => {
    setSelectedStoreOption(store);
    console.log("★★★★★ 4. category store change", store);
  };

  const handlePosChange = (pos) => {
    setSelectedPosOption(pos);
    console.log("★★★★★ 5. category pos change", pos);
  };

  // 분류 설정에 있는 대분류 카테코리를 불러온다. 부모 => 자식
  const handleBigCatetory = () => {
    console.log("handleBigCategory");
    bigCategroyRef.current.getCategoryList();
  };

  // 대분류 카테코리(화면에서)에서 부모한테 값을 전달한다. 그리고 그것을 state에 저장
  const handleMiddleCategory = (
    agency,
    fran,
    brand,
    store,
    pos,
    code,
    upper,
    lvl
  ) => {
    console.log("handleMiddleCategory");
    console.group("big");
    console.log("agency", "fran  ", "brand ", "store", "pos", "code  ", "lvl");
    console.log(agency, fran, brand, store, pos, code, lvl);
    console.groupEnd("big");
    bigCategoryGoodsCode.current.value = code;
    setMiddleCategory({
      agency: agency,
      fran: fran,
      brand: brand,
      store: store,
      pos: pos,
      code: code,
      upper: upper,
      lvl: lvl,
    });

    // 그리고 Middle에 있는 함수 클릭
    //middleCategoryRef.current.getCategoryList();
  };

  const handleSmallCategory = (
    agency,
    fran,
    brand,
    store,
    pos,
    code,
    upper,
    lvl
  ) => {
    console.log("handleSamllCategory");
    console.group("small");
    console.log(
      "agency",
      "fran  ",
      "brand ",
      "store",
      "pos",
      "code  ",
      "upper",
      "lvl"
    );
    console.log(agency, fran, brand, store, pos, code, upper, lvl);
    console.groupEnd("small");

    setSmallCategory({
      agency: agency,
      fran: fran,
      brand: brand,
      store: store,
      pos: pos,
      code: code,
      upper: upper,
      lvl: lvl,
    });
  };

  const handleCategorySave = () => {
    console.group("save");
    console.log("formState.lvl", formState.lvl);
    console.log("formState.type", formState.type);

    if (formState.lv1 === "1") {
      if (formState.type === "추가") {
      } else {
        // 수정
        if (goodsIdRef.current.value === "") {
          goodsIdRef.current.focus();
          toast.error("분류코드를 입력하세요");
          return;
        }
      }
    } else if (formState.lv1 === "2") {
      if (formState.type === "추가") {
      } else {
        // 수정
        if (goodsIdRef.current.value === "") {
          goodsIdRef.current.focus();
          toast.error("분류코드를 입력하세요");
          return;
        }
      }
    } else if (formState.lv1 === "3") {
      if (formState.type === "추가") {
      } else {
        // 수정
        if (goodsIdRef.current.value === "") {
          goodsIdRef.current.focus();
          toast.error("분류코드를 입력하세요");
          return;
        }
      }
    }

    if (orderRef.current.value === "") {
      orderRef.current.focus();
      toast.error("디스플레이 순서를 입력하세요");
      return;
    }

    if (xSizeRef.current.value === "") {
      xSizeRef.current.focus();
      toast.error("X 크기 를 입력하세요");
      return;
    }

    if (ySizeRef.current.value === "") {
      ySizeRef.current.focus();
      toast.error("Y 크기 를 입력하세요");
      return;
    }

    if (goodsNmRef.current.value === "") {
      goodsNmRef.current.focus();
      toast.error("분류명을 입력하세요");
      return;
    }

    if (formState.lv1 === "1") {
      if (koeranRef.current.value === "") {
        koeranRef.current.focus();
        toast.error("한국어를 입력하세요");
        return;
      }

      if (englishRef.current.value === "") {
        englishRef.current.focus();
        toast.error("영어를 입력하세요");
        return;
      }

      if (japaneseRef.current.value === "") {
        japaneseRef.current.focus();
        toast.error("일본어를 입력하세요");
        return;
      }

      if (chineseRef.current.value === "") {
        chineseRef.current.focus();
        toast.error("중국어를 입력하세요");
        return;
      }
    }

    console.groupEnd();

    const useYn = $("select[name='useYN']").val();
    //console.log("useYn", useYn);
    setFormState((prevState) => {
      const updatedState = {
        ...prevState,
        agency: selectedAgencyOption,
        fran: selectedFranOption,
        brand: selectedBrandOption,
        store: selectedStoreOption,
        pos: selectedPosOption,
        useYN: useYn,
      };

      console.group("1. 분류 - 카테고리");
      console.log("2. 선택된 대행사 옵션:", selectedAgencyOption);
      console.log("3. 업데이트된 formState:", updatedState);

      console.groupEnd("분류 - 카테고리");

      // api 호출

      let Url = "";
      let method = "PUT";

      if (updatedState.type === "추가") {
        Url = `${serverUrl}/category/saveCategory`;
        method = "POST";
      } else {
        Url = `${serverUrl}/category/updateCategory`;
        method = "PUT";
      }

      console.log("저장", method, Url);

      let nextGoodsId = "";

      fetchData(method, Url, updatedState)
        .then((data) => {
          // 성공적으로 데이터를 가져왔을 때의 처리
          //console.log("Data fetched: ", data);

          if (data.header) {
            if (data.header.res_code === "ok") {
              //
              nextGoodsId = data.data;
              console.log(data.data);
              toast.success(data.res_msg || "분류가 저장되었습니다.");
              console.log(
                " >>>>>>>>>> 저장 >>>>>>>>>>>>>> ",
                data.res_code,
                data.res_msg
              );

              if (formState.lvl === "1") {
                setFormState({
                  ...formState,
                  disorder: "",
                  x: "",
                  y: "",
                  useYN: "Y",
                  upper: "",
                  name: "",
                });
              } else {
                setFormState({
                  ...formState,
                  disorder: "",
                  x: "",
                  y: "",
                  useYN: "Y",
                  name: "",
                });
              }

              if (formState.lvl === "1") {
                // 다국어 ===>
                Url = "";
                if (updatedState.type === "추가") {
                  //alert("다국어 추가");
                  Url = `${serverUrl}/lang/addLang?agency=${selectedAgencyOption}&fran=${selectedFranOption}&brand=${selectedBrandOption}&store=${selectedStoreOption}&pos=${selectedPosOption}&code=${nextGoodsId}
					&gubun=category&big=&middle=&small=&korean=${koeranRef.current.value}&english=${englishRef.current.value}&japanese=${japaneseRef.current.value}
					&chinese=${chineseRef.current.value}&lvl=${formState.lvl}`;

                  method = "POST";
                } else {
                  //alert(updatedState.code);
                  Url = `${serverUrl}/lang/editLang?agency=${selectedAgencyOption}&fran=${selectedFranOption}&brand=${selectedBrandOption}&store=${selectedStoreOption}&pos=${selectedPosOption}&code=${updatedState.code}
                      &gubun=category&big=&middle=&small=&korean=${koeranRef.current.value}&english=${englishRef.current.value}&japanese=${japaneseRef.current.value}
                      &chinese=${chineseRef.current.value}`;

                  method = "PUT";
                }

                console.log("Url", Url);
                fetchData(method, Url)
                  .then((data) => {
                    // 성공적으로 데이터를 가져왔을 때의 처리
                    console.log("addLang fetched: ", data);

                    if (data.header) {
                      //console.log("data.header", data.header);
                      if (data.header.res_code === "ok") {
                        //setGoodsList(data.data);
                        toast.success(
                          data.res_msg || "다국어가 저장되었습니다."
                        );
                        console.log("다국어 입력 => ", data.data);
                        //search();
                      } else {
                        // 에러 메시지 표현
                        //setGoodsList([]);
                        console.log(data.header.res_code, data.header.res_msg);
                        toast.error(
                          data.res_msg || "다국어가 저장실패하였습니다."
                        );
                        //search();
                      }
                    } else {
                      //setGoodsList([]);
                      toast.error("다국어 저장시 에러가 발생하였습니다.");
                      console.error("header 값이 없음");
                      //search();
                    }
                  })
                  .catch((error) => {
                    // fetchData 함수 내에서 발생한 에러를 처리
                    //setGoodsList([]);
                    toast.error(error);
                    console.error("Error in addLang fetchData: ", error);
                    // search();
                  });

                // 다국어 <===
              }

              bigCategroyRef.current.getCategoryList();
              console.log(formState);
            } else {
              // 에러 메시지 표현
              bigCategroyRef.current.getCategoryList();
              console.log(data.header.res_code, data.header.res_msg);
            }
          } else {
            bigCategroyRef.current.getCategoryList();
            console.error("header 값이 없음");
          }
        })
        .catch((error) => {
          bigCategroyRef.current.getCategoryList();
          // fetchData 함수 내에서 발생한 에러를 처리
          console.error("Error in fetchData: ", error);
        });

      return updatedState;

      //   console.log("4. 업데이트된 formState ori:", formState);
    });

    if (formModalRef.current) {
      $(".btn.btn-success[data-dismiss='modal']").trigger("click");
    }

    // setFormState({
    //   ...formState,
    //   agency: selectedAgencyOption,
    //   fran: selectedFranOption,
    //   brand: selectedBrandOption,
    //   store: selectedStoreOption,
    //   pos: selectedPosOption,
    // });

    // console.log(selectedAgencyOption);

    // console.group("분류 - 카테고리");

    // console.log(selectedAgencyOption);
    // console.log("폼 정보 ", formState);

    // console.groupEnd("분류 - 카테고리");

    // if (formModalRef.current) {
    //   $(".btn.btn-success[data-dismiss='modal']").trigger("click");
    // }
  };

  const handleGetLvl = (lvl, upper, type, newForm) => {
    console.log("래베루", lvl);

    if (lvl === "1") {
      $("#lang_id").show();
    } else {
      $("#lang_id").hide();
    }

    koeranRef.current.value = "";
    englishRef.current.value = "";
    japaneseRef.current.value = "";
    chineseRef.current.value = "";

    if (type === "추가") {
      setFormState({
        ...formState,
        lvl: lvl,
        upper: upper,
        type: type,
        code: "",
        name: "",
        disorder: "",
        x: "",
        y: "",
      });
    } else {
      setFormState(newForm);

      console.group("selectOnLang");
      // 검색해서 다국어 내용을 채워준다.
      const Url = `${serverUrl}/lang/selectOneLang?agency=${newForm.agency}&fran=${newForm.fran}&brand=${newForm.brand}&store=${newForm.store}
	  &pos=${newForm.pos}&gubun=category&lvl=${lvl}&code=${newForm.code}`;
      const method = "GET";
      console.log("Url", Url);
      fetchData(method, Url)
        .then((data) => {
          // 성공적으로 데이터를 가져왔을 때의 처리
          console.log("selectOneLang fetched: ", data);

          if (data.header) {
            //console.log("data.header", data.header);
            if (data.header.res_code === "ok") {
              koeranRef.current.value = data.data[0].KOREAN;
              englishRef.current.value = data.data[0].ENGLISH;
              japaneseRef.current.value = data.data[0].JAPANESE;
              chineseRef.current.value = data.data[0].CHINESE;

              console.log("selectOneLang", data.data);
            } else {
              // 에러 메시지 표현
              //setGoodsList([]);
              console.log(data.header.res_code, data.header.res_msg);
              //search();
            }
          } else {
            //setGoodsList([]);
            console.error("header 값이 없음");
            //search();
          }
        })
        .catch((error) => {
          // fetchData 함수 내에서 발생한 에러를 처리
          //setGoodsList([]);
          toast.error(error);
          console.error("Error in addLang fetchData: ", error);
          // search();
        });
      console.groupEnd();
    }
  };

  const handleInputChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  //   React.Children.map(children, (child) => {
  //     if (child.props.isLoaded) {
  //       // 각 차일드 컴포넌트의 로딩 완료 상태 처리
  //       console.log(`${child.type.name} 로딩 완료`);
  //     }
  //   });

  useEffect(() => {
    // 컴포넌트가 마운트될 때 API 호출
    const keep_login = window.localStorage.getItem("keep_login") || "false";
    console.log("##### 마운트 #####");

    if (!getLoginCheck()) {
      navigate("/login", { replace: true });
      return;
    } else {
      //   if (keep_login === "false" || keep_login === false) {
      //     navigate("/login", { replace: true });
      //     return;
      //   }
    }
    // else if (keep_login === "false" || keep_login === false) {
    //   navigate("/login", { replace: true });
    //   return;
    // }

    return () => {
      // 컴포넌트가 언마운트될 때 리소스 정리
      //cleanupSubscription();
    };
  }, []);

  return (
    <div>
      <div id="wrapper" className="preload">
        <CommonHeader />
        <ASide index={0} gubun={"goods"} />
        <div id="main-container">
          <CategoryHeader title={"상품 분류 관리"} />

          <div className="padding-md">
            <div className="panel panel-default ">
              <div className="panel-body">
                {/*  */}
                <SelectBox
                  onAgencyChange={handleAgencyChange}
                  onFranChange={handleFranChange}
                  onBrandChange={handleBrandChange}
                  onStoreChange={handleStoreChange}
                  onPosChange={handlePosChange}
                  onButtonClick={handleBigCatetory}
                  type={"category"}
                />
                <div className="row">
                  <BigCatergory
                    agency={selectedAgencyOption}
                    fran={selectedFranOption}
                    brand={selectedBrandOption}
                    store={selectedStoreOption}
                    pos={selectedPosOption}
                    onhandleTrClick={handleMiddleCategory}
                    handleGetLvl={handleGetLvl}
                    ref={bigCategroyRef}
                  />
                  <MiddleCategory
                    middle={middleCategory}
                    onhandleTrClick={handleSmallCategory}
                    handleGetLvl={handleGetLvl}
                    ref={middleCategoryRef}
                  />
                  <SmallCategroy
                    small={smallCategory}
                    handleGetLvl={handleGetLvl}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade in"
          id="formModal"
          style={{ display: "none" }}
          aria-hidden="false"
          ref={formModalRef}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close fl-right"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  &times;
                </button>
                <span className="fcred fl-right marR20">* 필수입력</span>
                <h4>상품 분류 추가 및 설정</h4>
              </div>
              <div className="modal-body">
                <form>
                  <label>
                    <span className="fcred">*</span> 분류명
                  </label>
                  <div className="row">
                    <div className="col-md-3 pad0 padl15">
                      <div className="form-group">
                        <select className="form-control" value={formState.lvl}>
                          <option value="">선택하세요.</option>
                          <option value="1">대분류</option>
                          <option value="2">중분류</option>
                          <option value="3">소분류</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 pad0">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control "
                          placeholder="분류명"
                          value={formState.name}
                          onChange={handleInputChange}
                          name="name"
                          ref={goodsNmRef}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 pad0 padr15">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control "
                          placeholder="분류 코드"
                          disabled
                          onChange={handleInputChange}
                          name="code"
                          value={formState.code}
                          ref={goodsIdRef}
                        />
                      </div>
                    </div>
                  </div>

                  <label>
                    <span className="fcred">*</span> 상위 분류설정{" "}
                    {formState.uppername}
                  </label>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control "
                      placeholder="상위 분류"
                      disabled
                      value={formState.upper}
                    />
                  </div>

                  <label>
                    <span className="fcred">*</span> 사용여부
                  </label>
                  <div className="form-group">
                    <select
                      className="form-control"
                      name="useYN"
                      onChange={handleInputChange}
                      value={formState.useYN}
                    >
                      <option value="Y">사용</option>
                      <option value="N">중지</option>
                    </select>
                  </div>

                  <label>
                    <span className="fcred">*</span> 디스플레이 순서
                  </label>

                  <div className="row">
                    <div className="col-md-3 pad0 padl15">
                      <div className="form-group">
                        <input
                          type="number"
                          className="form-control "
                          placeholder="순서"
                          name="disorder"
                          onChange={handleInputChange}
                          value={formState.disorder}
                          ref={orderRef}
                        />
                      </div>
                    </div>
                  </div>

                  <label>
                    <span className="fcred">*</span> 크기
                  </label>
                  <div className="row">
                    <div className="col-md-3 pad0 padl15">
                      <div className="form-group">
                        <input
                          type="number"
                          className="form-control "
                          placeholder="X 크기"
                          onChange={handleInputChange}
                          name="x"
                          value={formState.x}
                          ref={xSizeRef}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 pad0 padl15">
                      <div className="form-group">
                        <input
                          type="number"
                          className="form-control "
                          placeholder="Y 크기"
                          onChange={handleInputChange}
                          name="y"
                          value={formState.y}
                          ref={ySizeRef}
                        />
                      </div>
                    </div>
                  </div>
                  <div id="lang_id">
                    <label>
                      <span className="fcred">*</span> 다국어 ( 한국어 / 영어 /
                      일본어 / 중국어 )
                    </label>
                    <div className="row">
                      <div className="col-md-3 pad1 padl15">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control "
                            placeholder="한국어"
                            ref={koeranRef}
                          />
                        </div>
                      </div>
                      <div className="col-md-3 pad1 padl15">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control "
                            placeholder="영어"
                            ref={englishRef}
                          />
                        </div>
                      </div>
                      <div className="col-md-3 pad1 padl15">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control "
                            placeholder="일본어"
                            ref={japaneseRef}
                          />
                        </div>
                      </div>
                      <div className="col-md-3 pad1 padl15">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control "
                            placeholder="중국어"
                            ref={chineseRef}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-success "
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  취소
                </button>
                <a
                  className="btn btn-navy"
                  //   data-dismiss="modal"
                  //   aria-hidden="false"
                  onClick={handleCategorySave}
                >
                  저장
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="excelModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close fl-right"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  &times;
                </button>
                <h4>엑셀파일 등록</h4>
              </div>
              <div className="modal-body">
                <div className="marB30">
                  <div className="alert">
                    <i className="fa fa-warning"></i>
                    <span className="m-left-xs">
                      지정된 형식의 엑셀파일만 등록이 가능합니다
                    </span>
                    <div className="m-left-xs">
                      <span>
                        STEP 1. 아래 [서식 파일 받기] 버튼을 클릭하여 엑셀
                        파일을 다운로드
                        <br />
                        STEP 1. 다운로드한 파일에 관련 정보를 입력한 후 저장
                        <br />
                        STEP 1. 아래 [파일 선택] 버튼을 클릭하여 저장된 엑셀
                        파일을 선택한 후, [등록] 버튼 클릭
                        <br />
                      </span>
                    </div>
                  </div>
                  <form action="." className="">
                    <div className="form-inline no-margin">
                      <input
                        name="file"
                        type="file"
                        multiple
                        className="form-control "
                        style={customBoderStyle}
                      />
                      <div className="navbar-right">
                        <a
                          href=""
                          role="button"
                          className="btn btn-sm btn-default"
                        >
                          <i className="fa fa-download"></i> 서식 파일 받기
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-success "
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  취소
                </button>
                <a href="#" className="btn btn-navy">
                  일괄 등록
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <input type="hidden" ref={bigCategoryGoodsCode} />
      <ToastContainer autoClose={600} />
    </div>
  );
};

export default Category;
