import React, { useState, useEffect, useRef } from "react";
import { fetchData, getAPIsUrl, getLocalAPIsUrl } from "../../utils/api";

const serverUrl = getAPIsUrl();

const SelectBox = ({
  onAgencyChange,
  onFranChange,
  onBrandChange,
  onStoreChange,
  onPosChange,
  onButtonClick,
  type,
}) => {
  const [agency, setAgency] = useState([]);
  const [selectedAgencyOption, setSelectedAgencyOption] = useState("");
  const [fran, setFran] = useState([]);
  const [selectedFranOption, setSelectedFranOption] = useState("");
  const [brand, setBrand] = useState([]);
  const [selectedBrandOption, setSelectedBrandOption] = useState("");
  const [store, setStore] = useState([]);
  const [selectedStoreOption, setSelectedStoreOption] = useState("");
  const [pos, setPos] = useState([]);
  const [selectedPos, setSelectedPos] = useState("");

  const buttonRef = useRef(null);

  const agencyUrl = `${serverUrl}/agnecy/getListNormalAgency`;

  useEffect(() => {
    console.log(
      "선택 대리점",
      "선택 프랜",
      "선택 브랜드",
      "선택 점포",
      "선택 포스"
    );
    console.log(
      selectedAgencyOption,
      selectedFranOption,
      selectedBrandOption,
      selectedStoreOption,
      selectedPos
    );

    //onButtonClick();
  });

  // 대리점 리스트 가져오기
  useEffect(() => {
    // agegy 값 가져오기
    fetchData("GET", agencyUrl)
      .then((data) => {
        // 성공적으로 데이터를 가져왔을 때의 처리
        //console.log("Data fetched: ", data);

        if (data.header) {
          console.log("data.header", data.header);
          if (data.header.res_code === "ok") {
            // 로그인 인포, 스토어 인포 저장
            console.log("1. 대리점 데이터 리스트  => ", data.data);
            setAgency(data.data);
            setSelectedAgencyOption(data.data[0].CODE);
            onAgencyChange(data.data[0].CODE);
            getFranSelectList(data.data[0].CODE);
          } else {
            // 에러 메시지 표현
            setAgency([]);
            setSelectedAgencyOption("");
            onAgencyChange("");

            console.log(data.header.res_code, data.header.res_msg);
          }
        } else {
          setAgency([]);
          setSelectedAgencyOption("");
          onAgencyChange("");
          console.error("header 값이 없음");
        }
      })
      .catch((error) => {
        // fetchData 함수 내에서 발생한 에러를 처리
        console.error("Error in fetchData: ", error);
      });
  }, []);

  const getFranSelectList = (agency) => {
    const url = `${serverUrl}/franchise/getComboFran?agency=${agency}`;

    fetchData("GET", url)
      .then((data) => {
        // 성공적으로 데이터를 가져왔을 때의 처리
        //console.log("Data fetched: ", data);

        if (data.header) {
          //console.log("data.header", data.header);
          if (data.header.res_code === "ok") {
            console.log("2. 프렌차이즈 데이터 리스트 => ", data.data);
            setFran(data.data);
            setSelectedFranOption(data.data[0].CODE);
            onFranChange(data.data[0].CODE);
            getBrandSelectList(data.data[0].AGENCY_CODE, data.data[0].CODE);
          } else {
            // 에러 메시지 표현
            setFran([]);
            setSelectedFranOption("");
            onFranChange("");
            console.log(data.header.res_code, data.header.res_msg);
          }
        } else {
          setFran([]);
          setSelectedFranOption("");
          onFranChange("");
          console.error("header 값이 없음");
        }
      })
      .catch((error) => {
        // fetchData 함수 내에서 발생한 에러를 처리
        console.error("Error in fetchData: ", error);
      });
  };

  const getBrandSelectList = (agency, fran) => {
    const url = `${serverUrl}/brand/getSelectOptBrand?agency=${agency}&fran=${fran}`;
    console.log("url", url);

    fetchData("GET", url)
      .then((data) => {
        // 성공적으로 데이터를 가져왔을 때의 처리
        //console.log("Data fetched: ", data);

        if (data.header) {
          //console.log("data.header", data.header);
          if (data.header.res_code === "ok") {
            console.log("3. 브랜드 데이터 리스트 => ", data.data);
            setBrand(data.data);
            setSelectedBrandOption(data.data[0].CODE);
            onBrandChange(data.data[0].CODE);
            getStoreSelectList(
              data.data[0].AGENCY_CODE,
              data.data[0].FRANCHISE_CODE,
              data.data[0].CODE
            );
          } else {
            // 에러 메시지 표현
            setBrand([]);
            setSelectedBrandOption("");
            onBrandChange("");
            console.log(data.header.res_code, data.header.res_msg);
          }
        } else {
          setBrand([]);
          setSelectedBrandOption("");
          onBrandChange("");
          console.error("header 값이 없음");
        }
      })
      .catch((error) => {
        // fetchData 함수 내에서 발생한 에러를 처리
        console.error("Error in fetchData: ", error);
      });
  };

  const getStoreSelectList = (agency, fran, brand) => {
    const url = `${serverUrl}/store/getSelectOptStore?agency=${agency}&fran=${fran}&brand=${brand}`;

    fetchData("GET", url)
      .then((data) => {
        // 성공적으로 데이터를 가져왔을 때의 처리
        //console.log("Data fetched: ", data);

        if (data.header) {
          //console.log("data.header", data.header);
          if (data.header.res_code === "ok") {
            console.log("4. 점 데이터 리스트 => ", data.data);
            setStore(data.data);
            setSelectedStoreOption(data.data[0].STORE_CODE);
            onStoreChange(data.data[0].STORE_CODE);
            getPosSelectList(
              data.data[0].AGENCY_CODE,
              data.data[0].FRANCHISE_CODE,
              data.data[0].BRAND_CODE,
              data.data[0].STORE_CODE
            );
          } else {
            // 에러 메시지 표현
            setStore([]);
            setSelectedStoreOption("");
            onStoreChange("");
            console.log(data.header.res_code, data.header.res_msg);
          }
        } else {
          setStore([]);
          setSelectedStoreOption("");
          onStoreChange("");
          console.error("header 값이 없음");
        }
      })
      .catch((error) => {
        // fetchData 함수 내에서 발생한 에러를 처리
        console.error("Error in fetchData: ", error);
      });
  };

  const getPosSelectList = async (agency, fran, brand, store) => {
    const url = `${serverUrl}/pos/getPosSelectBox?agency=${agency}&fran=${fran}&brand=${brand}&store=${store}`;

    await fetchData("GET", url)
      .then((data) => {
        // 성공적으로 데이터를 가져왔을 때의 처리
        //console.log("Data fetched: ", data);

        if (data.header) {
          //console.log("data.header", data.header);
          if (data.header.res_code === "ok") {
            console.log("5. POS 데이터 리스트 => ", data.data);
            setPos(data.data);
            setSelectedPos(data.data[0].POS_NO);
            onPosChange(data.data[0].POS_NO);
          } else {
            // 에러 메시지 표현
            setPos([]);
            setSelectedPos("");
            onPosChange("");
            console.log(data.header.res_code, data.header.res_msg);
          }
        } else {
          setPos([]);
          setSelectedPos("");
          onPosChange("");
          console.error("header 값이 없음");
        }
      })
      .catch((error) => {
        // fetchData 함수 내에서 발생한 에러를 처리
        console.error("Error in fetchData: ", error);
      });

    if (type == "category") onButtonClick();
  };

  // SELECT option 변경 event 1 - agency
  const handleFirstSelectChange = (event) => {
    setSelectedAgencyOption(event.target.value);
    onAgencyChange(event.target.value);
    getFranSelectList(event.target.value);
    console.log("agency Select Change");
  };

  // SELECT option 변경 event 2 - fran
  const handleSecondSelectChange = (event) => {
    setSelectedFranOption(event.target.value);
    onFranChange(event.target.value);
    getBrandSelectList(selectedAgencyOption, event.target.value);
    console.log("fran Select Change");
  };

  // SELECT option 변경 event 3 - brand
  const handleThirdSelectChange = (event) => {
    setSelectedBrandOption(event.target.value);
    onBrandChange(event.target.value);
    getStoreSelectList(
      selectedAgencyOption,
      selectedFranOption,
      selectedBrandOption
    );
    console.log("brand Select Change");
  };

  // SELECT option 변경 event 4 - store
  const handleForthSelectChange = (event) => {
    setSelectedStoreOption(event.target.value);
    onStoreChange(event.target.value);
    getPosSelectList(
      selectedAgencyOption,
      selectedFranOption,
      selectedBrandOption,
      selectedStoreOption
    );
    console.log("store Select Change");
  };

  // SELECT option 변경 event 5 - pos
  const handleFithSelectChange = (event) => {
    setSelectedPos(event.target.value);
    onPosChange(event.target.value);

    onButtonClick();
    console.log("store Select Change");
  };

  const handleButtonClick = (event) => {
    console.log("select box 버튼 클릭");
    onButtonClick();
  };

  const customBlockStyle = {
    display: "inline-block",
    width: "150px",
  };

  const customFloatStyle = {
    float: "left",
  };

  return (
    <div className="row">
      <div className="col-lg-6 col-sm-6 col-xs-6 fl-right">
        <div style={customFloatStyle} className="marR2">
          <select
            className="form-control input-sm"
            style={customBlockStyle}
            onChange={handleFirstSelectChange}
          >
            {/* <option value="">대리점</option> */}
            {agency.map((option, index) => (
              <option key={index} value={option.CODE}>
                {option.CODE_NAME}
              </option>
            ))}
          </select>
        </div>
        <div style={customFloatStyle} className="marR2">
          <select
            className="form-control input-sm"
            style={customBlockStyle}
            onChange={handleSecondSelectChange}
          >
            {fran.map((option, index) => (
              <option key={index} value={option.CODE}>
                {option.CODE_NAME}
              </option>
            ))}
          </select>
        </div>
        <div style={customFloatStyle} className="marR2">
          <select
            className="form-control input-sm"
            style={customBlockStyle}
            onChange={handleThirdSelectChange}
          >
            {brand.map((option, index) => (
              <option key={index} value={option.CODE}>
                {option.CODE_NAME}
              </option>
            ))}
          </select>
        </div>
        <div style={customFloatStyle} className="marR2">
          <select
            className="form-control input-sm"
            style={customBlockStyle}
            onChange={handleForthSelectChange}
          >
            {store.map((option, index) => (
              <option key={index} value={option.STORE_CODE}>
                {option.STORE_NM}
              </option>
            ))}
          </select>
        </div>
        <div style={customFloatStyle} className="marR2">
          <select
            className="form-control input-sm"
            style={customBlockStyle}
            onChange={handleFithSelectChange}
          >
            {pos.map((option, index) => (
              <option key={index} value={option.POS_NO}>
                {option.POS_NO}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <div className="input-group">
            {/* <input
              type="text"
              className="form-control input-sm"
              placeholder="검색어를 입력하세요."
            /> */}
            <span className="input-group-btn">
              <button
                className="btn btn-default btn-sm"
                type="button"
                onClick={handleButtonClick}
                ref={buttonRef}
              >
                <i className="fa fa-search"></i>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectBox;
