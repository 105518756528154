import React from "react";

const FranHeader = () => {
  return (
    <div className="main-header clearfix row">
      <div
        className="page-title col-sm-12 col-xs-12"
        style={{ width: "200px" }}
      >
        <h3 className="no-margin fw500 fcblack">프랜차이즈 관리</h3>
      </div>
      {/* <!-- /page-title --> */}

      <div className="navbar-right marR20">
        <span>
          <a
            href="#formModal"
            role="button"
            data-toggle="modal"
            className="btn btn-sm btn-danger"
            data-backdrop="static"
            data-keyboard="false"
          >
            <i className="fa fa-plus-circle"></i> 프랜차이즈 등록
          </a>{" "}
        </span>
        {/* <span>
          <a
            href="#excelModal"
            role="button"
            data-toggle="modal"
            className="btn btn-sm btn-success"
            data-backdrop="static"
            data-keyboard="false"
          >
            <i className="fa fa-upload"></i> 엑셀파일 등록
          </a>{" "}
        </span>
        <span>
          <a href="" role="button" className="btn btn-sm btn-default">
            <i className="fa fa-download"></i> 엑셀파일 받기
          </a>{" "}
        </span> */}
      </div>

      {/* <!-- /page-stats --> */}
    </div>
  );
};

export default FranHeader;
