import React, { useState, useEffect, useMemo, useRef } from "react";
import CommonHeader from "../../component/Header/CommonHeader";
import ASide from "../../component/ASide/ASide";
import GoodsHeader from "../../component/Header/GoodsHeader";
import { fetchData, getAPIsUrl, getLocalAPIsUrl } from "../../utils/api";
import { getCommpaPrice, getContentType } from "../../utils/util";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Resizer from "react-image-file-resizer";
import ReactDOM, { createRoot } from "react-dom";
import { useNavigate } from "react-router-dom";
import {
  getLoginCheck,
  drawImageOnCanvas,
  generateDownload,
} from "../../utils/util";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const serverUrl = getAPIsUrl();

var sendData = {
  agency: "",
  fran: "",
  brand: "",
  store: "",
  goodsid: "",
  pos: "",
  goodsname: "",
  categoryBig: "",
  categoryMiddle: "",
  categorySmall: "",
  salePrice: 0,
  eventSdate: "",
  eventEdate: "",
  eventPrice: 0,
  eventYN: "N",
  saleYN: "Y",
  custCD: "",
  taxYN: "Y",
  bottleCD: "",
  bottleAmt: 0,
  deleteDate: "",
  goodsBigNm: "",
  goodsMiddileNm: "",
  juniorYN: "N",
  pointSaveYN: "N",
  itemCommissionYN: "",
  itemCommissionGB: "",
  itemCommissionRate: 0,
  itemCashgateHead: "",
  itemCashgateHeadNM: "",
  itemCashgateStCD: 0,
  itemCashgateStNM: "",
  itemCashgateStMethod: "",
  itemCashgateSEQDFT: 0,
  onlyCashYN: "N",
  korailPassDcType: "",
  harmYN: "N",
  harmCN: "",
  expCheckYN: "N",
  expCheckType: "",
  expTime: "",
  timeBarcodeType: "",
  cookYN: "N",
  CookClassNo: "",
  dcYN: "N",
  orderPrinterYN: "N",
  cultureYN: "N",
  description: "",
  itemDetailNM: "",
  hasOption: "N",
  offPrice: 0,
  offPercent: 0,
  isDetail: "N",
  imageName: "",
  filePath: "./img/goods/",
  serverFolderPath: "",
  imageIsChange: false,
  oldImageFile: "",
};

var sendFalg = "";

const Goods = () => {
  const customBoderStyle = {
    border: "0px solid #ffffff",
  };

  const customBlockStyle = {
    display: "inline-block",
    width: "150px",
  };

  const customFloatStyle = {
    float: "left",
  };

  const sendDataInit = () => {
    sendData.agency = "";
    sendData.fran = "";
    sendData.brand = "";
    sendData.store = "";
    sendData.goodsid = "";
    sendData.pos = "";
    sendData.goodsname = "";
    sendData.categoryBig = "";
    sendData.categoryMiddle = "";
    sendData.categorySmall = "";
    sendData.salePrice = 0;
    sendData.eventSdate = "";
    sendData.eventEdate = "";
    sendData.eventPrice = 0;
    sendData.eventYN = "N";
    sendData.saleYN = "";
    sendData.custCD = "";
    sendData.taxYN = "";
    sendData.bottleCD = "";
    sendData.bottleAmt = 0;
    sendData.deleteDate = "";
    sendData.goodsBigNm = "";
    sendData.goodsMiddileNm = "";
    sendData.juniorYN = "N";
    sendData.pointSaveYN = "N";
    sendData.itemCommissionYN = "";
    sendData.itemCommissionGB = "";
    sendData.itemCommissionRate = 0;
    sendData.itemCashgateHead = "";
    sendData.itemCashgateHeadNM = "";
    sendData.itemCashgateStCD = 0;
    sendData.itemCashgateStNM = "";
    sendData.itemCashgateStMethod = "";
    sendData.itemCashgateSEQDFT = 0;
    sendData.onlyCashYN = "N";
    sendData.korailPassDcType = "";
    sendData.harmYN = "N";
    sendData.harmCN = "";
    sendData.expCheckYN = "N";
    sendData.expCheckType = "";
    sendData.expTime = "";
    sendData.timeBarcodeType = "";
    sendData.cookYN = "N";
    sendData.CookClassNo = "";
    sendData.dcYN = "N";
    sendData.orderPrinterYN = "N";
    sendData.cultureYN = "N";
    sendData.description = "";
    sendData.itemDetailNM = "";
    sendData.hasOption = "N";
    sendData.offPrice = 0;
    sendData.offPercent = 0;
    sendData.isDetail = "N";
    sendData.imageName = "";
    sendData.filePath = "./img/goods/";
    sendData.serverFolderPath = "";
    sendData.imageIsChange = false;
    sendData.oldImageFile = "";
  };

  const navigate = useNavigate();

  const handleBtnImgDel = (e) => {
    console.log("이미지 버튼 삭제");
    // 이미지 미리 보기 삭제
    attZoneRef.current.innerHTML = "";
  };

  // 대리점
  const agencyRef = useRef(null);
  // 프랜차이즈
  const franRef = useRef(null);
  // 브랜드
  const brandRef = useRef(null);
  // 점코드
  const storeRef = useRef(null);
  // 포스번호
  const posRef = useRef(null);
  // 대분류
  const bigRef = useRef(null);
  // 중분류
  const middleRef = useRef(null);
  // 소분류
  const smallRef = useRef(null);
  // 메뉴이름
  const itemNameRef = useRef(null);
  // 상품코드
  const itemcodeRef = useRef(null);
  // 옵션가능
  const hasOptionRef = useRef(null);
  // 판매여부
  const saleYnRef = useRef(null);
  // 판매가격
  const priceRef = useRef(null);
  // 할인율
  const offPercentRef = useRef(null);
  // 할인금액
  const offPriceRef = useRef(null);

  // 파일
  const fileInputRef = useRef(null);
  // 이미지 미리보기
  const attZoneRef = useRef(null);
  // 상세/수정
  const editRef = useRef(null);

  // 파일 이미지
  const fileRef = useRef(null);

  // 조회시 사용
  const [formState, setFormState] = useState({
    agency: "",
    fran: "",
    brand: "",
    store: "",
    pos: "",
    goods_name: "",
    pageNumber: 1,
    pageSize: 10,
  });

  const initRegState = {
    agency: "",
    fran: "",
    brand: "",
    store: "",
    goodsid: "",
    pos: "",
    goodsname: "",
    categoryBig: "",
    categoryMiddle: "",
    categorySmall: "",
    salePrice: 0,
    eventSdate: "",
    eventEdate: "",
    eventPrice: 0,
    eventYN: "N",
    saleYN: "Y",
    custCD: "",
    taxYN: "Y",
    bottleCD: "",
    bottleAmt: 0,
    deleteDate: "",
    goodsBigNm: "",
    goodsMiddileNm: "",
    juniorYN: "N",
    pointSaveYN: "N",
    itemCommissionYN: "",
    itemCommissionGB: "",
    itemCommissionRate: 0,
    itemCashgateHead: "",
    itemCashgateHeadNM: "",
    itemCashgateStCD: 0,
    itemCashgateStNM: "",
    itemCashgateStMethod: "",
    itemCashgateSEQDFT: 0,
    onlyCashYN: "N",
    korailPassDcType: "",
    harmYN: "N",
    harmCN: "",
    expCheckYN: "N",
    expCheckType: "",
    expTime: "",
    timeBarcodeType: "",
    cookYN: "N",
    CookClassNo: "",
    dcYN: "N",
    orderPrinterYN: "N",
    cultureYN: "N",
    description: "",
    itemDetailNM: "",
    hasOption: "N",
    offPrice: 0,
    offPercent: 0,
    isDetail: "N",
    imageName: "",
    filePath: "./img/goods/",
    serverFolderPath: "",
  };

  // 상품등록,수정시 사용
  const [regState, setRegState] = useState(initRegState);

  // 대분류
  const [bigCategory, setBigCategory] = useState([]);
  // 중분류
  const [middleCategory, setMiddleCategory] = useState([]);
  // 소분류
  const [smallCategory, setSmallCategory] = useState([]);

  // 선택된 대분류
  const [selBigCategory, setSelBigCategory] = useState("");
  // 선택된 중분류
  const [selMiddleCategory, setSelMiddleCategory] = useState("");
  // 소분류
  const [selSmallCategory, setSelSmallCategory] = useState("");

  const [data, setData] = useState([]);
  const [tot, setTot] = useState("0");

  const [imgSrc, setImgSrc] = useState();
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState(null);

  const [croppedImageUrl, setCroppedImageUrl] = useState(null); // 추가된 상태

  const imgRef = useRef(null);
  const canvasRef = useRef(null);

  const handleCompleteCrop = (crop) => {
    console.log("handleCompleteCrop");

    drawImageOnCanvas(imgRef.current, canvasRef.current, crop);
    setCompletedCrop(crop);

    // 캔버스의 자른 이미지를 데이터 URL로 변환하여 저장
    const canvas = canvasRef.current;
    if (canvas) {
      const croppedUrl = canvas.toDataURL("image/png");

      console.log("croppedImageUrl", croppedImageUrl);

      setCroppedImageUrl(croppedUrl);

      const blob = dataURLtoBlob(croppedUrl);
      const file = new File([blob], "image.png", { type: "image/png" });
      fileRef.current = file;

      //const imgElement = document.getElementById("img_pre_add");
      //imgElement.src = croppedImageUrl;
    }
  };

  function dataURLtoBlob(dataURL) {
    const parts = dataURL.split(",");
    const byteString = atob(parts[1]);
    const mimeString = parts[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  const onClose = () => {
    console.log("close");
    setCrop(null);
    setImgSrc(null);

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // canvas 초기화 코드 작성
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    //canvasRef.current = null;

    fileRef.current = null;
    setCroppedImageUrl(null);
  };

  const handleDownload = () => {
    generateDownload(canvasRef.current, completedCrop);
  };

  // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
  const canvasStyles = {
    width: Math.round(completedCrop?.width ?? 0),
    height: Math.round(completedCrop?.height ?? 0),
  };

  const totalPages = useMemo(
    () => Math.ceil(tot / formState.pageSize),
    [tot, formState.pageSize]
  );

  const pageNumbers = useMemo(() => {
    const numbers = [];
    for (let i = 1; i <= totalPages; i++) {
      numbers.push(i);
    }
    return numbers;
  }, [totalPages]);

  const handleBtnGoods = async (agency, fran, brand, store, pos) => {
    console.log(
      "agency",
      agency,
      "fran",
      fran,
      "brand",
      brand,
      "store",
      store,
      "pos",
      pos
    );

    await setFormState({
      ...formState,
      agency: agency,
      fran: fran,
      brand: brand,
      store: store,
      pos: pos,
      pageNumber: 1,
    });

    console.log(
      "[Goods , handleBtnGoods]",
      formState.agency,
      formState.fran,
      formState.brand,
      formState.store,
      formState.pos
    );
  };

  const handleBtnGoodsReg = (agency, fran, brand, store, pos) => {
    setRegState({
      ...regState,
      agency: agency,
      fran: fran,
      brand: brand,
      store: store,
      pos: pos,
    });
  };

  const handleKeyWordChange = (e) => {
    console.log("keyword", e.target.value);
    setFormState({
      ...formState,
      goods_name: e.target.value,
    });
  };

  const onSearchBtnClick = (e) => {
    searchGoodsTotCntList();
    searchGoodsPagingList();
  };

  const searchGoodsPagingList = () => {
    let url = "";
    url = `${serverUrl}/goods/getGoodsPageList?pageNumber=${formState.pageNumber}&pageSize=${formState.pageSize}&agency=${formState.agency}&fran=${formState.fran}&brand=${formState.brand}&store=${formState.store}&pos=${formState.pos}&goods_nm=${formState.goods_name}`;
    console.log("url", url);
    fetchData("GET", url)
      .then((data) => {
        // 성공적으로 데이터를 가져왔을 때의 처리
        //console.log("Data fetched: ", data);

        if (data.header) {
          //console.log("data.header", data.header);
          if (data.header.res_code === "ok") {
            setData(data.data);
            console.log("상품 데이터 페이징 리스트 => ", data.data);
          } else {
            // 에러 메시지 표현
            setData([]);
            console.log(data.header.res_code, data.header.res_msg);
          }
        } else {
          setData([]);
          console.error("header 값이 없음");
        }
      })
      .catch((error) => {
        // fetchData 함수 내에서 발생한 에러를 처리
        console.error("Error in fetchData: ", error);
      });
  };

  const searchGoodsTotCntList = () => {
    const url = `${serverUrl}/goods/getGoodsCntList?agency=${formState.agency}&fran=${formState.fran}&brand=${formState.brand}&store=${formState.store}&pos=${formState.pos}&goods_nm=${formState.goods_name}`;
    console.log("url", url);
    fetchData("GET", url)
      .then((data) => {
        // 성공적으로 데이터를 가져왔을 때의 처리
        //console.log("Data fetched: ", data);

        if (data.header) {
          //console.log("data.header", data.header);
          if (data.header.res_code === "ok") {
            setTot(data.data[0].cnt);
            console.log("상품 데이터 페이징 리스트 => ", data.data);
          } else {
            // 에러 메시지 표현
            setTot("0");
            console.log(data.header.res_code, data.header.res_msg);
          }
        } else {
          setTot("0");
          console.error("header 값이 없음");
        }
      })
      .catch((error) => {
        // fetchData 함수 내에서 발생한 에러를 처리
        console.error("Error in fetchData: ", error);
      });
  };

  useEffect(() => {
    const keep_login = window.localStorage.getItem("keep_login") || "false";
    if (!getLoginCheck()) {
      navigate("/login", { replace: true });
      return;
    } else {
      //   if (keep_login === "false" || keep_login === false) {
      //     navigate("/login", { replace: true });
      //     return;
      //   }
    }
    // else if (keep_login === "false" || keep_login === false) {
    //   alert(keep_login);
    //   navigate("/login", { replace: true });
    //   return;
    // }
  }, []);

  useEffect(() => {
    setFormState({
      ...formState,
      pageNumber: 1,
      pageSize: 10,
    });

    const attZone = document.getElementById("att_zone");
    const btnAtt = document.getElementById("btnAtt");
    const chkbtnAtt = document.getElementById("chkbtn");
    var sel_files = [];

    console.log(btnAtt);

    const imageView = function imageView() {
      // 이미지와 삭제 박스를 감싸고 있는 div 속성
      var div_style =
        "display:inline-block;position:relative;" +
        "width:150px;height:150px;margin:5px;border:1px solid #00f;z-index:1; margin-top:15px; margin-top:15px";
      // 미리보기 이미지 속성
      var img_style = "width:100%;height:100%;z-index:none";

      // 이미지안에 표시되는 삭제 버튼의 속성
      var chk_style =
        "width:30px;height:30px;position:absolute;font-size:15px;" +
        "right:5px;bottom:5px;z-index:999;background-color:#ffffff;color:#f00;border-radius: 15px; border:1px solid #111111";

      // 이미지안에 표시되는 라디오 버튼 속성
      var deImg_style =
        "opacity:1;width:30px;height:20px;position: Relative;font-size:24px;" +
        "z-index:999;background-color:rgba(255,255,255,0.1);color:#f00; text-align:center;top:8px; left:60px; padding-bottom:15px";

      btnAtt.onchange = function (e) {
        //sel_files = [];
        attZoneRef.current.innerHTML = "";
        console.group("FileChange");

        var files = e.target.files;
        var fileArr = Array.prototype.slice.call(files);
        for (let f of fileArr) {
          console.log(f);
          imageLoader(f);
        }

        if (e.target.files && e.target.files.length > 0) {
          const reader = new FileReader();
          reader.addEventListener("load", () => setImgSrc(reader.result));
          reader.readAsDataURL(e.target.files[0]);
        }
        // console.log("이미지 setImgSrc", imgSrc);
        console.groupEnd();
      };

      // 탐색기에서 드래그앤 드롭 사용
      //   attZone.addEventListener(
      //     "dragenter",
      //     function (e) {
      //       e.preventDefault();
      //       e.stopPropagation();
      //       //console.log("dragenter");
      //     },
      //     false
      //   );

      //   attZone.addEventListener(
      //     "dragover",
      //     function (e) {
      //       e.preventDefault();
      //       e.stopPropagation();
      //       //console.log("dragover");
      //     },
      //     false
      //   );

      //   attZone.addEventListener(
      //     "drop",
      //     function (e) {
      //       var files = {};
      //       e.preventDefault();
      //       e.stopPropagation();
      //       var dt = e.dataTransfer;
      //       files = dt.files;
      //       for (let f of files) {
      //         imageLoader(f);
      //       }
      //     },
      //     false
      //   );

      /*첨부된 이미지 배열에 넣고 미리보기 */
      const imageLoader = function (file) {
        //attZone.remove();

        sel_files.push(file);
        var reader = new FileReader();
        reader.onload = function (ee) {
          let img = document.createElement("img");
          img.setAttribute("style", img_style);
          img.src = ee.target.result;
          img.id = "img_pre_add";
          attZone.appendChild(makeDiv(img, file));
        };

        reader.readAsDataURL(file);
      };

      /*첨부된 파일이 있는 경우 checkbox와 함께 attZone에 추가할 div를 만들어 반환 */
      const makeDiv = function (img, file) {
        var div = document.createElement("div");
        div.setAttribute("style", div_style);

        var chkbtn = document.createElement("input");
        chkbtn.setAttribute("type", "radio");
        chkbtn.setAttribute("name", "deImg");
        chkbtn.setAttribute("value", file.name);
        chkbtn.setAttribute("style", deImg_style);

        var btn = document.createElement("input");
        btn.setAttribute("type", "button");
        btn.setAttribute("value", "x");
        btn.setAttribute("delFile", file.name);
        btn.setAttribute("style", chk_style);
        btn.onclick = function (ev) {
          var ele = ev.srcElement;
          var delFile = ele.getAttribute("delFile");
          for (var i = 0; i < sel_files.length; i++) {
            if (delFile == sel_files[i].name) {
              sel_files.splice(i, 1);
            }
          }

          const dt = new DataTransfer();
          for (let f in sel_files) {
            var file = sel_files[f];
            dt.items.add(file);
          }
          btnAtt.files = dt.files;
          var p = ele.parentNode;
          attZone.removeChild(p);
        };
        div.appendChild(img);
        div.appendChild(btn);
        div.appendChild(chkbtn);
        return div;
      };
    };

    imageView();

    return () => {
      // 컴포넌트가 언마운트될 때 정리할 내용을 여기에 작성
    };
  }, []);

  useEffect(() => {
    numberBtnChange();
  }, [formState.pageNumber]);

  useEffect(() => {
    console.log("✛✛✛✛✛✛");
    if (
      formState.agency !== "" &&
      formState.fran !== "" &&
      formState.brand !== "" &&
      formState.store !== "" &&
      formState.pos !== ""
    ) {
      numberBtnChange();
      console.log("값이 다 있음");
    } else {
      console.log("값이 일부[전부] 없음");
    }
  }, [
    formState.agency,
    formState.fran,
    formState.brand,
    formState.store,
    formState.pos,
  ]);

  const numberBtnChange = () => {
    searchGoodsTotCntList();
    searchGoodsPagingList();
  };

  const handlePageChange = (pageNumber) => {
    setFormState({ ...formState, pageNumber: pageNumber });
  };

  const handleTrClick = (e) => {
    // console.log(e.currentTarget.dataset.datas);
    //const data = JSON.parse(e.currentTarget.dataset.datas);
    //console.log(data, typeof data);
  };

  const handleBtnDeleteGoods = (e) => {
    const data = JSON.parse(e.currentTarget.dataset.datas);
    console.log(data, typeof data);

    const url = `${serverUrl}/goods/deletGoods?agency=${data.AGENCY_CODE}&fran=${data.FRANCHISE_CODE}&brand=${data.BRAND_CODE}&store=${data.STORE_CD}&pos=${data.POS_NO}&goodsId=${data.PLU_CD}&imageName=${data.imageName}`;
    console.log("url", url);
    fetchData("DELETE", url)
      .then((data) => {
        // 성공적으로 데이터를 가져왔을 때의 처리
        //console.log("Data fetched: ", data);

        if (data.header) {
          //console.log("data.header", data.header);
          if (data.header.res_code === "ok") {
            //setTot(data.data[0].cnt);
            console.log("상품 삭제 => ", data.data);
            toast.success(data.header.res_msg || "삭제에 성공하였습니다.");
            searchGoodsTotCntList();
            searchGoodsPagingList();
            numberBtnChange();
          } else {
            // 에러 메시지 표현
            //setTot("0");
            console.log(data.header.res_code, data.header.res_msg);
            searchGoodsTotCntList();
            searchGoodsPagingList();
            numberBtnChange();
            toast.error(data.header.res_msg || "삭제에 실패 하였습니다.");
          }
        } else {
          //setTot("0");
          console.error("header 값이 없음");
          searchGoodsTotCntList();
          searchGoodsPagingList();
          numberBtnChange();
          toast.error("header 전문이 없습니다.");
        }
      })
      .catch((error) => {
        // fetchData 함수 내에서 발생한 에러를 처리
        console.error("Error in fetchData: ", error);
        searchGoodsTotCntList();
        searchGoodsPagingList();
        numberBtnChange();
        toast.error(error);
      });

    // numberBtnChange();
  };

  const handleBtnRegGoods = (agency, fran, brand, store, pos) => {
    const updatedState = {
      ...regState,
      agency: agency,
      fran: fran,
      brand: brand,
      store: store,
      pos: pos,
    };

    setRegState(updatedState);

    console.group("Goods > 상품등록 버튼 클릭");

    sendFalg = "INSERT";

    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      // canvas 초기화 코드 작성
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }

    setImgSrc(null);
    if (imgRef.current) {
      imgRef.current.src = null;
    }

    console.log("받아온 값", agency, fran, brand, store, pos);
    console.log("regState", regState);

    sendDataInit();

    sendData.agency = agency;
    sendData.fran = fran;
    sendData.brand = brand;
    sendData.store = store;
    sendData.pos = pos;

    agencyRef.current.value = agency;
    franRef.current.value = fran;
    brandRef.current.value = brand;
    storeRef.current.value = store;
    posRef.current.value = pos;

    // 할인율
    offPercentRef.current.value = "";
    // 할인금액
    offPriceRef.current.value = "";

    // 상품명 클리어
    //setRegState({ ...regState, goodsname: "" });
    itemNameRef.current.value = "";
    // 판매 가격 클리어
    priceRef.current.value = "";
    // 파일 클리어
    fileInputRef.current.value = "";
    // 이미지 미리 보기 삭제
    attZoneRef.current.innerHTML = "";

    // 상품코드
    itemcodeRef.current.value = "";

    console.log(sendData);
    console.groupEnd();
    getBigCategory(agency, fran, brand, store, pos);

    // 대분류
    if (bigRef.current) {
      bigRef.current.selectedIndex = 0; // 첫 번째 옵션 선택
    }
  };

  const handleBtnEditGoods = (e) => {
    console.log("상품 수정 버튼");
    sendFalg = "EDIT";
    const data = e.currentTarget.dataset.datas;

    const jsonData = JSON.parse(data);

    console.log(jsonData);

    const formData = new FormData();

    sendDataInit();

    // file로 삭제
    fileInputRef.current.value = "";
    // 이미지 미리 보기 삭제
    attZoneRef.current.innerHTML = "";

    // 대리점
    sendData.agency = jsonData.AGENCY_CODE;
    agencyRef.current.value = jsonData.AGENCY_CODE;
    // 프랜차이즈
    sendData.fran = jsonData.FRANCHISE_CODE;
    franRef.current.value = jsonData.FRANCHISE_CODE;
    // 브랜드
    sendData.brand = jsonData.BRAND_CODE;
    brandRef.current.value = jsonData.BRAND_CODE;
    // 매장
    sendData.store = jsonData.STORE_CD;
    storeRef.current.value = jsonData.STORE_CD;
    // 상품코드
    sendData.goodsid = jsonData.PLU_CD;
    itemcodeRef.current.value = jsonData.PLU_CD;
    // 포스번호
    sendData.pos = jsonData.POS_NO;
    posRef.current.value = jsonData.POS_NO;
    // 상품이름
    sendData.goodsname = jsonData.GOODS_NM;
    itemNameRef.current.value = jsonData.GOODS_NM;
    // 카테고리 대분류
    sendData.categoryBig = jsonData.ITEM_HEAD;
    console.log("카테고리 대분류", jsonData.ITEM_HEAD);
    //bigRef.current.value = jsonData.ITEM_HEAD;
    // 카테고리 중분류
    sendData.categoryMiddle = jsonData.ITEM_MEDIUM;
    //middleRef.current.value = jsonData.ITEM_MEDIUM;
    // 카테고리 소분류
    sendData.categorySmall = jsonData.ITEM_DETAIL;
    //smallRef.current.value = jsonData.ITEM_DETAIL;
    // 판매 여부
    sendData.saleYN = jsonData.SALE_YN;
    setRegState({ ...regState, saleYN: jsonData.SALE_YN });
    // 판매 가격
    priceRef.current.value = jsonData.SALEPRICE;
    //SALEPRICE
    //옵션 가능
    if (jsonData.hasOption === "N") {
      hasOptionRef.current.checked = false;
    } else {
      hasOptionRef.current.checked = true;
    }
    sendData.hasOption = jsonData.hasOption;
    // 과세 여부
    setRegState({ ...regState, taxYN: jsonData.TAX_GUB });
    sendData.taxYN = jsonData.TAX_GUB;
    // 할인 적용
    // 할인율
    sendData.offPercent = jsonData.offPercent;
    offPercentRef.current.value = jsonData.offPercent;
    // 할인가격
    sendData.offPrice = jsonData.offPrice;
    offPriceRef.current.value = jsonData.offPrice;

    // 이미지 등록
    console.log("이미지", jsonData.ServerFolderPath);

    // const container = attZoneRef.current;

    const container = attZoneRef.current;
    if (container) {
      const newElement = document.createElement("div");
      newElement.style.display = "inline-block";
      newElement.style.position = "relative";
      newElement.style.width = "150px";
      newElement.style.height = "150px";
      newElement.style.margin = "5px";
      newElement.style.border = "1px solid #00f";
      newElement.style.zIndex = "1";
      newElement.style.marginTop = "15px";

      const img = document.createElement("img");
      img.style.width = "100%";
      img.style.height = "100%";
      img.style.zIndex = "none";
      img.src = jsonData.ServerFolderPath;
      newElement.appendChild(img);

      const button = document.createElement("input");
      button.type = "button";
      button.value = "x";
      button.delfile = "";
      button.onclick = handleBtnImgDel;
      button.style.width = "30px";
      button.style.height = "30px";
      button.style.position = "absolute";
      button.style.fontSize = "15px";
      button.style.right = "5px";
      button.style.bottom = "5px";
      button.style.zIndex = "999";
      button.style.backgroundColor = "#ffffff";
      button.style.color = "#f00";
      button.style.borderRadius = "15px";
      button.style.border = "1px solid #111111";
      newElement.appendChild(button);

      const radio = document.createElement("input");
      radio.type = "radio";
      radio.name = "deImg";
      radio.value = "";
      radio.style.opacity = "1";
      radio.style.width = "30px";
      radio.style.height = "20px";
      radio.style.position = "relative";
      radio.style.fontSize = "24px";
      radio.style.zIndex = "999";
      radio.style.backgroundColor = "rgba(255, 255, 255, 0.1)";
      radio.style.color = "#f00";
      radio.style.textAlign = "center";
      radio.style.top = "8px";
      radio.style.left = "60px";
      radio.style.paddingBottom = "15px";
      newElement.appendChild(radio);

      container.appendChild(newElement);

      //   setCrop(jsonData.serverFolderPath);

      setImgSrc(jsonData.serverFolderPath);

      console.log("서버에서 가져온 이미지", jsonData.ServerFolderPath);

      const imgUrl = jsonData.ServerFolderPath;

      if (imgRef.current) {
        imgRef.current.src = jsonData.serverFolderPath;
        imgRef.current.src = imgUrl;
      }

      setImgSrc(imgUrl);
    }

    getBigCategory(
      jsonData.AGENCY_CODE,
      jsonData.FRANCHISE_CODE,
      jsonData.BRAND_CODE,
      jsonData.STORE_CD,
      jsonData.POS_NO
    );

    //if (bigRef.current) bigRef.current.value = jsonData.ITEM_HEAD;
    //if (middleRef.current) middleRef.current.value = jsonData.ITEM_MEDIUM;
    // const container = attZoneRef.current;
    // if (container) {
    //   container.appendChild(innerHtml);
    // }

    //attZoneRef.current.innerHTML = innerHtml;
    //ReactDOM.render(innerHtml, attZoneRef.current);
    // const root = createRoot(attZoneRef.current);
    // root.render(innerHtml);

    // Clear existing content
    // while (attZoneRef.current.firstChild) {
    //   attZoneRef.current.removeChild(attZoneRef.current.firstChild);
    // }

    // // Create a root and render the JSX content
    // const root = createRoot(attZoneRef.current);
    // root.render(innerHtml);
  };

  const getBigCategory = (agency, fran, brand, store, pos) => {
    const url = `${serverUrl}/category/getCategoryList?agency=${agency}&fran=${fran}&brand=${brand}&store=${store}&pos=${pos}&lvl=1`;
    console.log("url", url);
    fetchData("GET", url)
      .then((data) => {
        // 성공적으로 데이터를 가져왔을 때의 처리
        //console.log("Data fetched: ", data);

        if (data.header) {
          console.log("data.header", data.header);
          if (data.header.res_code === "ok") {
            setBigCategory(data.data);
            setSelBigCategory(data.data[0].CATEGORYCODE);
            setRegState({
              ...regState,
              categoryBig: data.data[0].CATEGORYCODE,
            });
            console.log("중분류 카테고리  => ", data.data);
            //const timer = setTimeout(() => {
            if (sendFalg === "EDIT") {
              getMiddleCategory(
                data.data[0].AGENCY_CODE,
                data.data[0].FRANCHISE_CODE,
                data.data[0].BRAND_CODE,
                data.data[0].STORE_CD,
                data.data[0].POS_NO,
                sendData.categoryBig
              );
            } else {
              getMiddleCategory(
                data.data[0].AGENCY_CODE,
                data.data[0].FRANCHISE_CODE,
                data.data[0].BRAND_CODE,
                data.data[0].STORE_CD,
                data.data[0].POS_NO,
                data.data[0].CATEGORYCODE
              );
            }

            //}, 500);
          } else {
            // 에러 메시지 표현
            setBigCategory([]);
            setSelBigCategory("");
            setRegState({
              ...regState,
              categoryBig: "",
            });
            console.log(data.header.res_code, data.header.res_msg);
            getMiddleCategory("", "", "", "", "", "");
          }
        } else {
          setBigCategory([]);
          setSelBigCategory("");
          setRegState({
            ...regState,
            categoryBig: "",
          });
          console.error("header 값이 없음");
          getMiddleCategory("", "", "", "", "", "");
        }
      })
      .catch((error) => {
        // fetchData 함수 내에서 발생한 에러를 처리
        console.error("Error in fetchData: ", error);
      });
  };

  const getMiddleCategory = (agency, fran, brand, store, pos, upper) => {
    const url = `${serverUrl}/category/getCategoryList?agency=${agency}&fran=${fran}&brand=${brand}&store=${store}&pos=${pos}&upper=${upper}&lvl=2`;
    console.log("url", url);
    //alert(url);

    fetchData("GET", url)
      .then((data) => {
        // 성공적으로 데이터를 가져왔을 때의 처리
        //console.log("Data fetched: ", data);

        if (data.header) {
          //console.log("data.header", data.header);
          if (data.header.res_code === "ok") {
            setMiddleCategory(data.data);
            setSelMiddleCategory(data.data[0].CATEGORYCODE);
            setRegState({
              ...regState,
              categoryMiddle: data.data[0].CATEGORYCODE,
            });
            console.log(data.data);
            //const timer = setTimeout(() => {
            if (sendFalg === "EDIT") {
              getSmallCategory(
                data.data[0].AGENCY_CODE,
                data.data[0].FRANCHISE_CODE,
                data.data[0].BRAND_CODE,
                data.data[0].STORE_CD,
                data.data[0].POS_NO,
                sendData.categoryMiddle
              );
            } else {
              getSmallCategory(
                data.data[0].AGENCY_CODE,
                data.data[0].FRANCHISE_CODE,
                data.data[0].BRAND_CODE,
                data.data[0].STORE_CD,
                data.data[0].POS_NO,
                data.data[0].CATEGORYCODE
              );
            }

            //}, 500);
          } else {
            // 에러 메시지 표현
            setMiddleCategory([]);
            setSelMiddleCategory("");
            setRegState({
              ...regState,
              categoryMiddle: "",
            });
            console.log(data.header.res_code, data.header.res_msg);
            getSmallCategory("", "", "", "", "", "");
          }
        } else {
          setMiddleCategory([]);
          setSelMiddleCategory("");
          setRegState({
            ...regState,
            categoryMiddle: "",
          });
          console.error("header 값이 없음");
          getSmallCategory("", "", "", "", "", "");
        }
      })
      .catch((error) => {
        // fetchData 함수 내에서 발생한 에러를 처리
        console.error("Error in fetchData: ", error);
      });
  };

  const getSmallCategory = (agency, fran, brand, store, pos, upper) => {
    const url = `${serverUrl}/category/getCategoryList?agency=${agency}&fran=${fran}&brand=${brand}&store=${store}&pos=${pos}&upper=${upper}&lvl=3`;
    console.log("url", url);

    fetchData("GET", url)
      .then((data) => {
        // 성공적으로 데이터를 가져왔을 때의 처리
        //console.log("Data fetched: ", data);

        if (data.header) {
          //console.log("data.header", data.header);
          if (data.header.res_code === "ok") {
            setSmallCategory(data.data);
            setSelSmallCategory(data.data[0].CATEGORYCODE);
            setRegState({
              ...regState,
              categorySmall: data.data[0].CATEGORYCODE,
            });
            console.log(data.data);
          } else {
            // 에러 메시지 표현
            setSmallCategory([]);
            setSelSmallCategory("");
            setRegState({
              ...regState,
              categorySmall: "",
            });
            console.log(data.header.res_code, data.header.res_msg);
          }
        } else {
          setSmallCategory([]);
          setSelSmallCategory("");
          setRegState({
            ...regState,
            categorySmall: "",
          });
          console.error("header 값이 없음");
        }
      })
      .catch((error) => {
        // fetchData 함수 내에서 발생한 에러를 처리
        console.error("Error in fetchData: ", error);
      });
  };

  /*
  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    setDraggedFiles(files);

    files.forEach((file) => {
      const imageView = () => {
        let imgDiv = document.createElement("div");
        imgDiv.style.display = "inline-block";
        imgDiv.style.position = "relative";
        imgDiv.style.width = "150px";
        imgDiv.style.height = "150px";
        imgDiv.style.margin = "5px";
        imgDiv.style.border = "1px solid #00f";
        imgDiv.style.zIndex = "1";
        imgDiv.style.marginTop = "15px";
        imgDiv.style.marginTop = "15px";

        let img = document.createElement("img");
        img.style.width = "100%";
        img.style.height = "100%";
        img.style.zIndex = "none";
        img.src = URL.createObjectURL(file);

        let delBtn = document.createElement("input");
        delBtn.type = "button";
        delBtn.value = "x";
        delBtn.style.width = "30px";
        delBtn.style.height = "30px";
        delBtn.style.position = "absolute";
        delBtn.style.fontSize = "15px";
        delBtn.style.right = "5px";
        delBtn.style.bottom = "5px";
        delBtn.style.zIndex = "999";
        delBtn.style.backgroundColor = "#ffffff";
        delBtn.style.color = "#f00";
        delBtn.style.borderRadius = "15px";
        delBtn.style.border = "1px solid #111111";
        delBtn.setAttribute("delfile", file.name);
        //delBtn.addEventListener("click", handleDelete);

        let radioBtn = document.createElement("input");
        radioBtn.type = "checkbox";
        radioBtn.name = "deImg";
        radioBtn.value = file.name;
        radioBtn.style.opacity = "1";
        radioBtn.style.width = "30px";
        radioBtn.style.height = "20px";
        radioBtn.style.position = "relative";
        radioBtn.style.fontSize = "24px";
        radioBtn.style.zIndex = "999";
        radioBtn.style.backgroundColor = "rgba(255,255,255,0.1)";
        radioBtn.style.color = "#f00";
        radioBtn.style.textAlign = "center";
        radioBtn.style.top = "8px";
        radioBtn.style.left = "60px";
        radioBtn.style.paddingBottom = "15px";
        //radioBtn.addEventListener("change", handleSelect);

        imgDiv.appendChild(img);
        imgDiv.appendChild(delBtn);
        imgDiv.appendChild(radioBtn);
        attZone.current.appendChild(imgDiv);
      };

      imageView();
    });
  };
*/
  const handleBtnSave = async (e) => {
    console.log("저장 버튼 클릭");
    const formData = new FormData();

    sendData.categoryBig = bigRef.current.value;
    sendData.categoryMiddle = middleRef.current.value;
    sendData.categorySmall = smallRef.current.value;

    const selectedBigText =
      bigRef.current?.options?.[bigRef.current?.selectedIndex]?.text;
    if (selectedBigText) {
      sendData.goodsBigNm = selectedBigText;
    } else {
      toast.error(
        "대분류 값이 없습니다. 카테고리설정[상품분류관리] 메뉴를 확인해 보세요"
      );
      return;
    }

    // sendData.goodsBigNm =
    //   bigRef.current.options[bigRef.current.selectedIndex].text;`

    const selectedMiddleText =
      middleRef.current?.options?.[middleRef.current?.selectedIndex]?.text;
    if (selectedMiddleText) {
      sendData.goodsMiddileNm = selectedMiddleText;
    } else {
      toast.error(
        "중분류 값이 없습니다. 카테고리설정[상품분류관리] 메뉴를 확인해 보세요"
      );
      return;
    }

    // sendData.goodsMiddileNm =
    //   middleRef.current.options[middleRef.current.selectedIndex].text;

    const selectedSmallText =
      smallRef.current?.options?.[smallRef.current?.selectedIndex]?.text;
    if (selectedSmallText) {
      sendData.itemDetailNM = selectedSmallText;
    } else {
      toast.error(
        "소분류 값이 없습니다. 카테고리설정[상품분류관리] 메뉴를 확인해 보세요"
      );
      return;
    }

    // sendData.itemDetailNM =
    //   smallRef.current.options[smallRef.current.selectedIndex].text;

    sendData.goodsname = itemNameRef.current.value;

    if (hasOptionRef.current.checked === true) {
      sendData.hasOption = "Y";
    } else {
      sendData.hasOption = "N";
    }

    sendData.saleYN = regState.saleYN;
    sendData.salePrice = priceRef.current.value;
    sendData.taxYN = regState.taxYN;

    sendData.offPercent =
      offPercentRef.current.value === "" ? "0" : offPercentRef.current.value;
    sendData.offPrice =
      offPriceRef.current.value === "" ? "0" : offPriceRef.current.value;

    const files = fileInputRef.current.files;
    console.log("Selected files:", files);

    // if (!canvasRef.current) {
    //   toast.error("이미지를 마우스로 드래그 해서 선택하세요");
    //   return;
    // }

    // const selectedFile = fileInputRef.current.files[0];

    if (croppedImageUrl === null || croppedImageUrl === "") {
      toast.error("이미지를 마우스로 드래그 해서 선택하세요");
      return;
    }

    const selectedFile = fileRef.current;
    if (selectedFile) {
      const resizedImage = await resizeImage(selectedFile, 605, 550);

      formData.append("file", resizedImage, selectedFile.fileName);

      //const resizedImage = resizeImage(selectedFile, 605, 550);
      //formData.append("file", resizedImage, selectedFile.name);

      const fileName = selectedFile.name;
      const fileSize = selectedFile.size;
      const fileType = selectedFile.type;
      console.log("Selected file:", fileName);
      console.log("File size:", fileSize);
      console.log("File type:", fileType);

      sendData.imageName = selectedFile.name;
      //sendData.file = fileInputRef.current.files[0];

      // 서버 전송을 위한 준비 작업 수행
      // ...
    }
    //else {
    //   toast.error("이미지를 마우스로 드래그 해서 선택하세요");
    //   return;
    // }

    // 기입 안된 부분 체크 ==>
    // 상품명
    // else if (itemNameRef.current.value === "") {
    //   toast.error("상품명을 입력하세요");
    //   itemNameRef.current.focus();
    //   return;
    // }
    // 판매가격
    if (priceRef.current.value === "") {
      toast.error("판매가격을 입력하세요");
      priceRef.current.focus();
      return;
    }

    if (sendFalg === "INSERT") {
      // 파일 선택
      if (fileInputRef.current.value === "") {
        toast.error("이미지 파일을 선택하세요");
        fileInputRef.current.focus();
        return;
      }
    } else {
      // 수정일 경우
      const container = attZoneRef.current;
      console.log(container.hasChildNodes());

      console.log(
        "파일",
        fileInputRef.current.value,
        "하위노드",
        container.hasChildNodes()
      );

      // 파일선택도 없고 이미지 미리 보기도 없을 경우
      if (
        fileInputRef.current.value === "" &&
        container.hasChildNodes() === false
      ) {
        toast.error("이미지 파일을 선택하세요");
        fileInputRef.current.focus();
        return;
      } else if (
        // 이미지 미리 보기만 있다. 이것은 이미지 변동이 없다.
        fileInputRef.current.value === "" &&
        container.hasChildNodes() === true
      ) {
        //toast.error("이미지 변동없음");
        // 이미지 미리 보기만 있다. 이것은 이미지 변동이 없다.
        /*
		sendData.imageIsChange = false;

        if (editRef.current) {
          let datas = editRef.current?.dataset?.datas;
          console.log("###################");
          console.log(datas);
          if (datas) {
            datas = JSON.parse(datas);
            sendData.imageName = datas.imageName;
            sendData.serverFolderPath = datas.ServerFolderPath;
            //alert(sendData.imageName);
            //alert(sendData.serverFolderPath);
          } else {
            console.log("editRef dataset 값없음");
          }
        }*/

        sendData.imageIsChange = true;
        if (editRef.current) {
          let datas = editRef.current?.dataset?.datas;
          if (datas) {
            datas = JSON.parse(datas);
            // 옛날 이미지 파일 이름
            sendData.oldImageFile = datas.ServerFolderPath;
          }
        }
      } else if (
        // 이미지 가 있고, 미리 보기가 있다. 이것은 이미지 교체이다
        fileInputRef.current.value !== "" &&
        container.hasChildNodes() === true
      ) {
        // 이미지 가 있고, 미리 보기가 있다. 이것은 이미지 교체이다
        //toast.error("이미지 변동 있음");
        sendData.imageIsChange = true;
        if (editRef.current) {
          let datas = editRef.current?.dataset?.datas;
          if (datas) {
            datas = JSON.parse(datas);
            // 옛날 이미지 파일 이름
            sendData.oldImageFile = datas.ServerFolderPath;
          }
        }
      }

      onClose();

      // 임시
      //return;
    }

    // 기입 안된 부분 체크 ==>

    for (const key in sendData) {
      formData.append(key, sendData[key]);
    }

    // 파일 넣기
    if (selectedFile) {
      //formData.append("file", fileInputRef.current.files[0]);
    }

    console.log("formData >> ", JSON.stringify([...formData]));

    //console.log("regState", regState);
    console.log("■■■■■■ sendData ■■■■■■■", sendData);

    let Url = "";

    if (sendFalg === "INSERT") {
      Url = `${serverUrl}/goods/addGoods`;
      const method = "POST";

      console.log("저장", method, Url);

      setTimeout(() => {
        fetch(Url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            //'Content-Type': 'multipart/form-data' 넣지마시오!!
          },
          body: formData,
          timeout: 60 * 1000,
        })
          .then((response) => response.json()) // Assuming the response is JSON
          .then((data) => {
            console.log("Response:", data); // Log the response data
            // Additional logic with the response data if needed
            console.log(data.header.res_code);
            if (data.header.res_code === "ok") {
              console.log(data.header.res_msg);
              toast.success(data.header.res_msg || "삽입에 성공하였습니다");
            } else {
              console.log(data.header.res_msg);
              toast.error(data.header.res_msg || "삽입에 실패하였습니다");
            }
          })
          .catch((err) => {
            console.error(err);
            toast.error(err);
          })
          .finally(() => {
            console.log("finally");
            searchGoodsTotCntList();
            searchGoodsPagingList();
          });
      }, 500);
    } else {
      // 수정
      Url = `${serverUrl}/goods/editGoods`;
      //Url = `http://localhost:8080/goods/editGoods`;
      const method = "PUT";

      console.log("수정", method, Url);

      setTimeout(() => {
        fetch(Url, {
          method: method,
          headers: {
            Accept: "application/json",
            //'Content-Type': 'multipart/form-data' 넣지마시오!!
          },
          body: formData,
          timeout: 60 * 1000,
        })
          .then((response) => response.json()) // Assuming the response is JSON
          .then((data) => {
            console.log("Response:", data); // Log the response data
            // Additional logic with the response data if needed
            console.log(data.header.res_code);
            if (data.header) {
              if (data.header.res_code === "ok") {
                console.log(data.header.res_msg);
                toast.success(data.header.res_msg || "수정에 성공하였습니다");
                searchGoodsTotCntList();
                searchGoodsPagingList();
              } else {
                //console.log(data.header.res_msg);
                toast.error("header 값이 없습니다.");
                toast.error(data.header.res_msg || "수정에 실패하였습니다");
                searchGoodsTotCntList();
                searchGoodsPagingList();
              }
            } else {
              searchGoodsTotCntList();
              searchGoodsPagingList();
            }
          })
          .catch((err) => {
            console.error(err);
            toast.error(err || "에러가 발생했습니다.");
            searchGoodsTotCntList();
            searchGoodsPagingList();
          })
          .finally(() => {
            console.log("finally");
            searchGoodsTotCntList();
            searchGoodsPagingList();
          });
      }, 500);
    }

    $(".btn.btn-success[data-dismiss='modal']").trigger("click");
    return;
  };

  const handleGoodsChange = (e) => {
    console.log("handleGoodsChange > ", e.target.name);
    setRegState({ ...regState, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (bigRef.current && sendFalg === "EDIT") {
      //   alert(sendData.categoryBig);
      console.log(sendData.categoryBig);
      //bigRef.current.value = sendData.categoryBig;
      getMiddleCategory(
        sendData.agency,
        sendData.fran,
        sendData.brand,
        sendData.store,
        sendData.pos,
        sendData.categoryBig
      );
    }
  }, [sendData.categoryBig, sendFalg]);

  const handleGoodsBigCategoryChange = (e) => {
    const upper = e.target.value;

    console.log("♣ ♣ ♣ ♣ ♣  handleGoodsBigCategoryChange > ", upper);
    setRegState({ ...regState, categoryBig: upper });

    console.log(
      sendData.fran,
      sendData.brand,
      sendData.store,
      sendData.pos,
      upper
    );

    //alert("handleGoodsBigCategoryChange");
    getMiddleCategory(
      sendData.agency,
      sendData.fran,
      sendData.brand,
      sendData.store,
      sendData.pos,
      upper
    );
  };

  const handleGoodsMiddleCategoryChange = (e) => {
    const upper = e.target.value;

    console.log("♣ ♣ ♣ ♣ ♣  handleGoodsMiddleCategoryChange > ", upper);

    console.log(
      sendData.fran,
      sendData.brand,
      sendData.store,
      sendData.pos,
      upper
    );

    getSmallCategory(
      sendData.agency,
      sendData.fran,
      sendData.brand,
      sendData.store,
      sendData.pos,
      upper
    );
  };

  const resizeImage = (file, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        const mine = getContentType(file.name);

        canvas.toBlob(
          (blob) => {
            const resizedFile = new File([blob], file.name, {
              type: blob.type,
            });
            resolve(resizedFile);
          },
          mine,
          0.7
        );
      };
      img.onerror = reject;
    });
  };

  return (
    <div>
      {/* <div id="overlay" className="transparent"></div> */}
      <div id="wrapper" className="preload">
        <CommonHeader />
        <ASide index={1} gubun={"goods"} />

        <div id="main-container">
          <GoodsHeader
            title={"상품 관리"}
            onHandleBtn={handleBtnGoods}
            onHandleBtnReg={handleBtnRegGoods}
          />

          <div className="padding-md">
            <div className="panel panel-default table-responsive">
              <div className="panel-body">
                <div className="row">
                  <div className="col-lg-4 col-sm-4 col-xs-4">
                    <span className="marR5">전체</span>
                    <span className="badge badge-info">
                      {getCommpaPrice(tot)}
                    </span>
                  </div>
                  {/* <!-- /.col --> */}
                  <div className="col-lg-6 col-sm-6 col-xs-6 fl-right">
                    <div style={customFloatStyle} className="marR2">
                      <select
                        className="form-control input-sm"
                        style={customBlockStyle}
                      >
                        <option value="">상품명</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control input-sm"
                          placeholder="검색어를 입력하세요."
                          onChange={handleKeyWordChange}
                          value={formState.goods_name}
                        />
                        <span className="input-group-btn">
                          <button
                            className="btn btn-default btn-sm"
                            type="button"
                            onClick={onSearchBtnClick}
                          >
                            <i className="fa fa-search"></i>
                          </button>
                        </span>
                      </div>
                      {/* <!-- /input-group --> */}
                    </div>
                  </div>
                </div>
                {/* <!-- /.row --> */}
              </div>
              <table className="table table-striped" id="responsiveTable">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>판매상태</th>
                    <th>상품 코드</th>
                    <th>상품명</th>
                    <th>판매가격</th>
                    <th>성인유무</th>
                    <th>대분류</th>
                    <th>중분류</th>
                    <th>소분류</th>
                    <th>옵션포함여부</th>
                    <th>편집</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((value, index) => (
                    <tr
                      key={index}
                      data-datas={JSON.stringify(value)}
                      onClick={handleTrClick}
                    >
                      <td>{value.ROWNUM}</td>
                      <td>
                        <span className="fcgreen">{value.SALE_YN}</span>
                      </td>
                      <td>{value.PLU_CD}</td>
                      <td className="text-left">{value.GOODS_NM}</td>
                      <td>
                        <span className="fcblue">
                          {getCommpaPrice(value.SALEPRICE)}
                        </span>
                        원
                      </td>
                      <td>
                        <span className="fcgreen">
                          {value.JUNIOR_YN === "Y" ? "아니요" : "예"}
                        </span>
                      </td>
                      <td>{value.ITEM_HEAD_NM}</td>
                      <td>{value.ITEM_MEDIUM_NM}</td>
                      <td>{value.ITEM_DETAIL_NM}</td>
                      <td>
                        {value.ITEM_DETAIL_NM === "Y" ? "포함" : "미포함"}{" "}
                      </td>
                      <td>
                        <a
                          href="#formModal"
                          role="button"
                          data-toggle="modal"
                          class="btn btn-sm btn-default fcblue"
                          data-backdrop="static"
                          data-keyboard="false"
                          data-datas={JSON.stringify(value)}
                          onClick={handleBtnEditGoods}
                          ref={editRef}
                        >
                          <i class="fa">상세</i>
                        </a>
                        <button
                          type="button"
                          className="btn btn-sm btn-default fcred lightCustomModal_open"
                          href="#lightCustomModal"
                          data-datas={JSON.stringify(value)}
                          onClick={handleBtnDeleteGoods}
                        >
                          삭제
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="panel-footer clearfix">
                <div className="search-pager fl-right">
                  <ul className="pagination pagination-sm pagination-split no-margin">
                    <li className="disabled">
                      <a>«</a>
                    </li>
                    {pageNumbers.map((pageNumber) => (
                      <li
                        key={pageNumber}
                        className={
                          pageNumber === formState.pageNumber && "active"
                        }
                        onClick={() => handlePageChange(pageNumber)}
                      >
                        <a>
                          {pageNumber}
                          {pageNumber === formState.pageNumber && (
                            <span class="sr-only">(current)</span>
                          )}
                        </a>
                      </li>
                    ))}

                    <li>
                      <a>»</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <!-- /panel --> */}
          </div>
        </div>
        {/* <!-- /main-container --> */}
        <div className="modal fade" id="formModal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close fl-right"
                  data-dismiss="modal"
                  aria-hidden="true"
                  onClick={onClose}
                >
                  &times;
                </button>
                <span className="fcred fl-right marR20">* 필수입력</span>
                <h4>상품 등록</h4>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          <span className="fcred">*</span>대리점
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="대리점"
                          disabled
                          //value={regState.agency}
                          ref={agencyRef}
                        />
                      </div>
                      {/* <!-- /form-group -->     */}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          <span className="fcred">*</span>프랜차이즈
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="프랜차이즈"
                          disabled
                          //   value={regState.fran}
                          ref={franRef}
                        />
                      </div>
                      {/* <!-- /form-group -->     */}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          <span className="fcred">*</span> 브랜드
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="브랜드"
                          disabled
                          //   value={regState.brand}
                          ref={brandRef}
                        />
                      </div>
                      {/* <!-- /form-group -->     */}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          <span className="fcred">*</span> 매장
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          placeholder="매장"
                          disabled
                          //   value={regState.store}
                          ref={storeRef}
                        />
                        {/* <!-- /input-group-btn --> */}
                      </div>
                    </div>
                    {/* <!-- /.col --> */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          <span className="fcred">*</span> POS 선택
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="포스"
                          disabled
                          //   value={regState.pos}
                          ref={posRef}
                        />
                      </div>
                    </div>
                    {/* <!-- /.col --> */}
                  </div>
                  {/* <!-- /.row -->  */}

                  <div className="line100 marT20 marB20"></div>
                  <div className="form-group">
                    <label>
                      <span className="fcred">*</span> 상품분류
                    </label>
                    <div className="row">
                      <div className="col-md-4 col-sm-4 col-xs-4">
                        <select
                          className="form-control"
                          onChange={handleGoodsBigCategoryChange}
                          //   value={regState.bigCategory}
                          ref={bigRef}
                        >
                          {/* <option value="" selected>
                            대분류 선택
                          </option>
                          <option value="1">분류001</option>
                          <option value="2">분류002</option>
                          <option value="3">분류003</option>
                          <option value="4">분류004</option>
                          <option value="5">분류005</option> */}
                          {bigCategory.map((option, index) => (
                            <option
                              key={index}
                              value={option.CATEGORYCODE}
                              selected={
                                sendFalg === "EDIT" &&
                                sendData.categoryBig === option.CATEGORYCODE
                              }
                            >
                              {option.CATEGORYNAME}
                            </option>
                          ))}
                        </select>
                      </div>
                      {/* <!-- /.col --> */}
                      <div className="col-md-4 col-sm-4 col-xs-4">
                        <select
                          className="form-control"
                          onChange={handleGoodsMiddleCategoryChange}
                          ref={middleRef}
                        >
                          {middleCategory.map((option, index) => (
                            <option
                              key={index}
                              value={option.CATEGORYCODE}
                              selected={
                                sendFalg === "EDIT" &&
                                sendData.categoryMiddle === option.CATEGORYCODE
                              }
                            >
                              {option.CATEGORYNAME}
                            </option>
                          ))}
                          {/* <option value="" selected>
                            중분류 선택
                          </option>
                          <option value="1">분류001</option>
                          <option value="2">분류002</option>
                          <option value="3">분류003</option>
                          <option value="4">분류004</option>
                          <option value="5">분류005</option> */}
                        </select>
                      </div>
                      {/* <!-- /.col --> */}
                      <div className="col-md-4 col-sm-4 col-xs-4">
                        <select className="form-control" ref={smallRef}>
                          {/* <option value="" selected>
                            소분류 선택
                          </option>
                          <option value="1">분류001</option>
                          <option value="2">분류002</option>
                          <option value="3">분류003</option>
                          <option value="4">분류004</option>
                          <option value="5">분류005</option> */}
                          {smallCategory.map((option, index) => (
                            <option
                              key={index}
                              value={option.CATEGORYCODE}
                              selected={
                                sendFalg === "EDIT" &&
                                sendData.categorySmall === option.CATEGORYCODE
                              }
                            >
                              {option.CATEGORYNAME}
                            </option>
                          ))}
                        </select>
                      </div>
                      {/* <!-- /.col --> */}
                    </div>
                  </div>
                  {/* <!-- /.row -->    */}

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          <span className="fcred">*</span> 상품명
                        </label>
                        <div className="form-inline no-margin">
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control "
                              placeholder=""
                              style={{ width: 296 }}
                              //value={regState.goodsname}
                              onChange={handleGoodsChange}
                              name="goodsname"
                              ref={itemNameRef}
                            />
                          </div>
                          <input
                            type="text"
                            className="form-control "
                            placeholder="상품코드"
                            style={{ width: 120 }}
                            disabled
                            ref={itemcodeRef}
                          />
                        </div>
                        <div className="form-group marT10">
                          <label className="label-checkbox">
                            <input type="checkbox" ref={hasOptionRef} />
                            <span className="custom-checkbox"></span>
                            옵션 가능
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /.col --> */}

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          <span className="fcred">*</span> 판매여부
                        </label>
                        <div className="form-inline ">
                          <label className="label-radio inline fw-normal ">
                            <input
                              type="radio"
                              name="saleYN"
                              value="Y"
                              checked={regState.saleYN === "Y"}
                              onChange={handleGoodsChange}
                            />
                            <span className="custom-radio"></span>
                            판매
                          </label>
                          <label className="label-radio inline fw-normal">
                            <input
                              type="radio"
                              name="saleYN"
                              value="N"
                              checked={regState.saleYN === "N"}
                              onChange={handleGoodsChange}
                            />
                            <span className="custom-radio"></span>
                            중지
                          </label>
                        </div>
                      </div>
                      {/* <!-- /form-group -->     */}
                    </div>
                    {/* <!-- /.col --> */}
                  </div>
                  {/* <!-- /.row -->    */}

                  <div className="line100 marT20 marB20"></div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          <span className="fcred">*</span> 판매가격
                        </label>
                        <div className="form-inline no-margin">
                          <div className="input-group">
                            <input
                              type="number"
                              className="form-control "
                              placeholder=""
                              style={{ width: 296 }}
                              ref={priceRef}
                            />
                          </div>

                          <select
                            className="form-control"
                            style={{ width: 120 }}
                          >
                            <option value="" selected>
                              원(KRW)
                            </option>
                            <option value="1">달러(USD)</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /.col --> */}

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          <span className="fcred">*</span> 과세여부
                        </label>

                        <div className="form-inline">
                          <label className="label-radio inline fw-normal">
                            <input
                              type="radio"
                              name="taxYN"
                              value="Y"
                              checked={regState.taxYN === "Y"}
                              onChange={handleGoodsChange}
                            />
                            <span className="custom-radio"></span>
                            과세
                          </label>
                          <label className="label-radio inline fw-normal">
                            <input
                              type="radio"
                              name="taxYN"
                              value="N"
                              checked={regState.taxYN === "N"}
                              onChange={handleGoodsChange}
                            />
                            <span className="custom-radio"></span>
                            비과세
                          </label>
                        </div>
                      </div>
                      {/* <!-- /form-group -->     */}
                    </div>
                    {/* <!-- /.col --> */}
                  </div>
                  {/* <!-- /.row -->   */}

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label> 할인적용</label>
                        <div className="row">
                          <div className="col-md-4 col-sm-4 col-xs-4">
                            <div className="input-group">
                              <input
                                type="number"
                                className="form-control "
                                placeholder="할인율"
                                ref={offPercentRef}
                              />
                              <span className="input-group-addon">%</span>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-4">
                            <input
                              type="number"
                              className="form-control "
                              placeholder="할인금액"
                              ref={offPriceRef}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <!-- /form-group -->     */}
                    </div>

                    {/* <!-- 상품이미지 등록 --> */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <div class="line100 marT20"></div>
                        <label>
                          {" "}
                          <span className="fcred">*</span>이미지등록
                        </label>
                        <div class="row marT10 row">
                          <div class="col-md-12">
                            <input
                              type="file"
                              id="btnAtt"
                              ref={fileInputRef}
                            ></input>{" "}
                            {/* {src && ( */}
                            <ReactCrop
                              crop={crop}
                              onChange={setCrop}
                              aspect={3 / 4}
                              onComplete={handleCompleteCrop}
                            >
                              {imgSrc && (
                                <img
                                  ref={imgRef}
                                  src={imgSrc}
                                  alt="cropper image"
                                  style={{ maxWidth: "50%", maxHeight: "50%" }}
                                  crossOrigin="anonymous"
                                />
                              )}
                            </ReactCrop>
                            <canvas ref={canvasRef} style={canvasStyles} />
                            {/* )} */}
                            <div
                              id="att_zone"
                              data-placeholder="파일을 첨부 하려면 파일 선택 버튼을 클릭하세요"
                              ref={attZoneRef}
                              hidden
                              //onDragOver={handleDragOver}
                              //onDrop={handleDrop}
                            >
                              {/* {draggedFiles.map((file, index) => (
                            <div
                              key={index}
                              style={{
                                display: "inline-block",
                                position: "relative",
                                width: "150px",
                                height: "150px",
                                margin: "5px",
                                border: "1px solid #00f",
                                zIndex: "1",
                                marginTop: "15px",
                                marginTop: "15px",
                              }}
                            >
                              <img
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  zIndex: "none",
                                }}
                                src={URL.createObjectURL(file)}
                              />
                              <input
                                type="button"
                                value="x"
                                delfile={file.name}
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  position: "absolute",
                                  fontSize: "15px",
                                  right: "5px",
                                  bottom: "5px",
                                  zIndex: "999",
                                  backgroundColor: "#ffffff",
                                  color: "#f00",
                                  borderRadius: "15px",
                                  border: "1px solid #111111",
                                }}
                                // onClick={handleDelete}
                              />
                              <input
                                type="checkbox"
                                name="deImg"
                                value={file.name}
                                style={{
                                  opacity: "1",
                                  width: "30px",
                                  height: "20px",
                                  position: "relative",
                                  fontSize: "24px",
                                  zIndex: "999",
                                  backgroundColor: "rgba(255,255,255,0.1)",
                                  color: "#f00",
                                  textAlign: "center",
                                  top: "8px",
                                  left: "60px",
                                  paddingBottom: "15px",
                                }}
                                checked={selectedImgs.includes(file.name)}
                                // onChange={handleSelect}
                              />
                            </div>
                          ))} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /상품이미지 등록 --> */}

                    {/* <!-- /.col --> */}
                    {/* <div className="col-md-6">
                      <div className="form-group">
                        <label> 제휴할인</label>
                        <div className="row">
                          <div className="col-md-6 col-sm-6 col-xs-6">
                            <select className="form-control">
                              <option value="" selected>
                                통신사
                              </option>
                              <option value="1">SKT</option>
                              <option value="1">KT</option>
                            </select>
                          </div>
                          <div className="col-md-6 col-sm-6 col-xs-6">
                            <label className="label-checkbox">
                              <input type="checkbox" />
                              <span className="custom-checkbox"></span>
                              중복 할인 가능
                            </label>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>

                  {/* <div className="row">
                    <div className="col-md-3 col-sm-6 col-xs-6">
                      <div className="form-group">
                        <label> 특별판매가격</label>
                        <input
                          type="text"
                          className="form-control "
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div> */}

                  <div className="row">
                    {/* <div className="col-lg-12">
                      <div className="form-group">
                        <label> 특별판매기간</label>
                        <div className="row">
                          <div className="col-md-2 col-sm-4 col-xs-4">
                            <div className="input-group">
                              <input
                                type="text"
                                value="06/10/2013"
                                className="datepicker form-control"
                              />
                              <span className="input-group-addon">
                                <i className="fa fa-calendar"></i>
                              </span>
                            </div>
                          </div>
                          <div className="col-md-2 col-sm-4 col-xs-4">
                            <div className="input-group">
                              <input
                                type="text"
                                value="06/10/2013"
                                className="datepicker form-control"
                              />
                              <span className="input-group-addon">
                                <i className="fa fa-calendar"></i>
                              </span>
                            </div>
                          </div>

                          <div className="col-md-4 col-sm-12 col-xs-12"></div>
                          <div className="col-md-2 col-sm-4 col-xs-4">
                            <div className="input-group bootstrap-timepicker">
                              <input
                                className="timepicker form-control"
                                type="text"
                              />
                              <span className="input-group-addon">
                                <i className="fa fa-clock-o"></i>
                              </span>
                            </div>
                          </div>
                          <div className="col-md-2 col-sm-4 col-xs-4">
                            <div className="input-group bootstrap-timepicker">
                              <input
                                className="timepicker form-control"
                                type="text"
                              />
                              <span className="input-group-addon">
                                <i className="fa fa-clock-o"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>

                  {/* <div className="line100 marT20"></div>
                  <div className="marT10 row">
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                      <h4 className="fw600">옵션정보 </h4>
                    </div>
                    <div className="navbar-right marR20 marT5">
                      <a
                        href="#agencyModal"
                        className="btn btn-sm btn-danger"
                        data-dismiss="modal"
                        aria-hidden="true"
                        role="button"
                        data-toggle="modal"
                        data-backdrop="static"
                        data-keyboard="false"
                      >
                        <i className="fa fa-tasks"></i> 옵션추가
                      </a>
                    </div>
                  </div> */}
                  {/* <div className="form-group marT20 madalTable"> */}
                  {/* <div className="panel panel-default table-responsive">
                      <table
                        className="table table-striped fontSize13"
                        id="responsiveTable"
                      >
                        <thead>
                          <tr>
                            <th>노출순서</th>
                            <th>옵션코드</th>
                            <th>옵션선택</th>
                            <th>옵션기능</th>
                            <th>필수여부</th>
                            <th>옵션 분류 수</th>
                            <th>수량조절</th>
                            <th>편집</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>9000221</td>
                            <td>
                              <select className="form-control">
                                <option value="">상품 크기 변경</option>
                                <option value="">상품 온도 설정</option>
                                <option value="">상품 농도 조절</option>
                                <option value="">상품 당도 조절</option>
                                <option value="">토핑 재료 추가</option>
                              </select>
                            </td>
                            <td>단수선택</td>
                            <td>필수</td>
                            <td>3</td>
                            <td>불가</td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-sm btn-default fcblue"
                              >
                                상세
                              </button>
                              <button
                                type="button"
                                className="btn btn-sm btn-default fcred lightCustomModal_open"
                                href="#lightCustomModal"
                              >
                                삭제
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div> */}
                  {/* <!-- /panel --> */}
                  {/* </div> */}
                </form>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-success "
                  data-dismiss="modal"
                  aria-hidden="true"
                  onClick={onClose}
                >
                  취소
                </button>
                <a className="btn btn-navy" onClick={handleBtnSave}>
                  close 저장
                </a>
              </div>
            </div>
            {/* <!-- /.modal-content --> */}
          </div>
          {/* <!-- /.modal-dialog --> */}
        </div>
        {/* <!-- /.modal --> */}

        {/* <!-- 대리점관리매장 modal --> */}
        <div className="modal fade" id="agencyModal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close fl-right"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  &times;
                </button>
                <span className="fcred fl-right marR20">* 필수입력</span>
                <h4>대리점 관리 매장 등록</h4>
              </div>
              <div className="modal-body">
                <form>
                  <div className="fl-right marT20">
                    <div style={customFloatStyle} className="marR2">
                      <select
                        className="form-control input-sm"
                        style={{ display: "inline-block", width: 130 }}
                      >
                        <option value="">상호</option>
                        <option value="">대표자</option>
                        <option value="">사업자등록번호</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control input-sm"
                          placeholder="검색어를 입력하세요."
                        />
                        <span className="input-group-btn">
                          <button
                            className="btn btn-default btn-sm"
                            type="button"
                          >
                            <i className="fa fa-search"></i>
                          </button>
                        </span>
                      </div>
                      {/* <!-- /input-group --> */}
                    </div>
                  </div>

                  <div className="form-group marT20 madalTable">
                    <div className="panel panel-default table-responsive">
                      <table
                        className="table table-striped fontSize13"
                        id="responsiveTable"
                      >
                        <thead>
                          <tr>
                            <th>
                              <input type="checkbox" id="catechkAll" />
                              <span className="custom-checkbox"></span>
                            </th>
                            <th>상태</th>
                            <th>분류</th>
                            <th>코드</th>
                            <th>상호</th>
                            <th>사업자등록번호</th>
                            <th>대표</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <input type="checkbox" name="catechk" id="" />
                              <span className="custom-checkbox"></span>
                            </td>
                            <td>
                              <span className="fcgreen">운영</span>
                            </td>
                            <td>프랜차이즈</td>
                            <td>500025</td>
                            <td className="text-left">하나시스정보통신</td>
                            <td>138-55-88975</td>
                            <td>홍길동</td>
                          </tr>
                          <tr>
                            <td>
                              <input type="checkbox" name="catechk" id="" />
                              <span className="custom-checkbox"></span>
                            </td>
                            <td>
                              <span className="fcred">미운영</span>
                            </td>
                            <td>프랜차이즈</td>
                            <td>500025</td>
                            <td className="text-left">하나시스정보통신</td>
                            <td>138-55-88975</td>
                            <td>홍길동</td>
                          </tr>
                          <tr>
                            <td>
                              <input type="checkbox" name="catechk" id="" />
                              <span className="custom-checkbox"></span>
                            </td>
                            <td>
                              <span className="fcgreen">운영</span>
                            </td>
                            <td>프랜차이즈</td>
                            <td>500025</td>
                            <td className="text-left">하나시스정보통신</td>
                            <td>138-55-88975</td>
                            <td>홍길동</td>
                          </tr>
                          <tr>
                            <td>
                              <input type="checkbox" name="catechk" id="" />
                              <span className="custom-checkbox"></span>
                            </td>
                            <td>운영</td>
                            <td>프랜차이즈</td>
                            <td>500025</td>
                            <td className="text-left">하나시스정보통신</td>
                            <td>138-55-88975</td>
                            <td>홍길동</td>
                          </tr>
                          <tr>
                            <td>
                              <input type="checkbox" name="catechk" id="" />
                              <span className="custom-checkbox"></span>
                            </td>
                            <td>운영</td>
                            <td>프랜차이즈</td>
                            <td>500025</td>
                            <td className="text-left">하나시스정보통신</td>
                            <td>138-55-88975</td>
                            <td>홍길동</td>
                          </tr>
                          <tr>
                            <td>
                              <input type="checkbox" name="catechk" id="" />
                              <span className="custom-checkbox"></span>
                            </td>
                            <td>운영</td>
                            <td>프랜차이즈</td>
                            <td>500025</td>
                            <td className="text-left">하나시스정보통신</td>
                            <td>138-55-88975</td>
                            <td>홍길동</td>
                          </tr>
                          <tr>
                            <td>
                              <input type="checkbox" name="catechk" id="" />
                              <span className="custom-checkbox"></span>
                            </td>
                            <td>운영</td>
                            <td>프랜차이즈</td>
                            <td>500025</td>
                            <td className="text-left">하나시스정보통신</td>
                            <td>138-55-88975</td>
                            <td>홍길동</td>
                          </tr>
                          <tr>
                            <td>
                              <input type="checkbox" name="catechk" id="" />
                              <span className="custom-checkbox"></span>
                            </td>
                            <td>운영</td>
                            <td>프랜차이즈</td>
                            <td>500025</td>
                            <td className="text-left">하나시스정보통신</td>
                            <td>138-55-88975</td>
                            <td>홍길동</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {/* <!-- /panel --> */}
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-success "
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  취소
                </button>
                <a href="#" className="btn btn-navy">
                  저장
                </a>
              </div>
            </div>
            {/* <!-- /.modal-content --> */}
          </div>
          {/* <!-- /.modal-dialog --> */}
        </div>
        {/* <!-- /.modal --> */}

        {/* <!-- 엑셀파일 등록 modal--> */}
        <div className="modal fade" id="excelModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close fl-right"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  &times;
                </button>
                <h4>엑셀파일 등록</h4>
              </div>
              <div className="modal-body">
                <div className="marB30">
                  <div className="alert">
                    <i className="fa fa-warning"></i>
                    <span className="m-left-xs">
                      지정된 형식의 엑셀파일만 등록이 가능합니다
                    </span>
                    <div className="m-left-xs">
                      <span>
                        STEP 1. 아래 [서식 파일 받기] 버튼을 클릭하여 엑셀
                        파일을 다운로드
                        <br />
                        STEP 1. 다운로드한 파일에 관련 정보를 입력한 후 저장
                        <br />
                        STEP 1. 아래 [파일 선택] 버튼을 클릭하여 저장된 엑셀
                        파일을 선택한 후, [등록] 버튼 클릭
                        <br />
                      </span>
                    </div>
                  </div>
                  <form action="." className="">
                    <div className="form-inline no-margin">
                      <input
                        name="file"
                        type="file"
                        multiple
                        className="form-control "
                        style={customBoderStyle}
                      />
                      <div className="navbar-right">
                        <a
                          href=""
                          role="button"
                          className="btn btn-sm btn-default"
                        >
                          <i className="fa fa-download"></i> 서식 파일 받기
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* <!-- /.modal-content --> */}
              <div className="modal-footer">
                <button
                  className="btn btn-success "
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  취소
                </button>
                <a href="#" className="btn btn-navy">
                  일괄 등록
                </a>
              </div>
            </div>
            {/* <!-- /.modal-dialog --> */}
          </div>
          {/* <!-- /.modal --> */}
        </div>
        {/* <!-- /wrapper --> */}
      </div>
      <ToastContainer autoClose={600} />
    </div>
  );
};

export default Goods;
